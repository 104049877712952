<div>
	<div class="title">
		<span>Links</span>
	</div>
	<ng-template ngFor let-item [ngForOf]="items()">

		<ng-template let-info [ngIf]="item.parameters['info$'] | async" [ngIfElse]="noInfo">

			<ng-template [ngIf]="info.enabled" [ngIfElse]="disabled">
				<a bas-link [href]="info.url">
					<span [innerHTML]="item.name || (item.nameKey | msgKey)"></span>
				</a>
			</ng-template>
			<ng-template #disabled>
				<span class="defaultLink inactive" disabled="true" [title]="info.message">
					{{item.name || (item.nameKey | msgKey)}}
				</span>
			</ng-template>

		</ng-template>

		<ng-template #noInfo>
			<bas-side-nav-item [isDefaultLink]="true" [item]="item"></bas-side-nav-item>
		</ng-template>

	</ng-template>

</div>
