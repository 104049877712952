import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BreadcrumbService } from '../../generic-compounds/breadcrumb/breadcrumb.service';
import {
	AddBreadcrumbsAction, BreadcrumbsActionTypes, GetBreadcrumbsAction, UpdateBreadcrumbsAction,
} from '../actions/breadcrumbs.actions';

@Injectable()
export class BreadcrumbsEffects {

	getBreadcrumbs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BreadcrumbsActionTypes.GetBreadcrumbs),
			switchMap(() => this.breadcrumbService.getBreadcrumbs()),
			map(breadcrumbs => new AddBreadcrumbsAction(breadcrumbs))
		)
	);

	updateBreadcrumbs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BreadcrumbsActionTypes.UpdateBreadcrumbs),
			map(action => (action as UpdateBreadcrumbsAction).payload),
			switchMap(breadcrumb => breadcrumb.resetBreadcrumbs
				? this.breadcrumbService.setBreadcrumb(breadcrumb)
				: this.breadcrumbService.addBreadcrumb(breadcrumb)),
			catchError(error => of(null)),
			map(() => new GetBreadcrumbsAction()),
		)
	);

	removeLastBreadcrumbs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BreadcrumbsActionTypes.RemoveLastBreadcrumbs),
			switchMap(() => this.breadcrumbService.removeLast()),
			map(() => new GetBreadcrumbsAction())
		)
	);

	constructor(
		@Inject(Actions) private actions$: Actions,
		@Inject(BreadcrumbService) private breadcrumbService: BreadcrumbService
	) { }
}
