import { Inject, Injectable } from '@angular/core';
import { forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationItem, NavigationItemDeclaration } from '../navigation/models/navigationItem';
import { ConfigService } from '../shared/services/config.service';
import { BouncerService } from './bouncer/bouncer.service';
import { FeatureService } from './feature/feature.service';
import { NodeFunction } from './models/node-function';
import { PermissionBuilder } from './permission-builder';
import { Additional_Permissions, Nodes } from './permissions.token';
import { UserService } from './user/user.service';

@Injectable()
export class NodePermissionFilter {
	constructor(
		private user: UserService,
		private feature: FeatureService,
		private bouncer: BouncerService,
		@Inject(Additional_Permissions) private additionPermissionService,
		@Inject(Nodes) private nodesFunction: NodeFunction,
		private configService: ConfigService,
	) {
	}

	getNodes(): Observable<Array<NavigationItem>> {
		const allNodes = this.nodesFunction(
			this.user,
			this.feature,
			this.bouncer,
			PermissionBuilder.and,
			PermissionBuilder.or,
			PermissionBuilder.not,
			this.configService,
			this.additionPermissionService,
		);

		const permission$s = this.findItemsWithPermission(allNodes)
			.map(node => node.permission.pipe(map(value => ({ value, id: node.id }))));

		if (permission$s.length === 0) {
			return of(allNodes)
				.pipe(
					map(nodes => this.deepConvertToReadOnlyNodeList(nodes)),
				);
		}

		return forkJoin([...permission$s])
			.pipe(
				map(permissions => this.deepFilterPermissions(allNodes, permissions)),
				map(nodes => this.setEmptyParameters(nodes)),
				map(nodes => this.deepConvertToReadOnlyNodeList(nodes)),
			);
	}

	private deepConvertToReadOnlyNodeList(nodes: Array<NavigationItemDeclaration>): Array<NavigationItem> {
		return nodes.map((node) => {
			const nodeClone = ({ ...node }) as any;
			if (node.children) {
				nodeClone.children = this.deepConvertToReadOnlyNodeList(node.children);
			}
			if (node.permission) {
				nodeClone.permission = lastValueFrom(node.permission, { defaultValue: undefined });
			}
			if (node.url$) {
				nodeClone.url$ = lastValueFrom(node.url$, { defaultValue: undefined });
			}
			return nodeClone;
		});
	}

	private deepFilterPermissions(nodes: Array<NavigationItemDeclaration> | null, permissions: Array<{ value: boolean, id: string }>) {
		if (!nodes) {
			return nodes;
		}
		const filteredTopLevel = nodes.filter(node => {
			const permission = permissions.find(perm => perm.id === node.id);
			if (permission) {
				return permission.value;
			}

			return true;
		});

		return filteredTopLevel.map(node => ({
			...node,
			children: this.deepFilterPermissions(node.children, permissions),
		}));
	}

	private setEmptyParameters(nodes: Array<NavigationItemDeclaration>): Array<NavigationItemDeclaration> {
		if (!nodes) {
			return [];
		}

		return nodes
			.map(node => node.parameters ? node : ({ ...node, parameters: {} }))
			.map(node => ({ ...node, children: this.setEmptyParameters(node.children) }));
	}

	private findItemsWithPermission(nodes: Array<NavigationItemDeclaration>): Array<NavigationItemDeclaration> {
		if (!nodes || nodes.length === 0) {
			return [];
		}
		const nodesInTopLevel = nodes.filter(node => node.permission);

		const children = nodes.filter(node => node.children && node.children.length > 0)
			.reduce((arr, node) => [...arr, ...node.children], [])
			.filter(Boolean);
		const nodesInBottomLevel = this.findItemsWithPermission(children);

		return [...nodesInTopLevel, ...nodesInBottomLevel];
	}
}
