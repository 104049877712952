import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: '[bas-anchor-status]',
	templateUrl: './anchor-status.component.html',
	styleUrls: ['./anchor-status.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnchorStatusComponent {

	@HostBinding('class.nurFuerBasUndAdminSichtbar')
	nurFuerBasUndAdminSichtbar = false;
	@HostBinding('class.nurFuerBasSichtbar')
	nurFuerBasSichtbar = false;
	@HostBinding('class.abgelaufen')
	abgelaufen = false;
	@HostBinding('class.fuerShowHiddenSichtbar') // allianz mediapool sonderfall
	fuerShowHiddenSichtbar = false;

	@Input()
	set parameters(parameters: { [key: string]: boolean }) {
		if (parameters['nurFuerBasUndAdminSichtbar']) {
			this.nurFuerBasUndAdminSichtbar = true;
		}
		if (parameters['nurFuerBasSichtbar']) {
			this.nurFuerBasSichtbar = true;
		}
		if (parameters['abgelaufen']) {
			this.abgelaufen = true;
		}
		if (parameters['fuerShowHiddenSichtbar']) {
			this.fuerShowHiddenSichtbar = true;
		}
	}
}
