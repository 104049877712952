import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bas-group-separator',
	templateUrl: './group-separator.component.html',
	styleUrls: ['./group-separator.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSeparatorComponent {
	@Input()
	name: string;

	@Input()
	hint: string;
}
