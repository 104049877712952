import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { User } from '../../../models/user';
import { ApplicationState } from '../../shared/store/application-state';
import { UserQuery } from '../../shared/store/reducers/user.reducer';

@Component({
	selector: 'bas-nav-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {

	user = signal<User>(null);
	isMobile: boolean;

	constructor(private store: Store<ApplicationState>) {
		this.store.select(UserQuery.getUser)
			.pipe(takeUntilDestroyed())
			.subscribe(user => this.user.set(user));
	}

	ngOnInit() {
		this.isMobile = navigator.userAgent.includes('Mobi');
	}

	scrollToTop() {
		window.scrollTo(0, 0);
	}

}
