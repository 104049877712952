import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { ApplicationState } from '../shared/store/application-state';
import { AjaxRotatorService } from './ajax-rotator.service';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { MobileNavModule } from './mobile-nav/mobile-nav.module';
import { NavigationService } from './navigation.service';
import { SideNavModule } from './side-nav/side-nav.module';
import { NavigationEffects } from './store/navigation.effects';
import { navigationReducer } from './store/navigation.reducer';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ApplicationState>>('Registered Nav-Reducers');

@NgModule({
	imports: [
		HeaderModule,
		SideNavModule,
		FooterModule,
		MobileNavModule,
		StoreModule.forFeature('navigation', REDUCER_TOKEN),
		EffectsModule.forFeature([NavigationEffects])
	],
	exports: [
		HeaderModule,
		SideNavModule,
		FooterModule,
		MobileNavModule
	],
	providers: [
		NavigationService,
		AjaxRotatorService,
		{
			provide: REDUCER_TOKEN,
			useValue: navigationReducer
		}
	]
})
export class NavigationModule { }
