<div class="scroll-left"
	 (mouseleave)="scroll('none')"
	 (mousedown)="scroll('left')"
	 (mouseup)="scroll('none')"
	 *ngIf="scrollable"
	 [class.inactive]="margin === minMargin"></div>

<div class="breadcrumb-item-container"
	 #itemContainer>
	<div #items
		 [style.marginLeft.px]="margin">
		<ng-template ngFor
					 let-breadcrumb
					 [ngForOf]="breadcrumbs()"
					 let-first="first"
					 let-last="last"
					 [ngForTrackBy]="trackBreadCrumb">
			<div class="breadcrumb-item"
				 [ngClass]="{last: last, first: first}">
				<ng-container *ngTemplateOutlet="breadCrumbItem; context: { breadcrumb: breadcrumb, last: last }">
				</ng-container>
			</div>
		</ng-template>
	</div>
</div>

<div class="scroll-right"
	 *ngIf="scrollable"
	 (mouseleave)="scroll('none')"
	 (mousedown)="scroll('right')"
	 (mouseup)="scroll('none')"
	 [class.inactive]="margin === maxMargin"></div>

<ng-template #breadCrumbItem
			 let-breadcrumb="breadcrumb"
			 let-last="last">
	<a class="container"
	   *ngIf="!last;else span_only"
	   (click)="navigateRouted(breadcrumb,$event)">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>
	<ng-template #span_only>
		<div class="container">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</div>
	</ng-template>

	<ng-template #content>
		<div class="left-edge">
		</div>
		<div class="center-piece">
			<span>{{breadcrumb.description}}</span>
		</div>
		<div class="right-edge">
		</div>
	</ng-template>
</ng-template>