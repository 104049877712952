import config from 'configuration';
import { mergeCKEditorToolbars, mergeConfiguration } from 'helper/mergeHelper';

const audiConfiguration = {
	global: {
		contentArea: '.struts-content-area',
		fullPageOnFirstClick: true,
	},
	ScrollHelper: {
		contentSelector: '#content',
	},
	SectionScrollHelper: {
		contentSelector: '#content',
	},
	OverlayHelper: {
		overlayImageSource: 'page/style/img/audi_loader.gif',
		overlayImageCss: {
			width: '50px',
			height: '50px',
		},
	},
	TabIndexer: {
		indexArea: '#content',
	},
	AjaxRotator: {
		appendArea: 'body',
	},
	makeDatepicker: {
		changeMonth: false,
		changeYear: false,
		showWeek: true,
		showButtonPanel: true,
		weekHeader: 'KW',
		selectCurrent: true,
		currentText: 'Heute',
		buttonImageOnly: true,
		defaultIconPath: context + '/page/style/img/btn/kalender.png',
		defaultInactiveIconPath: context + '/page/style/img/btn/kalender-inaktiv.png',
		defaultHoverIconPath: context + '/page/style/img/btn/kalender-rollover.png',
	},
	EditViewStandard: {
		superviewTemplate: $('<div class="overlay superView editView"><div class="superviewContainer"><div class="superViewClose close"></div><div class="overviewArea"><div id="templateTitle"></div><div class="overviewContainer"><div class="overviewButtons"></div><div class="overviewImage"></div></div></div></div></div>'),
		centerSuperviewToolbar: false,
		scrollpane: {
			showArrows: false,
		},
		superviewTitle: true,
		superviewTitleLines: 1,
		superviewThumbnailWidth: 110,
		basScroll: {
			toggleControls: true,
		},
	},
	EditViewBanner: {
		basScroll: {
			toggleControls: true,
		},
	},
	Tooltip: {
		hasShadow: false,
	},
	Dialog: {
		clipDefaultCloseButton: 'inner-top-right',
		shadow: false,
	},
	BannerLightbox: {
		boxContainerHeight: 0.8,
		template: $('<div class="overlay superView"><div class="lightboxContainer"><div class="closeLightbox"/></div></div>'),
	},
	AutoComplete: {
		resetSearchfield: true,
	},
	/**
	 * CKEditor-Config
	 */
	CKEditorHandler: {
		toolbars: {
			toolbar_HtmlArea1: [
				[
					'Styles', 'Bold', 'HorizontalRule',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Link', 'Unlink',
				],
			],
			toolbar_HtmlArea2YT: [
				[
					'Cut', 'Copy', 'PasteText', '-', 'Undo', 'Redo',
				], [
					'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
				], [
					'Link', 'Unlink', 'Anchor',
				], [
					'FontSize', 'SpecialChar', 'TextColor', 'BGColor',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter',
					'JustifyRight', 'JustifyBlock', '-',
				], [
					'oembed', 'html5video', 'Image',
				],
			],
			toolbar_HtmlArea3: [
				[
					'Styles', 'Bold', 'HorizontalRule',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Link', 'Unlink',
				],
			],

			toolbar_audi_fussnoten: [
				[
					'Subscript', 'Superscript',
				],
			],

			toolbar_history: [
				[
					'FontSize',
				], [
					'Bold', 'Italic', 'Underline',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'SpecialChar',
				],
			],

			toolbar_newslettertext: [
				[
					'Bold',
				], [
					'BulletedList', 'NumberedList',
				], [
					'Subscript', 'Superscript',
				],
			],

			toolbar_superscript: [
				[
					'Superscript',
				],
			],
			toolbar_bold: [
				[
					'Bold',
				],
			],
			toolbar_boldFootnote: [
				[
					'Bold',
				], [
					'Superscript',
				],
			],
			toolbar_HtmlArea1YT: [
				[
					'Styles', 'Bold', 'Italic', 'Underline',
				], [
					'Link', 'Unlink',
				], [
					'oembed', 'html5video', 'Image',
				],
			],
		},
		config: {
			extraPlugins: 'colordialog,templatePlaceholder',
			stylesCombo_stylesSet: 'audiStyles',
			contentsCss: [
				context + '/page/style/css/organism/style.css', context + '/page/style/css/modules/general/text.css',
			],
			dialog_backgroundCoverColor: 'rgb(0,0,0)',
			dialog_backgroundCoverOpacity: 0.8,
			dialog_buttonsOrder: 'rtl',
			dialog_noConfirmCancel: true,
		},
		stylesets: {
			name: 'audiStyles',
			styles: [
				{
					name: 'Schwarz',
					element: 'font',
					attributes: {
						'class': 'defaultText',
					},
				}, {
					name: 'Rot',
					element: 'font',
					attributes: {
						'class': 'important',
					},
				},
			],
		},
	},
	Panel: {
		initialState: 'closed',
		useLocalStorage: false,
	},
	ReportListPanel: {
		cssClass: {
			iconExpand: 'moveUp',
			iconClosed: 'moveDown',
		},
	},
	Lightbox: {
		lightboxTriggerSelector: '.superviewButton',
		lightboxOverlaySelector: '.overlay',
		lightboxContainerSelector: '.imageContainer',
		lightboxActionSelector: '.controlContainer',
		lightboxResultSetSelector: 'div.imageList',
		lightboxLinkboxSelector: 'div.linkBox',
		hasWrapper: true,
		lightboxWrapperSelector: '.lightboxContainer',
		wrapperSettings: {
			height: '80%',
			width: '853px',
		},
		hasScaleButton: true,
		lightboxScaleSelector: '.scalePreview',
		closeButtonPosition: {
			outer: false,
			posH: 'right',
			posV: 'top',
		},
		imageMaxFactor: 0.8,
		actionsPositionValue: '0',
		actionsContainerWidth: '100%',
		imageDisplayDelay: 300,
	},
	LightboxTabulation: {
		lightboxTriggerSelector: '.lightboxTrigger',
		lightboxOverlaySelector: '.overlay',
		lightboxResultSetSelector: '~ .imageList:first',
		lightboxActionSelector: '.controlContainer',
		lightboxLinkboxSelector: 'div.linkBox',
		hasWrapper: true,
		lightboxWrapperSelector: '.lightboxContainer',
		overlayContainerSelector: '~ .overlayContainer:first',
		lightboxContainerSelector: '.imageContainer',

		wrapperSettings: {
			height: '80%',
			width: '853px',
		},
		hasScaleButton: true,
		lightboxScaleSelector: '.scalePreview',
		closeButtonPosition: {
			outer: false,
			posH: 'right',
			posV: 'top',
		},
		imageMaxFactor: 0.8,
		actionsPositionValue: '0',
		actionsContainerWidth: '100%',
		imageDisplayDelay: 300,
	},
	ExternalRefresh: {
		reloadCode: 'doRefresh.mandateDownload',
	},
	BildDBLightbox: {
		lightboxTriggerSelector: '#pictureDbPreviewButton',
		lightboxOverlaySelector: '.overlay',
		lightboxContainerSelector: '.imageContainer',
		lightboxActionSelector: '.controlContainer',
		lightboxResultSetSelector: 'div.imageList',
		descriptionTextSelector: '#imageDescriptionText',
		lightboxLinkboxSelector: 'div.linkBox',
		hasWrapper: true,
		lightboxWrapperSelector: '.lightboxContainer',
		wrapperSettings: {
			height: '80%',
			width: '853px',
		},
		hasScaleButton: true,
		lightboxScaleSelector: '.scalePreview',
		closeButtonPosition: {
			outer: false,
			posH: 'right',
			posV: 'top',
		},
		imageDisplayDelay: 300,
		imageMaxFactor: 0.8,
		actionsPositionValue: '0',
		actionsContainerWidth: '100%',
		linkBoxLabelIsClickable: false,
		linkBoxHasControls: false,
		isBildDB: true,
	},
	LightboxPreview: {
		lightboxPreviewOptions: {
			multidevicepreview: true,
		},
	},
	EditViewNewsletter: {
		previewSelector: '#newsletterPreview',
		dynamicZoomOptions: {
			previewSelector: '#newsletterPreview',
		},
		whiteSpaceOnSpecialChars: true,
		basScroll: {
			toggleControls: true,
		},
	},
	NewsletterPreview: {
		fillDesktopTabletPreview: true,
	},
	MarketingKalender: {
		collapsedCategories: [
			'Neuwagen Basiswerbemittel', 'Service Kampagnen (bonusrelevant)', 'Gebrauchtwagen Basiswerbemittel', 'Service Basiswerbemittel',
		],
	},
};

const configuration = mergeConfiguration(config, audiConfiguration);
if (typeof config.CKEditorHandler.toolbars !== 'undefined' && typeof audiConfiguration.CKEditorHandler.toolbars !== 'undefined') {
	configuration.CKEditorHandler.toolbars = mergeCKEditorToolbars(config.CKEditorHandler.toolbars, audiConfiguration.CKEditorHandler.toolbars);
}
export default configuration;
