import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AdblockService } from '../../../adblock/adblock.service';
import { UserService } from '../../../permissions/user/user.service';
import { MsgKeyService } from '../../translate/msg-key.service';
import { IsMobileAction } from '../actions/mobile.actions';
import { AddUserAction, RefreshUserAction, RemoveUserAction, UserActionTypes } from '../actions/user.actions';
import { ApplicationState } from '../application-state';
import { UserQuery } from '../reducers/user.reducer';

@Injectable()
export class UserEffects {

	refresh$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionTypes.RefreshUser),
			switchMap(() => this.userService.getUser()),
			catchError(error => {
				this.router.navigateByUrl('strutsreturn.do?forwardTo=error-default-message');
				return of(null);
			}),
			tap((u) => {
				MsgKeyService.clearCache();
				if (navigator.userAgent.includes('Mobi')) {
					this.store.dispatch(new IsMobileAction());
				}
				this.adblockService.detectAdblock();
			}),
			catchError(() => of(null)),
			switchMap(user => [new RemoveUserAction(), new AddUserAction(user)])
		)
	);

	get$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionTypes.GetUser),
			switchMap(() => this.store.select(UserQuery.getUser).pipe(take(1))),
			filter(user => !user),
			map(() => new RefreshUserAction())
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<ApplicationState>,
		private userService: UserService,
		private adblockService: AdblockService,
		private router: Router) {
	}

}
