import configuration from 'globalConfiguration';
import { merge } from 'helper/mergeHelper';

function BAS() {
	this.currentNavigationChecksum = '';
	this.loadFullPageOnNextNavigationChange = true; // kann auf false gesetzt werden, um unnötige Reloads zu verhindern, wenn die Navigation clientseitig bereits korrekt angepasst wurde
	this.loadFullPage = false;
	this.isLogout = false;
	this.msieversion = this.determineMsieversion();
}

BAS.prototype.getPluginConfiguration = function (pluginName) {
	return merge(configuration.global, configuration[pluginName]);
};

BAS.prototype.getResponseContent = function (data) {
	let htmlData = $(data);
	let html: JQuery<any> | string = '';
	this.loadFullPage = false;
	let actualChecksum = htmlData.find('#checksum')
		.data('checksum') || this.currentNavigationChecksum; // z.B. bei Downloads gibt es keine Checksumme, daher die alte weiterverwenden
	if (((configuration.global.fullPageOnFirstClick || this.currentNavigationChecksum !== '') && (actualChecksum != this.currentNavigationChecksum) && this.loadFullPageOnNextNavigationChange) || this.isLogout) {
		this.loadFullPage = true;
		html = htmlData;
	} else if (htmlData.find('#errorFlag').length > 0) {
		html = htmlData;
	} else {
		if (htmlData.find(configuration.global.contentArea).length > 0) {
			html = htmlData.find(configuration.global.contentArea)
				.contents();
		} else if (htmlData.find(configuration.global.alternateContentContainer).length > 0) {
			html = htmlData.find(configuration.global.alternateContentContainer)
				.contents();
		} else {
			html = htmlData;
		}
	}
	if (this.isLogout) {
		this.isLogout = false;
		this.currentNavigationChecksum = undefined;
	} else {
		this.currentNavigationChecksum = actualChecksum;
	}
	this.loadFullPageOnNextNavigationChange = true;
	return html;
};

BAS.prototype.isFileDownload = function (xhr, data, url) {
	let responseHeader = xhr.getResponseHeader('Content-Type');
	if (responseHeader !== 'text/html;charset=UTF-8' && responseHeader !== undefined) {
		this.handleFileDownload(xhr, data, url);
		return true;
	}
	return false;
};

BAS.prototype.handleFileDownload = function (xhr, data, url) {
	let iframe = $('<iframe></iframe>');
	iframe.css('display', 'none');
	iframe.attr('src', url);
	iframe.appendTo(configuration.global.contentArea);
};

BAS.prototype.checkExcludeTarget = function (element) {
	let result = false;
	$.each(configuration.ContentHandler.exclude, function (i, el) {
		if (element.is(el)) {
			result = true;
		}
	});
	return result;
};

BAS.prototype.isInIgnoreList = function (element) {
	let result = false;
	$.each(configuration.ContentHandler.ignore, function (i, el) {
		if (element.is(el.toString())) {
			result = true;
		}
	});
	return result;
};

BAS.prototype.isInStartup = function (element) {
	let result = false;
	$.each(configuration.ContentHandler.reinitStartup, function (i, el) {
		if (element.is(el.toString())) {
			result = true;
		}
	});
	return result;
};

BAS.prototype.isUiComponent = function (element) {
	let result = false;
	$.each(configuration.ContentHandler.uiComponent, function (i, el) {
		if (element.is(el.toString())) {
			result = true;
		}
	});
	return result;
};

BAS.prototype.isLoadFullPage = function () {
	return this.loadFullPage;
};

BAS.prototype.reconstructCssSelector = function (element) {
	element = $(element);
	let hasClass = element.attr('class') !== undefined,
		hasId = element.attr('id') !== undefined,
		selector = '';

	if (hasId) {
		let ids = element.attr('id')
			.split(' ');
		for (let i = 0; i < ids.length; i++) {
			selector += '#';
			selector += ids[i];
		}
	}
	if (hasClass) {
		let classes = element.attr('class')
			.split(' ');
		for (let j = 0; j < classes.length; j++) {
			selector += '.';
			selector += classes[j];
		}
	}
	return selector;
};

BAS.prototype.determineMsieversion = function () {
	let ua = window.navigator.userAgent;

	let msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	let trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		let rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	let edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// IE 12 => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
};

export default new BAS();
