import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { bufferTime, filter, map, tap } from 'rxjs/operators';

@Injectable()
export class MsgKeyService {

	private static endpoint = 'rest/messages';
	private static htmlEndpoint = 'rest/messagesWithTags';
	private static bufferRequests = true;

	private static msgKeyMap = new Map<string, string>();
	private static msgKeyObservables = new Map<string, Observable<MsgKeyCache>>();
	private static lastReset = -1;

	public static showKeys = false;

	private cache: { [key: string]: string } = {};
	private htmlCache: { [key: string]: string } = {};
	private keys$ = new Subject<MsgKeyRequestWithEndpoint>();

	public static clearCache() {
		this.msgKeyMap.clear();
		this.msgKeyObservables.clear();
		this.lastReset = Date.now();
	}

	public static getLastReset() {
		return this.lastReset;
	}

	constructor(private http: HttpClient) {
		if (MsgKeyService.bufferRequests) {
			this.keys$.pipe(
				bufferTime(0),
				map(requests => this.combineRequests(requests)),
				filter(a => !!Object.keys(a).length)
			)
				.subscribe((endpoints: CombinedMsgKeyRequests) => {
					Object.keys(endpoints)
						.map(endpoint => [endpoint, endpoints[endpoint]])
						.forEach(([endpoint, requests]) => this.resolveRequests(endpoint as string, requests as Array<MsgKeyRequest>));
				});
		}
	}

	resolveWithReplacements(keys: { [key: string]: Array<string> }): Observable<MsgKeyCache> {
		return this.resolve(...Object.keys(keys))
			.pipe(
				map(resolved => Object.keys(keys)
					.reduce((messages, key) => ({ ...messages, [key]: this.replace(resolved[key], keys[key]) }), {})));
	}

	resolve(...keys: Array<string>): Observable<MsgKeyCache> {
		return this.resolveFromEndpoint(MsgKeyService.endpoint, keys, this.cache);
	}

	getMessage(key: string, replacements?: Array<object>): Observable<string> {
		if (MsgKeyService.showKeys) {
			return of(`[${key}]`);
		}
		return this.resolveWithParam(key, replacements)
			.pipe(map(messages => messages[key]));
	}

	getMessageHtmlCached(key: string, replacements?: Array<object>): Observable<string> {
		return this.getMessageWithCache(key, replacements, this.resolveHtml.bind(this));
	}

	getMessageWithCache(
		key: string,
		replacements?: Array<object>,
		keyResolver: (...keys: Array<string>) => Observable<MsgKeyCache> = this.resolve.bind(this)
	): Observable<string> {

		if (MsgKeyService.showKeys) {
			return of(`[${key}]`);
		}

		const cachedMessageKey = MsgKeyService.msgKeyMap.get(key);
		if (cachedMessageKey) {
			const msgKey = this.replaceParams(key, { [key]: cachedMessageKey }, replacements);
			return of(msgKey[key]);
		}

		const cachedObservable = MsgKeyService.msgKeyObservables.get(key);
		if (cachedObservable) {
			return cachedObservable.pipe(
				map(cache => this.replaceParams(key, cache, replacements)[key])
			);
		}

		const observable = keyResolver(key)
			.pipe(
				map(messages => {
					MsgKeyService.msgKeyMap.set(key, messages[key]);
					MsgKeyService.msgKeyObservables.delete(key);
					return messages;
				}));
		MsgKeyService.msgKeyObservables.set(key, observable);
		return observable.pipe(
			map(cache => this.replaceParams(key, cache, replacements)[key])
		);
	}

	getMessageHtml(key: string, replacements?: Array<object>): Observable<string> {
		return this.resolveHtmlWithParam(key, replacements)
			.pipe(map(messages => messages[key]));
	}

	resolveWithParam(key: string, replacements?: Array<object>): Observable<MsgKeyCache> {
		return this.resolve(key)
			.pipe(
				map(messages => this.replaceParams(key, messages, replacements)));
	}

	resolveHtmlWithParam(key: string, replacements?: Array<object>): Observable<MsgKeyCache> {
		return this.resolveHtml(key)
			.pipe(
				map(messages => this.replaceParams(key, messages, replacements)));
	}

	replaceParams(key: string, message: MsgKeyCache, replacements?: Array<object>): MsgKeyCache {
		if (replacements && replacements[0] !== undefined) {
			replacements.forEach(function (replaceObject: any): void {
				const replaceKey = Object.keys(replaceObject)[0];
				const replaceValue = replaceObject[replaceKey];
				message[key] = message[key].split(replaceKey)
					.join(replaceValue);
			});
		}
		return message;
	}

	resolveHtml(...keys: Array<string>): Observable<MsgKeyCache> {
		return this.resolveFromEndpoint(MsgKeyService.htmlEndpoint, keys, this.htmlCache);
	}

	private replace(translated: string, replacements: Array<string>): string {
		let replacedStrings = 0;
		return translated.replace(/%(\d+|\w+%)/g, (match, toBeReplaced) => {
			const index = Number(toBeReplaced);
			if (isNaN(index)) {
				return replacements[replacedStrings++];
			}
			return replacements[index - 1] || match;
		});
	}

	private resolveFromEndpoint(endpoint: string, keys: Array<string>, cache: MsgKeyCache): Observable<MsgKeyCache> {
		if (MsgKeyService.showKeys) {
			const cacheObj: MsgKeyCache = {};
			keys.forEach(key => {
				cacheObj[key] = `[${key}]`;
			});
			return of(cacheObj);
		}

		let fromCache = {};
		cache = {};
		const keysToResolve = keys.filter(key => {
			const inCache = key in cache;
			if (inCache) {
				fromCache = {
					...fromCache,
					[key]: cache[key]
				};
			}

			return !inCache;
		});

		if (keysToResolve.length === 0) {
			return of(fromCache);
		}

		const safeToCache = (resolved: MsgKeyCache): void => Object.keys(resolved)
			.forEach(key => cache[key] = resolved[key]);

		if (MsgKeyService.bufferRequests) {
			const subject = new Subject<MsgKeyCache>();

			const request: MsgKeyRequestWithEndpoint = {
				endpoint,
				subject,
				keys: keysToResolve
			};

			this.keys$.next(request);

			return subject.asObservable()
				.pipe(
					tap(safeToCache),
					map(resolved => ({ ...resolved, ...fromCache })));
		}

		return this.http.get<MsgKeyCache>(endpoint, { params: { 'key': keysToResolve } })
			.pipe(
				tap(safeToCache),
				map(resolved => ({ ...resolved, ...fromCache }))
			);
	}

	private combineRequests(requests: Array<MsgKeyRequestWithEndpoint>): CombinedMsgKeyRequests {
		let combined = {};

		for (let i = 0; i < requests.length; ++i) {
			const { endpoint, subject, keys } = requests[i];
			const currentRequestsOfEndpoint = combined[endpoint] || [];

			combined = {
				...combined,
				[endpoint]: [...currentRequestsOfEndpoint, { subject, keys }]
			};
		}

		return combined;
	}

	private resolveRequests(endpoint: string, requests: Array<MsgKeyRequest>): void {
		const keys = requests.reduce((a, b) => [...a, ...b.keys], [])
			.filter((key, i, arr) => i === arr.lastIndexOf(key));
		const toCacheResult = (result: MsgKeyCache): (res: MsgKeyCache, key: string) => MsgKeyCache =>
			(res: MsgKeyCache, key: string): MsgKeyCache => ({
				...res,
				[key]: result[key]
			});

		const responses = [];
		for (let i = 0; i < keys.length; i += 25) {
			const chunk = keys.slice(i, i + 25);
			responses.push(this.http.get<MsgKeyCache>(endpoint, { params: { 'key': chunk } }));
		}

		forkJoin(responses).subscribe((result: Object[]) => {
			const mergedResult: any = result.reduce((prev: Object, cur: Object) => {
				return Object.assign(prev, cur);
			}, {});

			requests.forEach(request => {
				const resultOfRequest = request.keys.reduce(toCacheResult(mergedResult), {});
				request.subject.next(resultOfRequest);
				request.subject.complete();
			});
		});
	}

}

export interface MsgKeyCache {
	[key: string]: string;
}
export interface MsgKeyRequest {
	subject: Subject<MsgKeyCache>;
	keys: Array<string>;
}
export interface MsgKeyRequestWithEndpoint extends MsgKeyRequest {
	endpoint: string;
}

export interface CombinedMsgKeyRequests {
	[endpoint: string]: Array<MsgKeyRequest>;
}
