import { forkJoin, Observable } from 'rxjs';

import { map } from 'rxjs/operators';

export class PermissionBuilder {

	static and(...a: Array<Observable<boolean>>): Observable<boolean> {
		return forkJoin([...a])
			.pipe(map(array => array.reduce((va, vb) => va && vb)));
	}

	static or(...a: Array<Observable<boolean>>): Observable<boolean> {
		return forkJoin([...a])
			.pipe(map(array => array.reduce((va, vb) => va || vb)));
	}

	static not(a: Observable<boolean>): Observable<boolean> {
		return a.pipe(map(v => !v));
	}
}
