import * as sourcemappedStacktrace from 'sourcemapped-stacktrace';

declare global {
	interface Window {
		logRemoteInfo: (type, data) => void;
		logRemoteWarn: (type, data) => void;
		logRemoteError: (type, data) => void;
	}
}

window.logRemoteInfo = function (type, data) {
	_logRemote('info', type, data);
};

window.logRemoteWarn = function (type, data) {
	sourcemappedStacktrace.mapStackTrace(new Error().stack, function (stack) {
		const logData = {
			stack: stack,
		};
		for (let i in data) {
			logData[i] = data[i];
		}
		_logRemote('warn', type, logData);
	});
};

window.logRemoteError = function (type, data) {
	sourcemappedStacktrace.mapStackTrace(new Error().stack, function (stack) {
		const logData = {
			log_stack: stack,
			currentUrl: location.href,
			message: undefined,
		};
		if (typeof data == 'string') {
			logData.message = data;
		} else {
			for (let i in data) {
				logData[i] = data[i];
			}
		}
		_logRemote('error', type, logData);
	});
};

if (window.console && window.console.error) {
	const errorRef = console.error;
	console.error = function () {
		errorRef.apply(this, arguments);

		let args = [];
		for (let i in arguments) {
			try {
				args[i] = JSON.stringify(arguments[i]);
			} catch (e) {
				args[i] = arguments[i] + '';
			}
		}

		window.logRemoteError('consoleerror', args);
	};
}

let _logRemote = function (loglevel, type, data) {
	$.ajax({
		url: context + '/rest/clientlog/legacy/' + loglevel + '/' + type,
		type: 'PUT',
		dataType: 'JSON',
		data: { json: JSON.stringify(data) },
	});
};
