<ng-container *ngIf="items?.length">
	<ng-container *ngFor="let item of items">
		<bas-side-nav-item
				[item]="item"
				[class.hasChildren]="item.children?.length"
				[active]="showChildren(item)"
		></bas-side-nav-item>
		<bas-side-nav-hotlink
				*ngIf="showChildren(item)()"
				[items]="item.children"
		></bas-side-nav-hotlink>
	</ng-container>
</ng-container>
