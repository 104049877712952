import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * @group Generic Compounds
 * @component Hint
 */
@Component({
	selector: 'bas-hint',
	templateUrl: './hint.component.html',
	styleUrls: ['./hint.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintComponent {

	/** Hinweistext */
	@Input()
	hint: string;

	@Input()
	tiny: boolean = false;

	@Input()
	newicon: boolean = false;
}
