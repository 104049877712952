import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FeatureModule } from '../../permissions/feature/feature.module';
import { UserModule } from '../../permissions/user/user.module';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { MsgKeyModule } from '../../shared/translate/msg-key.module';
import { FooterContactComponent } from './footer-contact/footer-contact.component';
import { FooterCopyrightComponent } from './footer-copyright/footer-copyright.component';
import { FooterFaqComponent } from './footer-faq/footer-faq.component';
import { FooterHelpComponent } from './footer-help/footer-help.component';
import { FooterHistoryComponent } from './footer-history/footer-history.component';
import { FooterImpressumComponent } from './footer-impressum/footer-impressum.component';
import { FooterPrivacyPolicyComponent } from './footer-privacy-policy/footer-privacy-policy.component';
import { FooterServerInfoComponent } from './footer-serverinfo/footer-serverinfo.component';
import { FooterTermsOfUseComponent } from './footer-terms-of-use/footer-terms-of-use.component';
import { FooterComponent } from './footer.component';
import { FooterMravComponent } from './footer-mrav/footer-mrav.component';

@NgModule({
	declarations: [
		FooterComponent,
		FooterServerInfoComponent,
		FooterCopyrightComponent,
		FooterFaqComponent,
		FooterMravComponent,
		FooterTermsOfUseComponent,
		FooterImpressumComponent,
		FooterContactComponent,
		FooterHistoryComponent,
		FooterPrivacyPolicyComponent,
		FooterHelpComponent,
	],
	imports: [
		CommonModule,
		MsgKeyModule,
		FeatureModule,
		UserModule,
		BrowserAnimationsModule,
		RouterModule,
		DirectivesModule,
		MatIconModule,
	],
	exports: [
		FooterComponent,
		FooterServerInfoComponent,
	],
})
export class FooterModule {
	constructor() {
	}
}
