import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class VirusScanInterceptor implements HttpInterceptor {

	constructor(private injector: Injector) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				catchError((error) => {
					if (error instanceof HttpErrorResponse) {
						const virusInfectedRedirect = error.headers.get('x-virus-infected');
						if (virusInfectedRedirect) {
							const router = this.injector.get(Router);
							router.navigateByUrl(virusInfectedRedirect);
							throw new Error("x-virus-infected");
						}
					}
					throw error;
				})
			);
	}
}