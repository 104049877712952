import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ButtonComponent, ButtonLineComponent, CheckboxComponent, FieldComponent, GroupComponent, HintComponent, InputComponent, LabelComponent,
	TextareaComponent, ValidationContextDirective,
} from '@bas/ui';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { switchMap } from 'rxjs/operators';
import { InputValidationService } from '../service/input-validation/input-validation.service';
import { HeadlineModule } from '../shared/elements/headline/headline.module';
import { BreadcrumbModule } from '../shared/generic-compounds/breadcrumb/breadcrumb.module';
import { RequiredHintComponent } from '../shared/generic-compounds/required-hint/required-hint.component';
import { RoutedPage } from '../shared/routed-page/routed-page';
import { ApplicationState } from '../shared/store/application-state';
import { MsgKeyModule } from '../shared/translate/msg-key.module';
import { AdvService } from './adv.service';

@Component({
	selector: 'bas-adv-generate',
	standalone: true,
	imports: [
		CommonModule, ReactiveFormsModule, ValidationContextDirective, GroupComponent, FieldComponent, InputComponent,
		LabelComponent, MsgKeyModule, ButtonComponent, ButtonLineComponent, TextareaComponent, CheckboxComponent, HintComponent,
		BreadcrumbModule, HeadlineModule, RequiredHintComponent,
	],
	providers: [InputValidationService],
	templateUrl: './adv-generate.component.html',
	styleUrls: ['./adv-generate.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvGenerateComponent extends RoutedPage<any> implements OnInit {

	advForm = this.fb.nonNullable.group({
		legalName: ['', Validators.required],
		streetAndNumber: ['', Validators.required],
		zip: ['', { validators: Validators.required, asyncValidators: this.inputValidationService.zipValidator(), updateOn: 'blur' }],
		city: ['', Validators.required],
		representedBy: ['', Validators.required],
		comment: [''],
		confirmOverrideAdv: [false],
	});

	overrideAdv = false;

	constructor(
		store: Store<ApplicationState>,
		activatedRoute: ActivatedRoute,
		router: Router,
		private fb: FormBuilder,
		private advService: AdvService,
		private inputValidationService: InputValidationService,
	) {
		super(store, activatedRoute, router, {
			description: 'adv.generate.description',
			url: router.url,
			id: UUID.UUID(),
			helpTopic: 'adv',
			noUseForBacklink: false,
		});
	}

	autoRemoveAjaxRotator() {
		return false;
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.advService.getAdvData()
			.subscribe(advData => {
				this.overrideAdv = advData.overrideAdv;
				this.advForm.patchValue(advData);
				if (this.overrideAdv) {
					this.advForm.controls.comment.addValidators(Validators.required);
					this.advForm.controls.confirmOverrideAdv.addValidators(Validators.requiredTrue);
				}
				this.rotatorService.removePageLoadRotator();
			});
	}

	save(): void {
		this.advForm.updateValueAndValidity();
		if (this.advForm.valid) {
			const rotatorId = this.rotatorService.addRotator();
			this.advService.createAdv(this.advForm.getRawValue())
				.pipe(switchMap(() => this.route.queryParams))
				.subscribe(
					queryParams => {
						const saveForward = queryParams['forward'];
						this.router.navigateByUrl(saveForward);
						this.rotatorService.removeRotator(rotatorId);
					},
				);
		} else {
			this.advForm.markAllAsTouched();
			Object.values(this.advForm.controls)
				.forEach(control => control.markAsDirty());
		}
	}
}
