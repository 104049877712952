import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as detectAdblock from 'adblock-detect';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { FeatureService } from '../permissions/feature/feature.service';
import { ApplicationState } from '../shared/store/application-state';
import { UserQuery } from '../shared/store/reducers/user.reducer';
import { AdblockDialogComponent } from './adblock-dialog.component';

@Injectable()
export class AdblockService {
	private static SESSION_STORAGE_KEY = 'adblock-checked';

	private set alreadyChecked(value: boolean) {
		sessionStorage.setItem(AdblockService.SESSION_STORAGE_KEY, `${ value }`);
	}

	private get alreadyChecked(): boolean {
		return sessionStorage.getItem(AdblockService.SESSION_STORAGE_KEY) === 'true';
	}

	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
		private featureService: FeatureService,
		private store: Store<ApplicationState>,
	) {
	}

	public detectAdblock(): void {
		this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				take(1),
				filter(() => !this.alreadyChecked),
				switchMap(() => this.hasAdblock()),
				take(1),
				filter(Boolean),
				tap(() => this.alreadyChecked = true),
				catchError(() => of(null)),
				switchMap(() => this.featureService.has('adblockDetect')),
				filter(Boolean),
				switchMap(() => this.http.post('rest/adblock/log', {})),
			)
			.subscribe(() => {
				this.dialog.open(AdblockDialogComponent, { disableClose: true, height: '210px' });
			});
	}

	private hasAdblock(): Observable<boolean> {
		return new Observable(subscriber => detectAdblock(val => subscriber.next(val)));
	}
}
