import { MobileState } from '../states/mobile.state';
import { MobileActions, MobileActionTypes } from '../actions/mobile.actions';
import { ApplicationState } from '../application-state';

const initialState: MobileState = {
	isMobile: false,
};

export function mobileReducer(state: MobileState = initialState, action: MobileActions) {
	switch (action.type) {
		case MobileActionTypes.isMobileAction:
			if (state.isMobile) {
				return state;
			}

			return {
				...state,
				isMobile: true
			};
		case MobileActionTypes.isNotMobileAction:
			if (!state.isMobile) {
				return state;
			}

			return {
				...state,
				isMobile: false
			};
		default:
			return state;
	}
}

export namespace MobileQuery {
	export const isMobile = (state: ApplicationState) => state.mobileState.isMobile;
}

