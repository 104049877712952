import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@bas/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AdblockModule } from './adblock/adblock.module';
import { routes } from './app.route';
import { ErrorFallbackModule } from './error-fallback/error-fallback.module';
import { LoggingModule } from './logging/logging.module';
import { NavigationModule } from './navigation/navigation.module';
import { NavigationEffects } from './navigation/store/navigation.effects';
import { PermissionsModule } from './permissions/permissions.module';
import { ErrorModule } from './shared/elements/error/error.module';
import { LinkModule } from './shared/elements/link/link.module';
import { DialogModule } from './shared/generic-compounds/dialog/dialog.module';
import { applicationReducers } from './shared/store/application-reducers';
import { clearState } from './shared/store/clearState-reducers';
import { BreadcrumbsEffects } from './shared/store/effects/breadcrumbs.effects';
import { UserEffects } from './shared/store/effects/user.effects';
import { MsgKeyModule } from './shared/translate/msg-key.module';

const developmentImports: Array<any[] | ModuleWithProviders<{}> | Type<any>> = [
	StoreDevtoolsModule.instrument({ maxAge: 25 }),
];
const productiveImports: Array<any[] | ModuleWithProviders<{}> | Type<any>> = [];

const environmentImports = environment.production ? productiveImports : developmentImports;

export const sharedModules: Array<any[] | ModuleWithProviders<{}> | Type<any>> = [
	RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
	StoreModule.forRoot(applicationReducers, {
		metaReducers: [clearState],
	}),
	EffectsModule.forRoot([UserEffects, BreadcrumbsEffects, NavigationEffects]),

	BrowserModule,
	BrowserAnimationsModule,
	FormsModule,
	HttpClientModule,
	ErrorFallbackModule,
	MaterialModule,
	MsgKeyModule.forRoot(),
	NavigationModule,
	PermissionsModule,
	LinkModule,
	LoggingModule,
	AdblockModule,
	ErrorModule.forRoot(),
	DialogModule.forRoot(),
	...environmentImports,
];
