import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdvData } from './adv-data';

@Injectable({ providedIn: 'root' })
export class AdvService {
	constructor(private http: HttpClient) {
	}

	getAdvData(): Observable<AdvData> {
		return this.http.get<AdvData>('rest/preferences/adv');
	}

	createAdv(advData: AdvData): Observable<any> {
		return this.http.post('rest/preferences/adv/create', advData);
	}
}
