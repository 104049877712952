import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FeatureProvider } from '../models/feature-provider';
import { FeatureResponse } from '../models/feature-response';

export enum Feature {
	spellchecker
}

@Injectable()
export class FeatureService implements FeatureProvider {

	private static cache: Map<string, { pending?: Observable<FeatureResponse>, resolved?: FeatureResponse }> = new Map();

	constructor(private http: HttpClient) { }

	public hasFeature(feature: Feature): Observable<boolean> {
		return this.has(Feature[feature]);
	}

	public has(feature: string): Observable<boolean> {
		return this.getFeature(feature)
			.pipe(map(resp => resp.active));
	}

	public is(feature: string, value: string): Observable<boolean> {
		return this.getFeatureValue(feature)
			.pipe(map(val => val === value));
	}

	public any(features: Array<string>): Observable<boolean> {
		const requests = features.map(feature => this.http.get<FeatureResponse>('rest/features/' + feature));
		return forkJoin(requests)
			.pipe(map(resps => resps.some(resp => resp.active)));
	}

	public getFeatureValue(feature: string): Observable<string> {
		return this.getFeature(feature)
			.pipe(map(resp => resp.value));
	}

	public getFeature(feature: string): Observable<FeatureResponse> {
		let cache = FeatureService.cache.get(feature);
		if (!cache) {
			cache = {};
			FeatureService.cache.set(feature, cache);

		}

		if (cache.resolved !== undefined) {
			return of(cache.resolved);
		}

		if (cache.pending !== undefined) {
			return cache.pending;
		}
		const subject = new Subject<FeatureResponse>();
		const subscription = this.http.get<FeatureResponse>('rest/features/' + feature)
			.subscribe(featureResponse => {
				cache.resolved = featureResponse;
				subject.next(featureResponse);
				subject.complete();
				subscription.unsubscribe();
			});

		cache.pending = subject.asObservable();
		return cache.pending;
	}
}
