import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../shared/store/application-state';
import { NavigationQuery } from '../../store/navigation.reducer';

@Component({
	selector: 'bas-side-nav-external-links',
	templateUrl: './side-nav-external-links.component.html',
	styleUrls: ['./side-nav-external-links.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavExternalLinksComponent {
	items = computed(() => this.store.selectSignal(NavigationQuery.items)()
		.filter(item => item.parameters['showInAltNav']));

	constructor(private store: Store<ApplicationState>) {
	}
}
