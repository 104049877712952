import { Injectable } from '@angular/core';
import { MessageResolver } from '@bas/ui';
import { Observable } from 'rxjs';
import { MsgKeyService } from './msg-key.service';

@Injectable()
export class BuiMessageResolver implements MessageResolver {

	constructor(private msgKeyService: MsgKeyService) {
	}

	resolve(
		key: string,
		replacements: {
			[replacementKey: string]: string
		} = {},
	): Observable<string> {
		return this.msgKeyService.getMessageWithCache(key, Object.entries(replacements)
			.map(([replacementKey, replacement]) => ({
				[`%${ replacementKey }%`]: replacement,
			})));
	}
}
