interface SelectorConfig {
	name?: string,
	requirePath?: string, // should be : keyof import("../type/define").ImportMap
	initializationContext?: string | Document,
	configurationName?: string
	configuration?: string
	canDeactivateInDebug?: boolean,
	loadOnlyOnce?: boolean
}

export interface SelectorConfiguration {

	[key: string]: {
		[key: number]: SelectorConfig
	} | Array<SelectorConfig>;

	startup: Array<SelectorConfig>;
}

export default {
	startup: [
		{
			name: 'AjaxRotator',
			requirePath: 'handler/handler.ajaxRotator',
			initializationContext: document,
		}, {
			name: 'ContentHandler',
			requirePath: 'handler/handler.content',
			initializationContext: document,
		}, {
			name: 'CKEditorHandler',
			requirePath: 'handler/handler.ckeditor',
			initializationContext: document,
		}, {
			name: 'SectionLoader',
			requirePath: 'plugins/jquery.sectionLoader',
			initializationContext: document,
		}, {
			requirePath: 'misc/log',
		}, {
			requirePath: 'misc/error',
		}, {
			name: 'TabIndexer',
			requirePath: 'helper/tabIndexer',
			initializationContext: document,
			configurationName: 'TabIndexer',
		}, {
			name: 'ViewportLogging',
			requirePath: 'plugins/jquery.viewportLogging',
			initializationContext: document,
			canDeactivateInDebug: true,
		}, {
			name: 'SectionScrollHelper',
			requirePath: 'helper/sectionScrollHelper',
			initializationContext: document,
		},
	],
	'.img-button.detail': {
		1: {
			name: 'ImageButton',
			requirePath: 'plugins/jquery.imageButton',
		},
	},
	'#basketTrigger': {
		1: {
			name: 'BasketWidget',
			requirePath: 'plugins/jquery.basketWidget',
		},
	},

	'form[name=loginmask]': {
		1: {
			name: 'LoginHandler',
			requirePath: 'handler/handler.login',
		},
	},
	'div.overviewImage.standard': {
		1: {
			name: 'EditViewStandard',
			requirePath: 'plugins/editView/jquery.editViewStandard',
			initializationContext: '#editMask',
			configurationName: 'EditViewStandard',
		},
	},
	'div.overviewImage.pos': {
		1: {
			name: 'EditViewStandard',
			requirePath: 'plugins/editView/jquery.editViewStandard',
			initializationContext: '#editMask',
			configurationName: 'EditViewStatic',
		},
	},
	'div.overviewImage.newsletter': {
		1: {
			name: 'EditViewNewsletter',
			requirePath: 'plugins/editView/jquery.editViewNewsletter',
		},
	},
	'div.overviewImage.newslettertm': {
		1: {
			name: 'EditViewNewsletterTm',
			requirePath: 'plugins/editView/jquery.editViewNewsletterTm',
		},
	},
	'div.overviewImage.banner': {
		1: {
			name: 'EditViewBanner',
			requirePath: 'plugins/editView/jquery.editViewBanner',
			initializationContext: '#editMask',
		},
	},
	'div.overviewImage.some': {
		1: {
			name: 'EditViewStandard',
			requirePath: 'plugins/editView/jquery.editViewStandard',
			initializationContext: '#editMask',
			configurationName: 'EditViewSome',
		},
	},
	'#editMask.webtm': {
		1: {
			name: 'Webtm',
			requirePath: 'plugins/webtm/jquery.webtm',
		},
	},
	'div > a.lightboxTrigger': {
		1: {
			name: 'Lightbox',
			requirePath: 'plugins/lightbox/jquery.lightbox',
			initializationContext: '.hasLightbox',
			configurationName: 'Lightbox',
		},
	},
	'table.tabulation .lightboxTrigger': {
		1: {
			name: 'LightboxTabulation',
			requirePath: 'plugins/lightbox/jquery.lightboxTabulation',
			configurationName: 'LightboxTabulation',
		},
	},
	'#pictureDbPreviewButton': {
		1: {
			name: 'LightboxBildDB',
			requirePath: 'plugins/lightbox/jquery.lightboxBildDB',
			initializationContext: '.hasLightbox',
			configurationName: 'BildDBLightbox',
		},
	},
	'.resultSet.kompakt .resultSetItem, .resultSet.detail': {
		1: {
			name: 'SelectPreviewSwitch',
			requirePath: 'plugins/jquery.selectPreviewSwitch',
			configurationName: 'SelectPreviewSwitch',
		},
	},
	'.cropBox': {
		1: {
			name: 'JcropHelper',
			requirePath: 'plugins/jquery.initJcrop',
		},
	},
	'.dialog:not(.inactive), .openDialog:not(.inactive)': {
		1: {
			name: 'Dialog',
			requirePath: 'plugins/jquery.dialog',
		},
	},
	'div.jquery_tabs': {
		1: {
			name: 'Tabs',
			requirePath: 'plugins/jquery.tabs',
		},
	},
	'.marketing-planer-kalender': {
		1: {
			name: 'MarketingKalender',
			requirePath: 'plugins/marketingplaner/kalender/kalender.plugin',
		},
	},
	'.budget-overview': {
		1: {
			name: 'BudgetOverview',
			requirePath: 'plugins/marketingplaner/budget/budget.plugin',
		},
	},
	'.jahresbudget': {
		1: {
			name: 'Jahresbudget',
			requirePath: 'plugins/marketingplaner/budget/jahresbudget.plugin',
		},
	},
	'div.panel': {
		1: {
			name: 'Panel',
			requirePath: 'plugins/jquery.panel',
		},
	},
	'div.panel.smartFilter': {
		1: {
			name: 'SmartFilter',
			requirePath: 'plugins/jquery.smartFilter',
		},
	},
	'.reportItem': {
		1: {
			name: 'Panel',
			requirePath: 'plugins/jquery.panel',
			configurationName: 'ReportListPanel',
		},
	},
	'section.bas-formSection.toggle.groupButtonToggle': {
		1: {
			name: 'Panel',
			requirePath: 'plugins/jquery.panel',
			configurationName: 'GroupButtonPanel',
		},
	},
	'.tooltipTrigger': {
		1: {
			name: 'SmarterTooltip',
			requirePath: 'plugins/tooltip/jquery.smarterTooltip',
			configurationName: 'SmarterTooltip',
		},
	},
	'form': {
		1: {
			name: 'FormHandler',
			requirePath: 'handler/handler.form',
		},
	},
	'.datepicker': {
		1: {
			name: 'makeDatepicker',
			requirePath: 'plugins/datepicker/jquery.datepicker',
			configurationName: 'MakeDatepicker',
		},
	},
	'.monthpicker': {
		1: {
			name: 'monthpicker',
			requirePath: 'plugins/monthpicker/jquery.monthpicker',
			configurationName: 'monthpicker',
		},
	},
	'.dayspicker': {
		1: {
			name: 'dayspicker',
			requirePath: 'plugins/dayspicker/jquery.dayspicker',
		},
	},
	'.autocomplete': {
		1: {
			name: 'AutoComplete',
			requirePath: 'plugins/autocomplete/jquery.ui.ba-autocomplete',
		},
	},
	'#progressMonitor': {
		1: {
			name: 'ProgressMonitor',
			requirePath: 'plugins/progressMonitor/jquery.progressMonitor',
		},
	},
	'.checkboxGroupSelector input[type="checkbox"]': {
		1: {
			name: 'GroupSelectionCheckbox',
			requirePath: 'plugins/jquery.groupSelectionCheckbox',
		},
	},
	'input[id^="chosenFilter"]': {
		1: {
			name: 'ChosenFilter',
			requirePath: 'plugins/jquery.chosenFilter',
		},
	},
	'#bannerOverview': {
		1: {
			name: 'BannerLightbox',
			requirePath: 'plugins/lightbox/jquery.bannerLightbox',
		},
	},
	'.lockCheckboxes,.unlockCheckboxes': {
		1: {
			name: 'LockCheckbox',
			requirePath: 'plugins/jquery.lockCheckbox',
		},
	},
	'#superviewForward': {
		1: {
			name: 'SuperviewForward',
			requirePath: 'plugins/superviewForward/jquery.superviewForward',
		},
	},
	'#debugItems': {
		1: {
			name: 'DebugHelper',
			requirePath: 'misc/debug',
		},
	},
	'.printElement, .defaultLinkprintElement': {
		1: {
			name: 'PrintHelper',
			requirePath: 'helper/jquery.printHelper',
		},
	},
	'.calculatePopup': {
		1: {
			name: 'CalculatePopup',
			requirePath: 'plugins/calculatePopup/jquery.calculatePopup',
		},
	},
	'.externalRefresh': {
		1: {
			name: 'ExternalRefresh',
			requirePath: 'plugins/jquery.externalRefresh',
		},
	},
	'.toTop': {
		1: {
			name: 'ToTop',
			requirePath: 'plugins/jquery.toTop',
			configuration: 'Totop',

		},
	},
	'#dynamicTree': {
		1: {
			name: 'BasJsTree',
			requirePath: 'plugins/jquery.ba-jstree',
		},
	},
	'#areaTree': {
		1: {
			name: 'AreaTree',
			configurationName: 'AreaTreeBehaviour',
			requirePath: 'plugins/jquery.area-jstree',
		},
	},
	'#areaCbTree': {
		1: {
			name: 'AreaCbTree',
			configurationName: 'AreaTreeBehaviour',
			requirePath: 'plugins/jquery.area-cb-jstree',
		},
	},
	/*'#quicknavContainer': {
		1: {
			name: 'Gridify',
			requirePath: 'plugins/jquery.gridify'
		}
	},*/
	'#anyXmlMask': {
		1: {
			name: 'AnyXmlMask',
			requirePath: 'plugins/jquery.anyXmlMask',
		},
	},
	'.archiveNewsletterPreview': {
		1: {
			name: 'NewsletterPreview',
			requirePath: 'plugins/jquery.newsletterpreview',
		},
	},
	'.archiveNewsletterTmPreview': {
		1: {
			name: 'NewsletterTmPreview',
			requirePath: 'plugins/jquery.newslettertmpreview',
		},
	},
	'table.tabulation': {
		1: {
			name: 'StinkyTableHeader',
			requirePath: 'plugins/jquery.stinkyTableHeader',
		},
	},
	'div.floatingPageHeader': {
		1: {
			name: 'FloatingPageHeader',
			requirePath: 'plugins/jquery.floatingPageHeader',
		},
	},
	'div.werbemittel-container': {
		1: {
			name: 'WerbeformKachel',
			requirePath: 'plugins/jquery.werbeformKachel',
		},
	},
	'.uploadButton a': {
		1: {
			name: 'UploadButton',
			requirePath: 'plugins/jquery.uploadButton',
		},
	},
	'#browsersupport': {
		1: {
			name: 'Browsersupport',
			requirePath: 'plugins/jquery.browsersupport',
		},
	},
	'input[type="file"]:eq(0)': {
		1: {
			name: 'FileUploadValidator',
			requirePath: 'plugins/validators/jquery.fileUpload',
		},
	},
	'.triggerUpload': {
		1: {
			name: 'TriggerUpload',
			requirePath: 'plugins/jquery.triggerUpload',
		},
	},
	'.favIcon': {
		1: {
			name: 'FavoriteReport',
			requirePath: 'plugins/jquery.favoriteReport',
			initializationContext: '.reportItem',
		},
	},
	'.charcounter': {
		1: {
			name: 'CharCounter',
			requirePath: 'plugins/jquery.charcounter',
		},
	},
	'.triggerFavoriteReportMark': {
		1: {
			name: 'TriggerFavoriteReportMark',
			requirePath: 'plugins/jquery.triggerFavoriteReportMark',
		},
	},
	'select:not([size]):not(.dynamicTreeNodeFilterOption,.dynamicTreeNodeAttribute,.noCustomStyling, .cke_dialog_ui_input_select)': {
		1: {
			name: 'Chosen',
			requirePath: 'handler/handler.chosen',
		},
	},
	'.reportDownload': {
		1: {
			name: 'ReportDownload',
			requirePath: 'plugins/jquery.reportDownload',
		},
	},
	'.clockpicker': {
		1: {
			name: 'ClockPicker',
			requirePath: 'plugins/jquery.clockpicker',
			configuration: 'ClockPicker',
		},
	},
	'input[type=checkbox][disabled][data-enabled-by-click]': {
		1: {
			name: 'EnableByClick',
			requirePath: 'plugins/jquery.enableByClick',
		},
	},
	'input[type=radio][disabled][data-enabled-by-click]': {
		1: {
			name: 'EnableByClick',
			requirePath: 'plugins/jquery.enableByClick',
		},
	},
	'div[data-toggle-by-click]': {
		1: {
			name: 'ToggleByClick',
			requirePath: 'plugins/jquery.toggleByClick',
		},
	},
	'#gErrorsBox .box.notification .notificationContent div.text_notification': {
		1: {
			name: 'Toastr',
			requirePath: 'plugins/jquery.toastr',
		},
	},
	'.urlChecker': {
		1: {
			name: 'UrlChecker',
			requirePath: 'plugins/jquery.urlChecker',
		},
	},
	'.disable-after-click': {
		1: {
			name: 'DownloadLink',
			requirePath: 'plugins/jquery.downloadLink',
		},
	},
	'.treeNode': {
		1: {
			name: 'TreeNodeAutoSelect',
			requirePath: 'plugins/jquery.treeNodeAutoSelect',
		},
	},
	'.copyToClipboard': {
		1: {
			name: 'CopyToClipboard',
			requirePath: 'plugins/jquery.copyToClipboard',
		},
	},
	'.umfrageToggle': {
		1: {
			name: 'UmfrageToggle',
			requirePath: 'plugins/jquery.umfrageToggle',
		},
	},
	'.downloadAutomatically': {
		1: {
			name: 'DownloadAutomatically',
			requirePath: 'plugins/jquery.downloadAutomatically',
		},
	},
	'input[trigger-button-on-enter], div[trigger-button-on-enter]': {
		1: {
			name: 'TriggerButtonOnEnter',
			requirePath: 'plugins/jquery.triggerButtonOnEnter',
		},
	},
	'textarea.hasSmileypicker': {
		1: {
			name: 'SmileyPicker',
			requirePath: 'plugins/jquery.smileyPicker',
		},
	},
} as SelectorConfiguration;
