function FeatureHelper() {
}

FeatureHelper.prototype = {

	hasFeature: function (requestedFeature) {
		return $.ajax({
			url: context + '/rest/features/' + requestedFeature,
			type: 'GET',
			dataType: 'json',
		})
			.then(function (response) {
				return response.active;
			});
	},
};

export default new FeatureHelper();
