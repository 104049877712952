import TooltipBase from 'plugins/tooltip/jquery.tooltipBase';

var pluginName = 'ErrorTooltip',
	defaults = {
		noseHeight: 0,
		cssClasses: [
			'topright', 'midright', 'bottomright', 'bottomcenter',
		],
		debounceTime: 300,
		tooltipTriggerClass: 'errorTooltipTrigger',
	};

function ErrorTooltip(element, options) {
	this.tooltipTrigger = $(element);
	this.options = $.extend({}, defaults, options);
	this.init();
	this.initListener();
}

ErrorTooltip.prototype = new TooltipBase();

ErrorTooltip.prototype.initListener = function () {
};

ErrorTooltip.prototype.getCssForPosition = function (position, dimensionOfTooltipTrigger) {
	var css = {
		top: 0,
		left: 0,
	};
	if (position.indexOf('top') !== -1) {
		css.top = dimensionOfTooltipTrigger.top - this.tooltipTrigger.data('tooltip')
			.actual('outerHeight');
	}
	if (position.indexOf('mid') !== -1) {
		css.top = dimensionOfTooltipTrigger.top;
	}
	if (position.indexOf('bottom') !== -1) {
		css.top = dimensionOfTooltipTrigger.top + dimensionOfTooltipTrigger.height;
	}
	if (position.indexOf('center') !== -1) {
		css.left = dimensionOfTooltipTrigger.left - this.tooltipTrigger.data('tooltip')
			.actual('outerWidth') + dimensionOfTooltipTrigger.width + this.getHorizontalOffset(dimensionOfTooltipTrigger);
	}
	if (position.indexOf('right') !== -1) {
		css.left = dimensionOfTooltipTrigger.left + dimensionOfTooltipTrigger.width + this.getHorizontalOffset(dimensionOfTooltipTrigger);
	}
	return css;
};

ErrorTooltip.prototype.getHorizontalOffset = function (dimensionOfTooltipTrigger) {
	var offset = 0;
	if (this.tooltipTrigger.hasClass('itemBox')) {
		if (this.tooltipTrigger.find('label').length > 0) {
			var paddingRightTrigger = $(this.tooltipTrigger)
				.css('padding-right');
			var marginRigthChild = $(this.tooltipTrigger.children())
				.css('margin-right');
			offset = parseInt(marginRigthChild.substring(0, marginRigthChild.length - 2)) + parseInt(paddingRightTrigger.substring(0, paddingRightTrigger.length - 2));
		} else {
			offset = 5;
		}
	}
	return offset;
};

ErrorTooltip.prototype.removePluginFromElement = function (element) {
	element.removeClass(this.options.tooltipTriggerClass);
	element.removeData('plugin_' + pluginName);
};

$.fn[pluginName] = function (options) {
	return this.each(function () {
		var fullPluginName = 'plugin_' + pluginName;
		$(this)
			.removeData(fullPluginName);
		$.data(this, fullPluginName, new ErrorTooltip(this, options));
	});
};
