import { ChangeDetectionStrategy, Component, computed, effect, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../shared/store/application-state';
import { Logout } from '../../shared/store/logout.action';
import { UserQuery } from '../../shared/store/reducers/user.reducer';
import { NavigationQuery } from '../store/navigation.reducer';

@Component({
	selector: 'bas-nav-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	@HostBinding('class.logged-in') loggedIn = false;
	items = computed(() => this.store.selectSignal(NavigationQuery.items)()
		.filter(item => !item.parameters['showInAltNav']));
	showMainNav = this.store.selectSignal(NavigationQuery.showMainNav);

	preferencesTooltip = computed(() => {
		const user = this.store.selectSignal(UserQuery.getUser)();
		return user && `${ user.firstname } ${ user.surname } (${ user.userId })`;
	});

	private activeId = this.store.selectSignal(NavigationQuery.getActiveId);
	basketActive = computed(() => this.activeId() === 'basket');
	prefsActive = computed(() => this.activeId() === 'preferences');
	contactActive = computed(() => this.activeId() === 'contact');

	constructor(
		private router: Router,
		private store: Store<ApplicationState>,
	) {
		effect(() => {
			this.loggedIn = this.showMainNav();
		});
	}

	logout(route: string = '/logout.do') {
		this.store.dispatch(new Logout);
		this.router.navigateByUrl(route);
	}
}
