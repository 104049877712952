import { NgModule } from '@angular/core';
import { BouncerService } from './bouncer.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	imports: [
		HttpClientModule
	],
	providers: [
		BouncerService
	]
})
export class BouncerModule {}
