<footer id="footer" class="ym-clearfix">
	<div class="top">
		<a (click)="scrollToTop()">nach oben</a>
	</div>
	<nav class="footer ym-clearfix">
		<div class="right">
			<bas-footer-privacy-policy></bas-footer-privacy-policy>
			<bas-footer-history></bas-footer-history>
			<bas-footer-contact></bas-footer-contact>
			<bas-footer-help></bas-footer-help>
			<bas-footer-impressum></bas-footer-impressum>
			<bas-footer-terms-of-use></bas-footer-terms-of-use>
		</div>
		<div class="bottom">
			<bas-footer-serverinfo></bas-footer-serverinfo>
		</div>
	</nav>
</footer>
