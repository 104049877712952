import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorFallbackComponent } from './error-fallback.component';
import { FieldModule } from '../shared/generic-compounds/field/field.module';
import { LabelModule } from '../shared/elements/label/label.module';
import { LinkModule } from '../shared/elements/link/link.module';
import { GroupModule } from '../shared/generic-compounds/group/group.module';

@NgModule({
	declarations: [ErrorFallbackComponent],
	imports: [
		CommonModule,
		FieldModule,
		LinkModule,
		LabelModule,
		GroupModule
	]
})
export class ErrorFallbackModule { }
