import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldComponent, GroupComponent, LabelComponent } from '@bas/ui';
import { MsgKeyModule } from '../../translate/msg-key.module';

/**
 * @group Generic Compounds
 * @component Pflichtfeld Legende
 * @description
 * Markierungen sind rechts neben den Labels angebracht, um die linke Lesekante nicht zu brechen und nicht aus dem sichtbaren Bereich zu verschwinden.
 */
@Component({
	selector: 'bas-required-hint',
	standalone: true,
	imports: [CommonModule, LabelComponent, MsgKeyModule, FieldComponent, GroupComponent],
	templateUrl: './required-hint.component.html',
	styleUrls: ['./required-hint.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequiredHintComponent {}
