export function merge(mergeObj1, mergeObj2, targetObj = {}, deep = true) {
	if (mergeObj1 === undefined) {
		mergeObj1 = {};
	}
	if (mergeObj2 === undefined) {
		mergeObj2 = {};
	}
	return $.extend(deep, targetObj, mergeObj1, mergeObj2);
}

export function mergeConfiguration(configuration1, configuration2, to?) {
	return merge(configuration1, configuration2, to);
}

export function mergeCKEditorToolbars(defaultToolbars, customerToolbars) {
	let mergedToolbars = {};
	let allToolbars = mergeConfiguration(defaultToolbars, customerToolbars);
	$.each(allToolbars, function (toolbarName, toolbarConfig) {
		if (typeof customerToolbars[toolbarName] !== 'undefined') {
			mergedToolbars[toolbarName] = customerToolbars[toolbarName];
		} else {
			mergedToolbars[toolbarName] = toolbarConfig;
		}
	});
	return mergedToolbars;
}

export function mergeSelectorConfiguration(conf1, conf2, target?) {
	if (target === undefined) {
		target = conf1;
	}

	let startup = conf1.startup;
	startup = startup.concat(conf2.startup);

	delete conf1.startup;
	delete conf2.startup;

	$.extend(target, conf1, conf2);
	target.startup = startup;
	return target;
}
