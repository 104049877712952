function HtmlComposer() {

}

let generatedRadio = 0;

HtmlComposer.prototype = {

	capitaliseFirstLetter: function (string) {
		return string.charAt(0)
			.toUpperCase() + string.slice(1);
	},

	convertTags: function (string) {
		let htmlNamespaceReplacer = new RegExp('html:', 'g'),
			xmlnsReplacer = new RegExp('xmlns:html="[^"]*"', 'g');
		let convertedString = string.replace(htmlNamespaceReplacer, '');

		convertedString = convertedString.replace(xmlnsReplacer, '');
		return convertedString;
	},

	generateButtongroup: function (options) {
		let buttongroup = $(document.createElement('select'));
		for (let i = 0; i < options.length; i++) {
			buttongroup.append(this.generateOption(options[i]));
		}
		return buttongroup;
	},

	generateCKEditor: function (value, cssClass) {
		let CKEditorContainer = this.generateDiv('ckeditor'),
			ckeditor = this.generateElement('div', 'inlineckeditor', 'tmpCkEditor', [
				'contenteditable', 'true', 'data-inline-ckeditor', 'true',
			]);
		const convertedValue = this.convertTags(value);

		ckeditor.addClass(cssClass);
		ckeditor[0].innerHTML = convertedValue;

		CKEditorContainer.append(ckeditor);
		return CKEditorContainer;
	},

	generateCheckbox: function (attribute, readonlyFlag, attributes) {
		let input = this.generateElement('input');
		input.attr('type', 'checkbox')
			.val('false');
		if (attribute.value === 'true') {
			input.val('true');
			input.attr('checked', 'checked');
		}
		if (readonlyFlag === 'true') {
			input.prop('disabled', true);
		}
		if (attributes) {
			input = this.addAttributes(input, attributes);
		}
		return input;
	},

	generateCombobox: function (options, labels, selectedValue, attributes) {
		let combobox = this.generateElement('select');
		for (let i = 0; i < options.length; i++) {
			let optionValue = options[i];
			let optionLabel = labels !== undefined ? labels[i] : optionValue;
			let option = this.generateOption(optionLabel);
			option.prop('value', optionValue);
			if (optionValue === selectedValue) {
				option.prop('selected', 'selected');
			}
			combobox.append(option);
		}
		if (attributes) {
			combobox = this.addAttributes(combobox, attributes);
		}
		return combobox;
	},

	generateRadios: function (attributes) {
		const name = attributes.name + '-' + generatedRadio++;
		const generatedRadioContainer = this.generateDiv('radioContainer', name, undefined);
		for (let i = 0; i < attributes.allowedValues.length; i++) {
			const radioContainer = this.generateDiv('bas-inputCheck bas-inputRadio');

			const value = attributes.allowedValues[i];
			const selected = value == attributes.value;
			const radio = this.generateElement(
				'input', undefined, name + '-' + i,
				[
					['type', 'radio'],
					['name', name],
					['value', value],
					...(selected ? [['checked', '']] : []),
				],
			);
			radioContainer.append(radio);

			const label = this.generateElement(
				'label', undefined, undefined,
				[['for', name + '-' + i]],
			);
			label.append(attributes.allowedValueLabels[i]);
			radioContainer.append(label);

			generatedRadioContainer.append(radioContainer);
		}
		return generatedRadioContainer;
	},

	generateSeparator: function () {
		let separator = this.generateDiv('inputSeparator');
		return separator;
	},

	generateInputText: function (value, cssClass, readonlyFlag, attributes) {
		let input = this.generateElement('input', cssClass);
		input.attr('type', 'text')
			.val(value);
		if (readonlyFlag === 'true') {
			input.prop('disabled', true);
		}
		if (attributes) {
			input = this.addAttributes(input, attributes);
		}
		return input;
	},

	generateImage: function (src, id, cssClass, attributes) {
		let img = this.generateElement('img', cssClass, id);
		img.attr('src', src);
		if (src === '') {
			img.hide();
		}
		if (attributes) {
			img = this.addAttributes(img, attributes);
		}
		return img;
	},

	generateInputFile: function (id, cssClass, readonlyFlag, attributes) {
		let input = this.generateElement('input', cssClass, id);
		input.prop('type', 'file');
		if (readonlyFlag === 'true') {
			input.prop('disabled', true);
		}
		if (attributes) {
			input = this.addAttributes(input, attributes);
		}
		return input;
	},

	generateInputHidden: function (id, cssClass, attributes) {
		let input = this.generateElement('input', cssClass, id);
		input.prop('type', 'hidden');
		if (attributes) {
			input = this.addAttributes(input, attributes);
		}
		return input;
	},

	generateTextarea: function (value, cssClass, rows, readonlyFlag, attributes) {
		let textarea = this.generateElement('textarea', cssClass);
		textarea.attr('rows', rows);
		textarea.val(value);
		$(textarea)
			.text(value);
		if (attributes) {
			textarea = this.addAttributes(textarea, attributes);
		}
		return textarea;
	},

	generateLabel: function (text, attributes) {
		let label = $('<label>' + this.capitaliseFirstLetter(text) + '</label>');
		if (attributes) {
			label = this.addAttributes(label, attributes);
		}
		return label;
	},

	generateOption: function (optionsItem, attributes) {
		let option = this.generateElement('option');
		option.text(optionsItem);
		if (attributes) {
			option = this.addAttributes(option, attributes);
		}
		return option;
	},

	generateSubmitButton: function (text, id, highlight, withTabindex, cssClass) {
		let submitContainer = this.generateDiv('bas-inputButton'),
			submitButton = this.generateDiv('button text content', id);
		if (withTabindex) {
			submitButton.attr('tabindex', 0);
			submitButton.attr('trigger-button-on-enter', id);
		}
		submitContainer.append(submitButton);

		if (typeof cssClass !== 'undefined') {
			cssClass = cssClass.length === 0 ? '' : cssClass;
			submitButton.addClass(cssClass);
		}

		if (highlight === true) {
			submitContainer.addClass('highlight');
			submitButton.addClass('highlight');
		}

		submitButton.text(text);

		return submitContainer;
	},

	generateDiv: function (cssClass, id, attributes) {
		let div = $(document.createElement('div'));
		if (cssClass != undefined) {
			div.addClass(cssClass);
		}
		if (id != undefined) {
			div.attr('id', id);
		}
		if (attributes) {
			div = this.addAttributes(div, attributes);
		}
		return div;
	},

	generateText: function (text, attributes) {
		let textContainer = this.generateElement('span');
		textContainer.text(text);
		if (attributes) {
			textContainer = this.addAttributes(textContainer, attributes);
		}
		return textContainer;
	},

	generateErrors: function (errors) {
		let errorContainer = this.generateDiv('box error'),
			errorHeader = this.generateElement('header'),
			errorContent = this.generateDiv('errorContent');
		errorHeader.text('Fehlermeldung');
		errorContainer.append(errorHeader)
			.append(errorContent);
		if (Array.isArray(errors)) {
			for (let i = 0; i < errors.length; i++) {
				errorContent.text(errors[i]);
			}
		}
		return errorContainer;
	},

	generateElement: function (name, cssClass, id, attributes) {
		let element = $(document.createElement(name));
		if (cssClass != undefined) {
			element.addClass(cssClass);
		}
		if (id != undefined) {
			element.attr('id', id);
		}
		if (attributes) {
			element = this.addAttributes(element, attributes);
		}
		return element;
	},

	addAttributes: function (element, attributes) {
		for (let i = 0; i < attributes.length; i++) {
			if (jQuery.type(attributes[i]) === 'array') {
				$(element)
					.attr(attributes[i][0], attributes[i][1]);
			} else {
				$(element)
					.attr(attributes[0], attributes[1]);
			}
		}
		return element;
	},

	generateSVG: function (className) {
		let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.setAttribute('class', className);
		return svg;
	},

	generateSVGCircle: function (className, cx, cy, fill) {
		let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
		circle.setAttribute('class', className);
		circle.setAttribute('cx', cx);
		circle.setAttribute('cy', cy);
		circle.setAttribute('fill', fill);
		return circle;
	},

};

export default new HtmlComposer();
