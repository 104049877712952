import { NgModule } from '@angular/core';
import { HintComponent } from './hint.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		CommonModule,
		TooltipModule
	],
	declarations: [
		HintComponent
	],
	exports: [
		HintComponent
	]
})
export class HintModule {}
