import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationState } from '../../../../../shared/store/application-state';
import { NavigationQuery } from '../../../../store/navigation.reducer';

@Component({
	selector: 'bas-nav-basket',
	templateUrl: './basket.component.html',
	styleUrls: ['./basket.component.less'],
	animations: [
		trigger('openState', [
			state('open', style({
				transform: 'scaleY(1)',
			})),
			state('closed', style({
				transform: 'scaleY(0)',
				display: 'none',
			})),
			transition('open => closed', animate('500ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => open', animate('500ms cubic-bezier(0.19, 1, 0.22, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketComponent implements OnInit {

	open$: Subject<boolean> = new Subject();
	close$ = new Subject();

	private basket = this.store.selectSignal(NavigationQuery.getBasket);
	basketHasItems = computed(() => !!this.basket()?.size);
	basketTooltip = computed(() => this.basketHasItems() ? 'text.link.tobasket' : 'basket.title.inactive');
	basketDocTypes = computed(() => {
		const doctypes = this.basket()?.doctype;
		return doctypes && Object.entries(doctypes)
			.map(([nameKey, count]) => ({ nameKey, count }));
	});
	state: 'closed' | 'open' = 'closed';

	constructor(private store: Store<ApplicationState>, private cdr: ChangeDetectorRef) {
	}

	ngOnInit() {
		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(map(closed => toOpenState(!closed)));
		const open$ = this.open$.pipe(map(toOpenState));

		merge(close$, open$)
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
			)
			.subscribe(s => {
				this.state = <'closed' | 'open'>s;
				this.cdr.markForCheck();
			});
	}

	open() {
		this.open$.next(true);
	}

	close() {
		this.open$.next(false);
		this.close$.next(true);
	}
}
