import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from './models/breadcrumb';

@Injectable()
export class BreadcrumbService {

	constructor(@Inject(HttpClient) private httpClient: HttpClient) {
	}

	public getBreadcrumbs(): Observable<Breadcrumb[]> {
		return this.httpClient.get<Array<Breadcrumb>>('rest/breadcrumbs');
	}

	public addBreadcrumb(breadcrumb: Breadcrumb): Observable<Breadcrumb> {
		return this.httpClient.post<Breadcrumb>('rest/breadcrumbs', breadcrumb);
	}

	public setBreadcrumb(breadcrumb: Breadcrumb): Observable<void> {
		return this.httpClient.put<void>('rest/breadcrumbs', breadcrumb);
	}

	public removeLast(): Observable<object> {
		return this.httpClient.delete('rest/breadcrumbs/last');
	}

}
