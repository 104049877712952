import { Action } from '@ngrx/store';

export enum MobileActionTypes {
	isMobileAction = '[isMobile] change to true',
	isNotMobileAction = '[isMobile] change to false'
}

export class IsMobileAction implements Action {
	readonly type: string = MobileActionTypes.isMobileAction;
}

export class IsNotMobileAction implements Action {
	readonly type: string = MobileActionTypes.isNotMobileAction;
}

export type MobileActions =
	IsMobileAction
	| IsNotMobileAction;
