import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as sourcemappedStacktrace from 'sourcemapped-stacktrace';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class BASErrorHandler extends ErrorHandler {

	constructor(private injector: Injector) {
		super();
	}

	handleError(error) {
		super.handleError(error);
		const loggingService = this.injector.get(LoggingService);

		if (error.stack) {
			sourcemappedStacktrace.mapStackTrace(error.stack, stack => {
				error.stack = stack.join('\n');
				loggingService.logError(error);
			});
		} else {
			loggingService.logError(error);
		}
	}
}