import { Pipe, PipeTransform } from '@angular/core';
import { FeatureService } from './feature.service';
import { Observable } from 'rxjs';

@Pipe({
	name: 'feature'
})
export class FeaturePipe implements PipeTransform {

	constructor(private feature: FeatureService) {}

	transform(feature: string): Observable<boolean> {
		return this.feature.has(feature);
	}

}
