import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SystemParameterService } from './system-parameter.service';

@NgModule({
	imports: [
		HttpClientModule
	],
	providers: [
		SystemParameterService
	]
})
export class SystemParameterModule {}
