import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';
import { NodePermissionFilter } from '../permissions/node.permission.filter';
import { SystemParameter } from '../permissions/system-parameter/system-parameter';
import { SystemParameterService } from '../permissions/system-parameter/system-parameter.service';
import { UserService } from '../permissions/user/user.service';
import { Basket } from './models/basket';
import { NavigationItem } from './models/navigationItem';

@Injectable()
export class NavigationService {

	private nodeResolveRestUrl = `${ window.context }/rest/navigation/trees/`;

	constructor(
		private http: HttpClient,
		private nodePermissionFilter: NodePermissionFilter,
		private userService: UserService,
		private systemParameterService: SystemParameterService,
		private loggingService: LoggingService,
		private router: Router,
	) {
	}

	getBasket(): Observable<Basket> {
		return this.http.get<Basket>('rest/basket');
	}

	getNavigationItems(): Observable<Array<NavigationItem>> {

		return this.nodePermissionFilter.getNodes()
			.pipe(
				switchMap(navigationNodes => {
					const dynamicNodes = this.findDynamicNodes(navigationNodes);
					const attributeNodes = this.findAttributeNodes(navigationNodes);
					const combinedNodes = [...dynamicNodes, ...attributeNodes];

					const requests = combinedNodes.map(treeName => this.genObs(treeName, navigationNodes));

					const findAndReplace = replacements => (nodes: Array<NavigationItem>, node: NavigationItem) => {
						if (node.treeName) {
							const replacement = replacements.find(r => r.treeName === node.treeName);

							if (!replacement) {
								return nodes;
							}

							return [
								...nodes,
								...replacement.nodes
									.map(repNode => node.parameters['hideParent'] ? repNode.children : [repNode])
									.reduce((a, b) => [...a, ...b], [])
									.map(repNode => ({ ...repNode, parameters: { ...repNode.parameters } })),
							];

						} else if (node.dynamic) {
							const replacement = replacements.find(r => r.treeName === node.dynamic);

							if (!replacement) {
								return nodes;
							}
							return [...nodes, ...replacement.nodes];
						}

						if (!node.children) {
							return [...nodes, node];
						}

						return [
							...nodes, {
								...node,
								children: node.children.reduce(findAndReplace(replacements), []),
							},
						];
					};

					if (combinedNodes.length > 0) {
						return forkJoin([...requests])
							.pipe(
								map(response => response.map((nodes, i) => ({ treeName: combinedNodes[i], nodes }))),
								map(replacements => navigationNodes.reduce(findAndReplace(replacements), [])),
							);
					}
					return of(navigationNodes);

				}),
			);
	}

	findDynamicNodes(nodes: Array<NavigationItem> | null): Array<string> {
		if (!nodes) {
			return [];
		}

		const treeNamesOfNodes = nodes.filter(node => node.treeName)
			.map(node => node.treeName);
		const treeNamesOfChildren = nodes.map(node => this.findDynamicNodes(node.children))
			.reduce((arr, curr) => [...arr, ...curr], []);

		return [...treeNamesOfNodes, ...treeNamesOfChildren];
	}

	findAttributeNodes(nodes: Array<NavigationItem> | null): Array<string> {
		if (!nodes) {
			return [];
		}
		const dynamicNodes = nodes.filter(node => node.dynamic)
			.map(node => node.dynamic);
		const dynamicChildren = nodes.map(node => this.findAttributeNodes(node.children))
			.reduce((arr, curr) => [...arr, ...curr], []);

		return [...dynamicNodes, ...dynamicChildren];
	}

	genObs(name: string, navigationNodes: Array<NavigationItem>): Observable<NavigationItem[]> {
		if (name.indexOf('attribute.') > -1 || name.indexOf('system-parameter.') > -1) {
			return this.generateNavigationItem(name, navigationNodes);
		} else {
			return this.http.get<Array<NavigationItem>>(this.nodeResolveRestUrl + name)
				.pipe(
					catchError(error => {
						try {
							this.loggingService.logError(error, '');
						} catch (e) {
							// es steht keine Verbindung zum Server -> wir machen nichts
						}
						this.router.navigateByUrl('/strutsreturn.do?forwardTo=error-default-message');
						return of([]);
					}),
				);
		}
	}

	generateNavigationItem(name: string, navigationNodes: Array<NavigationItem>): Observable<NavigationItem[]> {
		const items: NavigationItem[] = this.getNavigationItemForDynamic(name, navigationNodes);
		return this.replacePlaceholders(items[0]);
	}

	getNavigationItemForDynamic(name: string, navigationNodes: Array<NavigationItem>): NavigationItem[] {
		const dynamicNode: NavigationItem[] = navigationNodes.filter(node => node.dynamic === name);
		const dynamicChildNode: NavigationItem[] = navigationNodes.filter(node => node.children)
			.map(node => this.getNavigationItemForDynamic(name, node.children))
			.reduce((arr, curr) => [...arr, ...curr], []);

		return [...dynamicNode, ...dynamicChildNode];
	}

	replacePlaceholders(navigationItem: NavigationItem): Observable<NavigationItem[]> {

		const replacePh = (obj, value) => typeof obj === 'string' ? obj.replace('$ph', value) : obj;
		const toNavigationItem = (item: {}, [key, value]) => ({ ...item, [key]: value });

		const replaceAttributeOnNavigationItem = (item: NavigationItem) =>
			(attribute: string) => Object.keys({ ...item })
				.map(key => [key, replacePh(item[key], attribute)])
				.reduce(toNavigationItem, {}) as NavigationItem;
		if (navigationItem.dynamic.startsWith('attribute.')) {
			const dynamicAttribute = navigationItem.dynamic.substring('attribute.'.length);
			return this.userService.attribute(dynamicAttribute)
				.pipe(
					map((values = []) => values.map(replaceAttributeOnNavigationItem(navigationItem))),
				);
		} else if (navigationItem.dynamic.startsWith('system-parameter.')) {
			const dynamicAttribute = navigationItem.dynamic.substring('system-parameter.'.length);
			return this.systemParameterService.get(dynamicAttribute)
				.pipe(
					catchError(_ => of<Array<SystemParameter>>([])),
					map(list => list.map(sys => sys.value)),
					map((values) => values.map(replaceAttributeOnNavigationItem(navigationItem))),
				);
		}
	}

	getFavoriten(): Observable<NavigationItem[]> {
		return this.http.get<NavigationItem[]>('rest/navigation/favoriten');
	}
}
