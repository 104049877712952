import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RefreshUserAction, RemoveUserAction } from '../../shared/store/actions/user.actions';
import { ApplicationState } from '../../shared/store/application-state';
import { NavigationService } from '../navigation.service';
import {
	AddMainNavigationItemsAction, NavigationActionTypes, RefreshBasketAction, RefreshNavigationAction, RemoveBasketAction, SetBasketAction,
	SetMainNavigationAction,
} from './navigation.actions';
import { NavigationQuery } from './navigation.reducer';

@Injectable()
export class NavigationEffects {

	removeAjax$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NavigationActionTypes.RemoveAjaxRotator),
			tap(() => window.$(document).trigger('ajaxPageLoaded')),
			filter(() => false)
		),
		{dispatch: false}
	);

	addAjax$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NavigationActionTypes.AddAjaxRotator),
			tap(() => window.$(document).trigger('ajaxPageLoadStart')),
			filter(() => false)
		),
		{dispatch: false}
	);

	refresh$ = createEffect(() =>
		this.actions$.pipe(
				ofType(NavigationActionTypes.RefreshNavigation),
				switchMap(() => this.navigationService.getNavigationItems()),
				tap(() => $(document).trigger('reinitTextHelper')),
				switchMap(items => [new RefreshUserAction(), new AddMainNavigationItemsAction(items)])
			)
	);

	showMainNavigation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NavigationActionTypes.ShowMainNavigation),
			withLatestFrom(this.store.select(NavigationQuery.showMainNav)),
			map(([action, show]) => show),
			tap(() => this.store.dispatch(new RefreshBasketAction())),
			filter((show => !show)),
			map(() => new SetMainNavigationAction(true)),
			tap(() => this.store.dispatch(new RefreshUserAction())),
			tap(() => this.store.dispatch(new RefreshNavigationAction()))
		)
	);

	hideMainNavigation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NavigationActionTypes.HideMainNavigation),
			withLatestFrom(this.store.select(NavigationQuery.showMainNav)),
			map(([action, show]) => show),
			filter(show => show),
			map(() => new SetMainNavigationAction(false)),
			tap(() => this.store.dispatch(new RemoveUserAction())),
			tap(() => document.querySelector('body').classList.remove('changedBackground')),
			tap(() => this.store.dispatch(new RemoveBasketAction())))
	);

	refreshBasket$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NavigationActionTypes.RefreshBasketAction),
			switchMap(() => this.navigationService.getBasket()),
			map(basket => new SetBasketAction(basket)))
	);

	constructor(
		private navigationService: NavigationService,
		private actions$: Actions,
		private store: Store<ApplicationState>
	) { }

}
