<li (mouseenter)="open()"
	(mouseleave)="close()"
	#menu>
	<a *ngIf="item.externalLink; else default"
	   #anchor
	   class="nav-item"
	   [class.has-children]="hasChildren()"
	   bas-anchor-status
	   [parameters]="item.parameters"
	   href="{{ item.url$ | async}}"
	   target="_blank"
	   (click)="active()"
	   (focus)="focus()"
	   (blur)="blur()">
		<span>{{ item.name || (item.nameKey | msgKey) }}</span>
	</a>
	<ng-template #default>
		<a #anchor
		   class="nav-item"
		   [class.has-children]="hasChildren()"
		   bas-anchor-status
		   [parameters]="item.parameters"
		   [routerLink]="item.url"
		   [queryParams]="item.query"
		   (click)="active()"
		   (focus)="focus()"
		   (blur)="blur()">
			<span>{{ item.name || (item.nameKey | msgKey) }}</span>
		</a>
	</ng-template>
	<ul *ngIf="hasChildren()"
		[@openState]="state"
		class="mat-elevation-z9"
		[ngClass]="{ openToLeft: openToLeft }">
		<bas-nav-sub-item-level-1 *ngFor="let subItem of item.children"
								  [item]="subItem"
								  (itemClick)="onClick()"></bas-nav-sub-item-level-1>
	</ul>
	<div *ngIf="hasChildren()"
		 class="subNavigationMarker"></div>
</li>