<bas-group>
	<div class="container">
		<span [innerHtml]="message"></span>
	</div>
</bas-group>
<bas-group>
	<div class="linkContainer">
		<a bas-link target="" href="/jump-to-homepage-prepare.do">
			<span>{{ backToHomepageLink }}</span>
		</a>
	</div>
</bas-group>