import scrollHelper from 'helper/scrollHelper';
import 'plugins/tooltip/jquery.errorTooltip';

interface ErrorElement {
	id?,
	trigger
	errors: Array<string>
}

export class ErrorHandler {
	scrollHelper = scrollHelper;
	errorSelector = '[data-error-for]';
	triggerClass = 'errorTooltipTrigger';
	tooltipDataAttr = 'data-tooltip';
	datepickerIconWidth = 25;

	markerClass: string = undefined;
	errorTypeMarkerClass: string = undefined;
	markerSign: string = undefined;
	errorFields: Array<ErrorElement> = undefined;

	init() {
		this.initCss();
		this.determineMarkerClass();
		this.errorFields = this.markErrorFields(this.errorSelector);
		this.initTooltips();
	}

	initCss() {
		const $head = $('head');
		if ($head.find('#hideErrors').length == 0) {
			$head.append(
				'<style id="hideErrors" type="text/css">' +
				'.hideError *,#editMask .hideError * {padding: 0; display: none !important;}' +
				'.hideError.box.error,#editMask .hideError.box.error,.hideError .box.error,#editMask .hideError .box.error {display: none !important;}' +
				'</style>',
			);
		}
	}

	determineMarkerClass(useWarning?: boolean) {
		if ($('#firstCall')
			.val() == 'true' || useWarning) {
			this.markerClass = 'hasWarnings';
			this.errorTypeMarkerClass = 'tt_hasWarnings';
			this.markerSign = '?';
		} else {
			this.markerClass = 'hasErrors';
			this.errorTypeMarkerClass = 'tt_hasErrors';
			this.markerSign = '!';
		}
	}

	markErrorFields(errorSelector): Array<ErrorElement> {
		let errors = $(errorSelector);
		const errorFields = [];
		for (let i = 0; i < errors.length; i++) {
			let error = $(errors[i]);
			const id = error.data('error-for');
			if (id == 'errorOnTop') {
				error.parent()
					.parent()
					.parent()
					.removeClass('hideError');
			} else {
				let inputElement = this.determineInputElement(id);
				if (inputElement.length != 0 && !inputElement.hasClass('errorOnTop') && (inputElement.attr('type') != 'hidden' || inputElement.parent()
					.children()
					.first()
					.hasClass('inlineckeditor'))) {
					let trigger = this.determineTrigger(inputElement);
					const triggerElement = trigger.element;
					if (trigger.clazz != undefined) {
						triggerElement.addClass(trigger.clazz);
					}
					this.addData(errorFields, id, triggerElement, error.text());
				} else {
					error.closest('.hideError')
						.removeClass('hideError');
				}
			}
		}
		return errorFields;
	}

	removeError(inputElement: JQuery<HTMLInputElement>) {
		let trigger = this.determineTrigger(inputElement),
			triggerElement = trigger.element;
		if (triggerElement.hasClass('hasErrors')) {
			triggerElement.removeClass('hasErrors');
		} else {
			triggerElement.find('.hasErrors')
				.first()
				.removeClass('hasErrors');
		}

		let errorTooltip = triggerElement.data('plugin_ErrorTooltip');
		if (errorTooltip) {
			errorTooltip.removeTooltip();
		}
	}

	addData(myArr, id, triggerElement, error) {
		for (let i = 0; i < myArr.length; i++) {
			let element = myArr[i];
			if (id == element.id) {
				element.errors.push(error);
				return;
			}
		}
		myArr.push({
			id: id,
			trigger: triggerElement,
			errors: [error],
		});
	}

	/**
	 *
	 * @param {*} id
	 * @returns {JQuery<HTMLInputElement>}
	 */
	determineInputElement(id) {
		let element: HTMLInputElement = document.querySelector('[id="' + id + '"]');
		if (element?.tagName === 'bwc-date-picker'.toUpperCase()) {
			element = element.querySelector('input');
		}
		if (element == null) {
			element = document.querySelector('[id^="' + id + 'N"]');
		}
		if (element == null || element.type == 'hidden') {
			element = document.querySelector('[id^="' + id + '_"]');
		}
		if (element == null) {
			element = document.querySelector('[data-id-for-rectangle="' + id + '"]');
		}
		if (element == null) {
			/**
			 * @type {any}
			 */
			let elements = $(document.getElementsByName(id));
			if (elements.length > 1) {
				// @ts-ignore
				return elements.last();
			} else {
				return elements;
			}
		}
		return $(element);
	}

	/**
	 *
	 * @param {JQuery<HTMLInputElement>} inputElement
	 */
	determineTrigger(inputElement) {
		let clazz = this.markerClass;
		let parent;
		let errorElement;
		if (inputElement.attr('errorpos')) {
			const parentCounter = +inputElement.attr('errorpos');
			parent = inputElement;
			for (let i = 0; i < parentCounter; i++) {
				parent = parent.parent();
			}
		} else if (inputElement.parent()
			.parent()
			.hasClass('selectionGroup') || inputElement.attr('svg') === 'true' || inputElement.hasClass('selectionInput')) {
			if (inputElement.length > 1 && inputElement.parents('.selectionGroup').length > 0) {
				errorElement = inputElement.parents('.selectionGroup');
			} else {
				errorElement = $(inputElement.find('~label svg')[0]);
			}
			this.attachWrapperForTriggerBoxSvg(errorElement);
			if (errorElement.length == undefined) { // Sonderfall fuer Audi -> Newsletter -> Beitraege (Layoutauswahl)
				errorElement = $(inputElement.closest('.selectionGroup '));
			}
			parent = errorElement.parent();
			clazz += ' triggerBox';
		} else if (inputElement.attr('type') == 'file' && inputElement.parent()
			.find('.fakeFileUpload').length !== 0) { // "toller" Sonderfall für Audi - juhu...
			// setzt Trigger, unterdrückt die Markierung
			parent = inputElement.parent();
			clazz = null;
			// setzt Markierung auf den Fake
			inputElement = inputElement.parent()
				.find('.fakeFileUpload')
				.find('input');
			if (!inputElement.parent()
				.hasClass('WrapperForFileInput')) {
				this.attachWrapperForFileInput(inputElement);
			}
			inputElement.parent()
				.addClass('hasErrors');
		} else if (inputElement.attr('type') == 'file' && inputElement.hasClass('hide')) {
			errorElement = inputElement.closest('.selection');
			this.attachWrapperForTriggerBoxSvg(errorElement);
			parent = errorElement.parent();
			clazz += ' triggerBox';
		} else if (inputElement.attr('type') == 'file' || (inputElement.attr('type') == 'text' && inputElement.next()
			.hasClass('hint'))) {
			if (!inputElement.parent()
				.hasClass('WrapperForFileInput')) {
				this.attachWrapperForFileInput(inputElement);
			}
			parent = $(inputElement.parent());
		} else if ((inputElement.is('input') && inputElement.hasClass('datepicker')) || (inputElement.is('input') && inputElement.hasClass('clockpicker'))) {
			this.attachWrapperForDatepicker(inputElement);
			parent = $(inputElement.parent());
		} else if ((inputElement.is('textarea') && inputElement.attr('cols') != undefined) || (inputElement.attr('type') == 'text' && inputElement.attr('class')
			.includes('cols')) || (inputElement.attr('type') == 'text' && inputElement.attr('size') != undefined)) {
			parent = inputElement.parent();
			if ((!inputElement.hasClass('hasUrlChecker') && inputElement[0].style.width != '') || inputElement.attr('size') != undefined || inputElement.attr('cols') != undefined) {
				if (inputElement.hasClass('alignRight')) {
					parent.css('display', 'block');
				} else {
					parent.css('display', 'inline-block');
				}
			}
		} else if ((inputElement.is('input') && inputElement.attr('type') == 'radio') || (inputElement.is('div')) && !inputElement.hasClass('inlineckeditor')) {
			inputElement = inputElement.last();
			parent = $(inputElement.closest('.item'));
			if (parent.length == 1) {
				let parentBox = parent.parent();
				if (parentBox.hasClass('itemBox')) {
					parent = parentBox.closest('.item');
				}
			}
			if (parent.length == 0) {
				parent = inputElement.closest('.tabulation');
			}
			if (parent.length == 0) {
				this.attachWrapperForRadioGroup(inputElement);
				parent = $(inputElement.closest('.item'));
			}
			clazz += ' div';
		} else if (inputElement.is('input') && inputElement.attr('type') == 'checkbox') {
			parent = $(inputElement.closest('.itemBox'));
			if (parent.length == 0) {
				parent = $(inputElement.parent());
				clazz += ' triggerBox';
			}
			clazz += ' div';
		} else if (inputElement.is('select')) {
			parent = $(inputElement.next('.chosen-container'));
			if ($(parent).length == 0) {
				this.attachWrapperForChosen(inputElement);
				parent = inputElement.parent();
			}
			clazz += ' div';
		} else if (inputElement.attr('id') == 'errorOnTop' || inputElement.attr('errorpos') == 'errorOnTop') {
			parent = inputElement;
		} else if (inputElement.attr('type') == 'hidden' || inputElement.hasClass('inlineckeditor')) {
			parent = inputElement.parent()
				.children()
				.first();
			clazz += ' div';
			if (inputElement.hasClass('inlineckeditor')) {
				clazz += ' ckeditorMarker';
			}
		} else if (inputElement.is('textarea') && inputElement.hasClass('hasSmileypicker')) {
			let tmpContainer = $('<div/>');
			inputElement.wrap(tmpContainer);
			inputElement.parent()
				.parent()
				.append(tmpContainer);
			parent = $(inputElement.parent());
		} else {
			parent = $(inputElement.parent());
		}
		return {
			element: parent,
			clazz: parent.hasClass(this.markerClass) ? undefined : clazz,
		};
	}

	initTooltips() {
		for (let i = 0; i < this.errorFields.length; i++) {
			this.initTooltip(this.errorFields[i]);
		}
	}

	initTooltip(data: ErrorElement) {
		const trigger = $(data.trigger);
		const errors = data.errors;
		if (errors.length > 1) {
			errors.unshift('Es liegen mehrere Fehler vor:');
		}
		let errorHtml = this.createError(errors);
		trigger.attr(this.tooltipDataAttr, errorHtml);
		trigger.addClass(this.triggerClass);
		return trigger.ErrorTooltip();
	}

	createError(errors) {
		let tooltip = '<div class=\'tooltip errorTooltip ' + this.errorTypeMarkerClass + '\'>';
		tooltip += '<div class=\'iconContainer\'>' + this.markerSign + '</div>';
		tooltip += '<div class=\'errorcontainer\'>';
		for (let i = 0; i < errors.length; i++) {
			if (errors[i] === 'Es liegen mehrere Fehler vor:') {
				tooltip += '<div><b>' + errors[i] + '</b></div>';
			} else {
				tooltip += '<div>' + errors[i] + '</div>';
			}
		}
		tooltip += '</div></div>';
		return tooltip;
	}

	/**
	 *
	 * @param {*} id
	 * @returns {JQuery<HTMLInputElement>}
	 */
	getById(id) {
		/**
		 * @type {JQuery<HTMLInputElement>}
		 */
		let checkbox = $('[id^="' + id + 'N"]');
		if (checkbox[0] !== undefined) {
			return checkbox;
		}
		/**
		 * @type {JQuery<HTMLInputElement>}
		 */
		let radioOrCombobox = $('[id^="' + id + '_"]');
		if (radioOrCombobox[0] !== undefined) {
			return radioOrCombobox;
		}
		/**
		 * @type {JQuery<HTMLInputElement>}
		 */
		let inlineckeditor = $('[data-id-for-rectangle="' + id + '"]');
		if (inlineckeditor[0] !== undefined) {
			return inlineckeditor;
		}
		return $('[id="' + id + '"]');
	}

	attachWrapperForDatepicker(inputElement) {
		let wrappedElements = $(inputElement[0])
				.parent()
				.children(),
			tmpContainer = $('<div/>');
		let base = window.document.getElementsByTagName('base');
		if (base && base[0] && base[0]['href'].includes('audi')) {
			wrappedElements[0].childNodes.length == 2 ? this.datepickerIconWidth = 2 : this.datepickerIconWidth = 37;
		}
		tmpContainer.width(wrappedElements.actual('outerWidth') + this.datepickerIconWidth);
		tmpContainer.addClass('datepickerError input');
		wrappedElements.wrapAll(tmpContainer);
	}

	attachWrapperForChosen(inputElement) {
		let tmpContainer = $('<div/>');
		tmpContainer.addClass('chosen-container');
		inputElement.wrap(tmpContainer);
		inputElement.parent()
			.parent()
			.append(tmpContainer);
	}

	attachWrapperForFileInput(inputElement) {
		let wrappedElement = $(inputElement[0]),
			tmpContainer = $('<div/>');
		tmpContainer.addClass('WrapperForFileInput');
		wrappedElement.wrap(tmpContainer);
	}

	attachWrapperForRadioGroup(inputElement) {
		let tmpElement = new Array();
		let tmpContainer = $('<div/>');
		tmpContainer.addClass('item');
		tmpContainer.addClass('vertical');
		inputElement.each(function (i) {
			tmpElement.push(inputElement[i].parentElement);
		});
		$(tmpElement)
			.wrapAll(tmpContainer);
	}

	attachWrapperForTriggerBoxSvg(svg) {
		let tmpElement = new Array();
		let tmpContainer = $('<div class="triggerBoxSvg triggerBox"/>');
		tmpElement.push(svg[0]);
		let span = svg.next('span');
		if (span.size() > 0) {
			tmpElement.push(span[0]);
		}
		$(tmpElement)
			.wrapAll(tmpContainer);
	}
}

export default new ErrorHandler();
