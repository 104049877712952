import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IsMobileAction } from '../../shared/store/actions/mobile.actions';
import { ApplicationState } from '../../shared/store/application-state';
import { Logout } from '../../shared/store/logout.action';
import { Router } from '@angular/router';

@Component({
	selector: 'bas-mobile-nav',
	templateUrl: './mobile-nav.component.html',
	styleUrls: ['./mobile-nav.component.less']
})
export class MobileNavComponent {

	constructor(protected store: Store<ApplicationState>, private router: Router) { }

	logout() {
		this.store.dispatch(new Logout);
		this.store.dispatch(new IsMobileAction);
		this.router.navigateByUrl('/logout.do');
	}
}
