<bas-breadcrumb></bas-breadcrumb>
<ng-container *ngIf="overrideAdv; else newAdv">
	<h1 bas-headline>{{ 'adv.generate.headline.override' | msgKeyCached }}</h1>
</ng-container>
<ng-template #newAdv>
	<h1 bas-headline>{{ 'adv.generate.headline.new' | msgKeyCached }}</h1>
</ng-template>
<form [formGroup]="advForm"
      buiValidationContext="adv.generate">
	<bui-group>
		<bui-field>
			<bui-label required>{{ 'adv.generate.legalName.label' | msgKeyCached }}</bui-label>
			<bui-input formControlName="legalName"
			           class="col-6/12 adv-input"></bui-input>
		</bui-field>
		<bui-field>
			<bui-label required>{{ 'adv.generate.streetAndNumber.label' | msgKeyCached }}</bui-label>
			<bui-input formControlName="streetAndNumber"
			           class="col-6/12"></bui-input>
		</bui-field>
		<bui-field>
			<bui-label required>{{ 'adv.generate.zipAndCity.label' | msgKeyCached }}</bui-label>
			<bui-input formControlName="zip"
			           class="col-1/12"></bui-input>
			<bui-input formControlName="city"
			           class="col-5/12"></bui-input>
		</bui-field>
		<bui-field>
			<bui-label required>{{ 'adv.generate.representedBy.label' | msgKeyCached }}</bui-label>
			<bui-input formControlName="representedBy"
			           class="col-6/12"></bui-input>
		</bui-field>

		<ng-container *ngIf="overrideAdv">
			<bui-field>
				<bui-label required>{{ 'adv.generate.comment.label' | msgKeyCached }}</bui-label>
				<bui-textarea formControlName="comment"
				              class="col-6/12"></bui-textarea>
			</bui-field>
			<bui-field>
				<bui-checkbox formControlName="confirmOverrideAdv" class="col-8/12">
					{{ 'adv.generate.confirmOverrideAdv.label' | msgKey }}
				</bui-checkbox>
			</bui-field>
		</ng-container>
	</bui-group>
</form>

<bas-required-hint></bas-required-hint>

<bui-button-line>
	<button bui-button
	        (throttledClick)="back()">{{ 'button.cancel.alt' | msgKey }}
	</button>
	<button bui-button
	        color="primary"
	        (throttledClick)="save()">{{ (overrideAdv ? 'button.update.alt' : 'button.generate') | msgKey }}
	</button>
</bui-button-line>

