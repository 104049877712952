import { debounceTime, distinctUntilChanged, fromEvent, map, merge, Subject, takeUntil, tap } from 'rxjs';
import 'plugins/jquery.actual';

function TooltipBase() {
	// nothing to do
	/**
	 * @type {JQuery}
	 */
	this.tooltipTrigger = undefined;
}

TooltipBase.prototype.init = function () {
	this.destroy$ = new Subject();
	if (typeof this.tooltipTrigger.data('tooltip') === 'undefined') {
		this.detachTooltipFromDom();
	}
	this.addEventListener();
};

TooltipBase.prototype.detachTooltipFromDom = function () {
	let tooltip = this.tooltipTrigger.next()
		.addClass('informationBlock');
	this.tooltipTrigger.attr('data-tooltip', tooltip[0].outerHTML);
	this.tooltipTrigger.data('tooltip', tooltip.detach());
};

TooltipBase.prototype.addEventListener = function () {
	if (typeof this.tooltipTrigger.data('tooltip') !== 'undefined') {
		if (typeof this.tooltipTrigger.data('tooltip') === 'string') {
			this.tooltipTrigger.data('tooltip', $(this.tooltipTrigger.data('tooltip'))
				.addClass('informationBlock'));
			this.tooltipTrigger.data('tooltip', $(this.tooltipTrigger.data('tooltip'))
				.css('z-index', '9001')); // it's over 9000!!!!
		}
		const isAllianz = window.document.getElementsByTagName('base')[0]['href'].includes('allianz');
		const isErrorTooltip = this.options.tooltipTriggerClass.startsWith('errorTooltip');
		const isEditpageTooltip = this.tooltipTrigger.hasClass('editPageTooltip');
		if (isAllianz && !isErrorTooltip && !isEditpageTooltip) { // ich kann dafuer nix, die wollen das so -.-
			const showTooltipObservable = this.createObservable(this.tooltipTrigger[0], 'click', true);
			this.tooltipTrigger.on('keydown', function (e) { //verhalten für Tabben
				if (e.key == 'Enter') {
					this.showTooltip();
					$(document)
						.on('keydown', function (e) {
							if (e.key == 'Escape' || e.key == 'Tab') {
								this.hideTooltip();
								$(document)
									.off('keydown');
								$(document)
									.off('click');
							}
						}.bind(this));
					$(document)
						.on('click', function hideTooltipAllianz(event) {
							if ($(event.target)
								.closest('.tooltipTrigger').length === 0 && $(event.target)
								.closest('.tooltip').length === 0) {
								this.hideTooltip();
								$(document)
									.off('click');
								$(document)
									.off('keydown');
							}
						}.bind(this));
				}
			}.bind(this));
			showTooltipObservable.pipe(
				debounceTime(this.options.debounceTime))
				.subscribe(function (event) {
					this.showTooltip();
					$(document)
						.on('keydown', function (e) {
							if (e.key == 'Escape') {
								this.hideTooltip();
								$(document)
									.off('keydown');
								$(document)
									.off('click');
							}
						}.bind(this));
					$(document)
						.on('click', function hideTooltipAllianz(event) {
							if ($(event.target)
								.closest('.tooltipTrigger').length === 0 && $(event.target)
								.closest('.tooltip').length === 0) {
								this.hideTooltip();
								$(document)
									.off('click');
								$(document)
									.off('keydown');
							}
						}.bind(this));
				}.bind(this));
		} else {
			let showTooltipObservable = this.createObservable(this.tooltipTrigger[0], 'mouseenter', true);
			let hideTooltipObservable = this.createObservable(this.tooltipTrigger[0], 'mouseleave', false);
			let tooltipMouseEnterObservable = this.createObservable(this.tooltipTrigger.data('tooltip')[0], 'mouseenter', true);
			let tooltipMouseLeaveObservable = this.createObservable(this.tooltipTrigger.data('tooltip')[0], 'mouseleave', false);
			let tooltipObservable = merge(showTooltipObservable, hideTooltipObservable, tooltipMouseEnterObservable, tooltipMouseLeaveObservable);
			tooltipObservable.pipe(
				debounceTime(this.options.debounceTime),
				distinctUntilChanged(),
			)
				.subscribe(function (show) {
					if (show) {
						this.showTooltip();
					} else {
						this.hideTooltip();
					}
				}.bind(this));
		}

		$(document)
			.on('ajaxPageLoadStart', $.proxy(this.hideTooltip, this));
	}
};

TooltipBase.prototype.createObservable = function (element, event, returnValue) {
	return fromEvent(element, event)
		.pipe(
			tap(function (x) {
				this.clientX = x.clientX;
			}.bind(this)),
			takeUntil(this.destroy$),
			map(() => returnValue),
		);
};

TooltipBase.prototype.showTooltip = function () {
	if (this.options.showAnyways || this.tooltipTrigger.hasClass(this.options.tooltipTriggerClass)) {
		this.tooltipTrigger.data('tooltipShown', true);
		if (typeof this.tooltipTrigger.data('tooltip') === 'string') {
			this.tooltipTrigger.data('tooltip', $(this.tooltipTrigger.data('tooltip'))
				.addClass('informationBlock'));
		}
		/**
		 * @type {JQuery}
		 */
		let tooltip = this.tooltipTrigger.data('tooltip');
		if (!tooltip) {
			return;
		}
		this._unescapeTooltip(tooltip[0]);
		tooltip.appendTo($('body'));
		tooltip.find('a')
			.on('click', function (event) {
				let href = this.getAttribute('href');
				let target = this.getAttribute('target');
				if (target) {
					window.open(href, target);
					event.preventDefault();
					event.stopPropagation();
					return false;
				} else {
					$(document)
						.trigger('navigateTo', href);
					event.preventDefault();
					return false;
				}
			});
		this.displayTooltipInDom();
	}
};

/**
 * @param {Node} tooltip
 */
TooltipBase.prototype._unescapeTooltip = function (tooltip) {
	let that = this;
	if (tooltip.childNodes.length) {
		for (let i = 0; i < tooltip.childNodes.length; i++) {
			that._unescapeTooltip(tooltip.childNodes[i]);
		}
	} else {
		const body = tooltip.textContent;
		try {
			if (body != null) {
				tooltip.textContent = decodeURIComponent(body);
			}
		} catch (e) {
			// catch malformed URI, do nothing
		}
	}
};

TooltipBase.prototype.hideTooltip = function () {
	if (this.tooltipTrigger.data('tooltipShown') && this.tooltipTrigger.data('tooltip')) {
		this.tooltipTrigger.data('tooltip')
			.remove();
		this.tooltipTrigger.data('tooltipShown', false);
	}
};

TooltipBase.prototype.removeTooltip = function () {
	if (this.tooltipTrigger.data('tooltip')) {
		this.tooltipTrigger.data('tooltip')
			.remove();
		this.tooltipTrigger.removeData('tooltipShown');
		this.tooltipTrigger.removeData('tooltip');
		this.tooltipTrigger.removeAttr('data-tooltip');
		this.destroy$.next();
		this.destroy$.complete();
	}
};

TooltipBase.prototype.getDimensionOfTooltipTrigger = function () {
	let elementToUse = this.tooltipTrigger;

	if (this.tooltipTrigger.hasClass('inlineckeditor') && this.tooltipTrigger.prev('.CKEditorAnimatedPlaceholder.topPlaceholder').length) {
		elementToUse = this.tooltipTrigger.prev('.CKEditorAnimatedPlaceholder.topPlaceholder');
	} else if (this.tooltipTrigger.hasClass('item') || this.tooltipTrigger.hasClass('chosen-container') || this.tooltipTrigger.hasClass('triggerBox')) {
		elementToUse = this.tooltipTrigger;
	} else if (this.tooltipTrigger.hasClass('ckeditorMarker')) {
		elementToUse = this.tooltipTrigger.parent();
	} else if (elementToUse.children()
		.first()
		.hasClass('hasSmileypicker')) {
		elementToUse = this.tooltipTrigger.closest('.errorTooltipTrigger');
	} else if (this.tooltipTrigger.find(':first-child').length > 0) {
		if (this.tooltipTrigger.is('bas-targeting-options')) {
			elementToUse = $(elementToUse.context);
		} else {
			elementToUse = this.tooltipTrigger.find(':first-child')
				.first();
		}
	}
	elementToUse = elementToUse.hasClass('ckeditor') && elementToUse.next('.cke').length > 0 ? elementToUse.next('.cke') : elementToUse;
	return {
		top: elementToUse.offset().top,
		left: elementToUse.offset().left,
		width: $.isNumeric(elementToUse.actual('outerWidth')) ? elementToUse.actual('outerWidth') : elementToUse.actual('width'),
		height: $.isNumeric(elementToUse.actual('outerHeight')) ? elementToUse.actual('outerHeight') : elementToUse.actual('height'),
	};
};

TooltipBase.prototype.resetPositionOfTooltip = function (tooltip) {
	tooltip.css({
		top: 0,
		left: 0,
	});
};

TooltipBase.prototype.displayTooltipInDom = function () {
	let dimensionOfTooltipTrigger = this.getDimensionOfTooltipTrigger();
	let tooltip = this.tooltipTrigger.data('tooltip');
	this.showAtMouseX = this.tooltipTrigger.data('tooltip-showatmousex');
	let tooltipContainerClass;
	let wantedTooltipContainerClass;
	let wantedCss;
	if (this.options.noseHeight == 0) {
		tooltip.addClass('noNose');
	}

	for (let i = 0; i <= this.options.cssClasses.length; i++) {
		this.resetPositionOfTooltip(tooltip);
		if (i === this.options.cssClasses.length) {
			tooltipContainerClass = 'bottomleft';
		} else {
			tooltipContainerClass = this.options.cssClasses[i];
		}
		let cssBefore = this.getCssForPosition(tooltipContainerClass, dimensionOfTooltipTrigger);
		tooltip.css(cssBefore);
		if (cssBefore.left >= 0) {
			wantedCss = cssBefore;
			wantedTooltipContainerClass = tooltipContainerClass;
		}
		let cssAfter = this.getCssForPosition(tooltipContainerClass, dimensionOfTooltipTrigger);

		let topPositionIsEqual = true;
		if (tooltipContainerClass === 'topleft' || tooltipContainerClass === 'topright') {
			topPositionIsEqual = cssBefore.top === cssAfter.top;
		}
		if (this.isInViewport(tooltip) && topPositionIsEqual) {
			break;
		}
	}
	tooltip.css(wantedCss);
	tooltip.removeClass(this.options.cssClasses.join(' '))
		.addClass(wantedTooltipContainerClass)
		.show();
};

TooltipBase.prototype.isInViewport = function (tooltip) {
	let viewport = {
		top: $(window)
			.scrollTop(),
		bottom: $(window)
			.scrollTop() + $(window)
			.height(),
		width: $(window)
			.width(),
	};
	tooltip.show();
	let bounds = {
		top: tooltip.offset().top,
		bottom: tooltip.offset().top + tooltip.actual('outerHeight'),
		left: tooltip.offset().left - $(window)
			.scrollLeft(),
		right: tooltip.offset().left - $(window)
			.scrollLeft() + tooltip.actual('outerWidth'),
	};
	return bounds.top >= viewport.top
		&& bounds.bottom <= viewport.bottom
		&& bounds.left >= 0
		&& bounds.right <= viewport.width;

};
/**
 * @param {any} position
 * @returns {{top:number}}
 */
TooltipBase.prototype.getCssForPosition = function (position, dimensionOfTooltipTrigger) {
	// abstract
	return null;
};

export default TooltipBase;
