import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SystemParameter } from './system-parameter';

@Injectable()
export class SystemParameterService {

	constructor(private http: HttpClient) {}

	public get(variable: string): Observable<Array<SystemParameter>> {
		return this.http.get<Array<SystemParameter>>('rest/system-parameters/' + variable);
	}
}
