import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, ErrorHandler } from '@angular/core';
import { BUI_MESSAGE_RESOLVER } from '@bas/ui';
import { AppComponent } from './app.component';
import { BASErrorHandler } from './errorhandler/bas-error.handler';
import { BlobErrorInterceptor } from './errorhandler/blob-error.interceptor';
import { WebserviceExceptionInterceptor } from './errorhandler/webservice-exception/webservice-exception.interceptor';
import { WebserviceExceptionService } from './errorhandler/webservice-exception/webservice-exception.service';
import { getNodes } from './navigation/nodes/nodes';
import { Nodes } from './permissions/permissions.token';
import { BreadcrumbService } from './shared/generic-compounds/breadcrumb/breadcrumb.service';
import { LocaleProvider } from './shared/services/locale.provider';
import { BuiMessageResolver } from './shared/translate/bui-message-resolver';
import { StrutsComponent } from './struts/struts.component';
import { StrutsService } from './struts/struts.service';

export const sharedDeclarations = [
	AppComponent,
	StrutsComponent,
];

export const sharedProviders = [
	{ provide: Nodes, useValue: getNodes },
	{ provide: ErrorHandler, useClass: BASErrorHandler },
	{ provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
	{ provide: BUI_MESSAGE_RESOLVER, useClass: BuiMessageResolver },
	{ provide: HTTP_INTERCEPTORS, useClass: WebserviceExceptionInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: BlobErrorInterceptor, multi: true },
	LocaleProvider,
	StrutsService,
	WebserviceExceptionService,
	BreadcrumbService,
];
