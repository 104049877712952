import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import configuration from 'globalConfiguration';
import BAS from 'misc/bas';
import { merge, Observable, Subscription } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { StrutsService } from './struts.service';

@Component({
	templateUrl: './struts.component.html',
	styleUrls: ['./struts.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrutsComponent implements OnInit, OnDestroy {
	@ViewChild('content', { static: false })
	dataContainer: ElementRef;

	checksum: string;

	private subscriptions: Array<Subscription>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private strutsService: StrutsService,
	) {
	}

	ngOnInit() {
		window.skipPanelCheck = false; // Prevent PanelHelper to prevent removal of AjaxLoader

		const removeLeadingSlash = url => {
			return url.startsWith('/') ? url.substring(1) : url;
		};

		const htmlSubscription = this.strutsService.html$
			.subscribe(html => this.newHtml(html));

		const routerNavigationStartSubscription = this.router.events
			.pipe(filter(this.navigationStart))
			.subscribe(() => this.strutsService.ajaxPageLoadStart());

		const routerNavigationEnd = this.router.events
			.pipe(
				filter(this.navigationEnd),
				map(event => event.url),
				map(removeLeadingSlash),
			);

		const firstRoute = this.route.url.pipe(
			first(), // Take first url
			map(segments => segments.map(({ path }) => path)), // Map Every segment to its path
			map(paths => paths.join('/')), // Combine all paths to single url
			map(removeLeadingSlash),
			map(url => 'enc::' + url),
			switchMap(url => this.appendQueryParamsOfCurrentUrl(url)),
		);

		const routingSubscription = merge(routerNavigationEnd, firstRoute)
			.subscribe(url => this.strutsService.get(url));

		this.subscriptions = [htmlSubscription, routingSubscription, routerNavigationStartSubscription];
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe()); // unsubscribe all subscriptions
		window.skipPanelCheck = true; // PanelHelper should prevent removal of AjaxLoader again
	}

	private navigationEnd(event: Event | RouterEvent): event is NavigationEnd {
		return event instanceof NavigationEnd;
	}

	private navigationStart(event: Event | RouterEvent): event is NavigationStart {
		return event instanceof NavigationStart;
	}

	private appendQueryParamsOfCurrentUrl(url: string): Observable<string> {
		return this.route.queryParamMap
			.pipe(
				map(params => params.keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params.get(key)))),
				map(params => params.join('&')),
				map(params => [url, params]),
				map(urlParts => urlParts.filter(Boolean)),
				map(urlParts => urlParts.join('?')),
				take(1),
			);
	}

	private newHtml(html: string) {
		BAS.isLogout = this.strutsService.isLogout;

		if (this.strutsService.isLogout) {
			BAS.loadFullPageOnNextNavigationChange = true;
		}

		let jNode = BAS.getResponseContent(html);
		let nodeToPerformOn;
		if (BAS.isLoadFullPage() || jNode.find('#errorFlag').length > 0) {
			nodeToPerformOn = this.dataContainer.nativeElement;
			window.$(document)
				.trigger('restartup', [jNode]);
		} else {
			nodeToPerformOn = this.dataContainer.nativeElement.querySelector(configuration.global.contentArea);
			if (nodeToPerformOn === null) {
				nodeToPerformOn = this.dataContainer.nativeElement.querySelector(configuration.global.alternateContentContainer);
			}
			if (nodeToPerformOn === null) {
				// Initial Startup
				nodeToPerformOn = this.dataContainer.nativeElement;
				jNode = window.$(html);
			}
		}

		this.emptyNode(nodeToPerformOn);

		const backButtonNode = jNode.find('.button[data-forward]')
			.filter((index, button) => {
				const forward = button.getAttribute('data-forward');
				return this.strutsService.backActionCodes.includes(forward);
			})
			.first()
			.get(0);
		this.strutsService.setBackButton(<HTMLAnchorElement>backButtonNode);
		const length = jNode.length;
		for (let i = 0; i < length; ++i) {
			nodeToPerformOn.appendChild(jNode[i]);
		}
		window.skipPanelCheck = false;

		const nodes = nodeToPerformOn.querySelectorAll('a[target]:not([rel])');
		for (let i = 0; i < nodes.length; i++) {
			nodes[i].setAttribute('rel', 'noopener noreferer');
		}

		this.strutsService.ajaxPageLoaded(nodeToPerformOn);
	}

	private emptyNode(node: HTMLElement) {
		const children = node.children;
		while (children[0]) {
			$(children[0])
				.remove();
		}
	}
}
