import { AbstractControl, FormGroup, NgForm } from '@angular/forms';

export interface MarkAllOptinos {
	touched?: boolean;
	updateValidity?: boolean;
}

export function markAll(control: NgForm | AbstractControl, options: MarkAllOptinos = {}) {
	if (control instanceof NgForm) {
		control = control.control;
	}
	if (options.touched !== undefined) {
		if (options.touched && !control.touched) {
			control.markAsTouched({ onlySelf: true });
		} else if (!options.touched && control.touched) {
			control.markAsUntouched({ onlySelf: true });
		}
	}
	if (options.updateValidity) {
		control.updateValueAndValidity();
	}

	if (control instanceof FormGroup) {
		const ctrls = control.controls;
		Object.keys(ctrls)
			.map(key => ctrls[key])
			.forEach(ctrl => {
				markAll(ctrl, options);
			});
	}
}

export interface MarkParentsOptions {
	touched?: boolean;
	updateValidity?: boolean;
	skipCount?: number;
	selector?: (control: AbstractControl) => boolean;
}

export function markParents(control: NgForm | AbstractControl, options: MarkParentsOptions = {}) {
	if (control instanceof NgForm) {
		control = control.control;
	}
	if (options.skipCount <= 0 || (options.selector && options.selector(control))) {
		if (options.touched !== undefined) {
			if (options.touched && !control.touched) {
				control.markAsTouched({ onlySelf: true });
			} else if (!options.touched && control.touched) {
				control.markAsUntouched({ onlySelf: true });
			}
		}
		if (options.updateValidity) {
			control.updateValueAndValidity({ onlySelf: true });
		}
	}

	if (control.parent) {
		options.skipCount--;
		markParents(control.parent, options);
	}
}

interface AnyControlptions {
	control: NgForm | AbstractControl;
	callback: (control: AbstractControl) => boolean;
	skipSelf?: boolean;
}

export function anyControl(options: AnyControlptions): boolean {
	if (options.control instanceof NgForm) {
		options.control = options.control.control;
	}

	if (options.callback(options.control)) {
		return true;
	}

	if (options.control instanceof FormGroup) {
		const ctrls = options.control.controls;

		for (const key in ctrls) {
			if (anyControl({ ...options, control: ctrls[key], skipSelf: false })) {
				return true;
			}
		}
	}
	return false;

}

export function setDebugKeys(control: NgForm | AbstractControl & { formName?: string }, prefix: string = '') {
	if (control instanceof NgForm) {
		control = control.control;
	}

	control.formName = prefix;

	if (control instanceof FormGroup) {
		const ctrls = control.controls;
		Object.keys(ctrls)
			.forEach(key => {
				setDebugKeys(ctrls[key], prefix ? prefix + '.' + key : key);
			});
	}
}
