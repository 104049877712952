import { HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { VirusScanInterceptor } from './errorhandler/virus-scan.interceptor';
import { SideNavModule } from './navigation/side-nav/side-nav.module';
import { sharedDeclarations, sharedProviders } from './shared-dependencies';
import { sharedModules } from './shared-modules';

@NgModule({
	declarations: [
		...sharedDeclarations,
	],
	imports: [
		...sharedModules,
		SideNavModule,
		HttpClientXsrfModule,
	],
	providers: [
		...sharedProviders,
		{ provide: HTTP_INTERCEPTORS, useClass: VirusScanInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
