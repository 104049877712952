import { ModuleWithProviders, NgModule } from '@angular/core';
import { MsgKeyCachePipe, MsgKeyExistsPipe, MsgKeyHtmlCachedPipe, MsgKeyHtmlPipe, MsgKeyPipe } from './msg-key.pipe';
import { MsgKeyService } from './msg-key.service';

@NgModule({
	declarations: [
		MsgKeyPipe,
		MsgKeyHtmlPipe,
		MsgKeyHtmlCachedPipe,
		MsgKeyExistsPipe,
		MsgKeyCachePipe
	],
	exports: [
		MsgKeyPipe,
		MsgKeyHtmlPipe,
		MsgKeyExistsPipe,
		MsgKeyHtmlCachedPipe,
		MsgKeyCachePipe
	]
})
export class MsgKeyModule {

	static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: MsgKeyModule,
			providers: [MsgKeyService]
		};
	}
}
