import configuration from 'globalConfiguration';
import eventHelper from 'helper/eventHelper';

function ScrollHelper() {
	this.configuration = configuration.ScrollHelper;
	this.scrollPosition = null;
	this.currentPage = $(this.configuration.contentSelector)
		.data('currentPage');
	this.isScrolled = false;

	this.addEventListener();
}

ScrollHelper.prototype = {

	addEventListener: function () {
		$(document)
			.on('beforeRemoveAjaxRotator', $.proxy(this.onPageChange, this));
	},

	getScrollPositionForInput: function (input) {
		var offset = input.offset().top,
			inputHeight = input.height(),
			scrollPosition,
			editmask = $('#editMask');

		if (input.is('.inlineckeditor')) {
			var toolbar = CKEDITOR.instances[input.attr('id')].ui.space('top');
			var realToolbarHeight = parseInt($(toolbar.$)
				.css('height'), 10);
			offset = input.parent()
				.offset().top;
			inputHeight = inputHeight - realToolbarHeight;
		}

		scrollPosition = offset - ($(window)
			.height() / 2) - inputHeight;
		if (scrollPosition < editmask.offset().top) {
			scrollPosition = editmask.offset().top;
		}

		return scrollPosition;
	},

	scrollTo: function (scrollPosition) {
		if (!this.isScrolled) {
			this.isScrolled = true;
			this.scrollToPosition(scrollPosition, 0);
			if (window.CKEDITOR && Object.keys(CKEDITOR.instances).length > 0) {
				$(document)
					.one('beforeRemoveAjaxRotator', $.proxy(this.scrollToPosition, this, scrollPosition, 0, undefined));
			}
		}
	},

	scrollToTop: function (scrollAnimationDuration) {
		if (!this.isScrolled) {
			this.isScrolled = true;
			this.scrollToPosition(0, scrollAnimationDuration);
		}
	},

	scrollToElement: function (element, scrollAnimationDuration, dfd) {
		this.scrollToPosition($(element)
			.offset().top, scrollAnimationDuration, dfd);
	},

	scrollToPosition: function (position, scrollAnimationDuration, dfd) {
		var panelCount = document.querySelectorAll(this.configuration.contentSelector + ' .panel').length;
		if (panelCount > 0) {
			var domDocument = $(document);
			eventHelper.deferredEmit($.proxy(this.doScroll, this, position, scrollAnimationDuration, dfd), 'allPanelsReady', domDocument);
		} else {
			this.doScroll(position, scrollAnimationDuration, dfd);
		}
	},

	doScroll: function (position, scrollAnimationDuration, dfd) {
		var bodys = $('html, body')
			.stop();
		if (dfd || scrollAnimationDuration) {
			bodys.animate({
				scrollTop: position,
			}, scrollAnimationDuration ? scrollAnimationDuration : 0, function () {
				if (dfd) {
					dfd.resolve();
				}
			});
		} else {
			window.scrollTo(window.scrollX, position);
		}
	},

	setScrollPoint: function () {
		this.scrollPosition = this.getCurrentScrollPosition();
		$('#scrollPoint')
			.val(this.scrollPosition);
	},

	onPageChange: function () {
		var content = $(this.configuration.contentSelector),
			currentPage = content.data('currentPage');
		content.removeAttr('data-current-page');
		if (this.currentPage !== currentPage) {
			this.currentPage = currentPage;
			this.scrollPosition = 0;
		}
	},

	resetScrollPoint: function () {
		this.scrollPosition = null;
		this.isScrolled = false;
		var hiddenScrollPoint = $('#scrollPoint');
		if (hiddenScrollPoint.length === 1) {
			hiddenScrollPoint.val('');
		}
	},

	getCurrentScrollPosition: function () {
		if (typeof pageYOffset != 'undefined') {
			return pageYOffset; // common Browsers
		}
		// IE with DocType
		return document.documentElement.scrollTop;
	},

};

export default new ScrollHelper();
