import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import LocaleHelper from 'helper/localeHelper';

/*
 * https://github.com/armanozak/angular-dynamic-locale
 * https://stackblitz.com/edit/angular-dynamic-locale
 */
@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	private _currentLocale;

	get currentLocale(): string {
		return this._currentLocale;
	}

	constructor(
		private router: Router,
		@Optional()
		@SkipSelf()
			otherInstance: LocaleService,
	) {
		if (otherInstance) {
			throw new Error('LocaleService should have only one instance.');
		}
		document.addEventListener('afterReinitLocaleHelper', () => {
			this.setLocale(LocaleHelper.getFormat());
		});
	}

	private registerLocaleData() {
		const locale = {
			'de_DE': localeDe,
			'en_GB': localeEn
		}[this._currentLocale] || localeDe;
		registerLocaleData(locale);
	}

	setLocale(localeId: string) {
		this._currentLocale = localeId;
		this.registerLocaleData();
	}
}
