<ng-container *ngIf="user(); else notLoggedIn">
	<div>
		<a routerLink="/help.do?currentTopic=homepage/impressum">
			{{"frame.footer.impressum" | msgKeyCached}}
		</a>
	</div>
</ng-container>

<ng-template #notLoggedIn>
	<ng-container *ngIf="('showLinkToImpressumBeforeLogin' | feature | async)">
		<div>
			<a routerLink="/help.do?currentTopic=homepage/impressum">
				{{"frame.footer.impressum" | msgKeyCached}}
			</a>
		</div>
	</ng-container>
</ng-template>