import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeatureService } from './feature/feature.service';

export const createFeatureAccessGuard = (feature: string): CanActivateFn => createModuleAccessGuard(() => inject(FeatureService)
	.has(feature));

export const createModuleAccessGuard = (hasModuleAccess: (next?: ActivatedRouteSnapshot) => Observable<boolean>): CanActivateFn => (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	const router = inject(Router);
	return hasModuleAccess(next)
		.pipe(
			tap(hasAccess => {
				if (!hasAccess) {
					router.navigateByUrl('error-fallback');
				}
			}),
		);
};
