export enum TooltipPosition {
	TOP_RIGHT,
	TOP_LEFT,
	BOTTOM_RIGHT,
	BOTTOM_LEFT
}

export interface TooltipSpace {
	position: TooltipPosition;
	width: number;
}
