import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkComponent } from './link.component';
import { IconLinkComponent } from './icon-link.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		LinkComponent,
		IconLinkComponent,
	],
	exports: [
		LinkComponent,
		IconLinkComponent,
	]
})
export class LinkModule { }
