import { Injectable } from '@angular/core';
import { BouncerProvider } from '../models/bouncer-provider';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BouncerService implements BouncerProvider {

	constructor(private http: HttpClient) {}

	public check(property: string): Observable<boolean> {
		return this.http.get<boolean>('rest/bouncer/' + property);
	}
}
