import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogModule} from '@bas/ui';
import {ButtonModule} from '../shared/elements/button/button.module';
import {MsgKeyModule} from '../shared/translate/msg-key.module';
import {AdblockDialogComponent} from './adblock-dialog.component';
import {AdblockService} from './adblock.service';

@NgModule({
	imports: [
		MatDialogModule,
		ButtonModule,
		MsgKeyModule,
		HttpClientModule,
		DialogModule,
		ButtonModule
	],
	declarations: [
		AdblockDialogComponent
	],
	providers: [
		AdblockService
	]
})
export class AdblockModule { }
