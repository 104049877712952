import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { H1Component } from './h1/h1.component';
import { H2Component } from './h2/h2.component';
import { H3Component } from './h3/h3.component';

/**
 * @group Elements
 * @component Headlines
 */
@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		H1Component,
		H2Component,
		H3Component
	],
	exports: [
		H1Component,
		H2Component,
		H3Component
	]
})
export class HeadlineModule {}
