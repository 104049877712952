import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupSeparatorModule } from '../../elements/group-separator/group-separator-module';
import { MsgKeyModule } from '../../translate/msg-key.module';
import { GroupComponent } from './group.component';

@NgModule({
	imports: [
		CommonModule,
		GroupSeparatorModule,
		MsgKeyModule
	],
	declarations: [ GroupComponent ],
	exports: [
		GroupComponent
	]
})
export class GroupModule {}
