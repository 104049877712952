import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'bas-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('flyInOut', [
			state('in', style({
				'height': '*',
				'padding-top': '*',
				'padding-bottom': '*',
				'margin-top': '*',
				'margin-bottom': '*'
			})),
			transition(':enter', [
				style({
					'height': '0',
					'padding-top': '0',
					'padding-bottom': '0',
					'margin-top': '0',
					'margin-bottom': '0'
				}),
				animate('.4s cubic-bezier(.25,.8,.25,1)')
			]),
			transition(':leave', [
				animate('.4s cubic-bezier(.25,.8,.25,1)', style({
					'height': '0',
					'padding-top': '0',
					'padding-bottom': '0',
					'margin-top': '0',
					'margin-bottom': '0'
				}))
			])
		])
	]
})
export class ErrorComponent {
	@Input()
	error = '';

	constructor(public cdr: ChangeDetectorRef) { }

	@HostBinding('@flyInOut')
	flyInOut = 'in';
}
