import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../shared/store/application-state';
import { UserQuery } from '../../../shared/store/reducers/user.reducer';
import { NavigationItem } from '../../models/navigationItem';
import { NavigationQuery } from '../../store/navigation.reducer';

@Component({
	selector: 'bas-side-nav-user',
	templateUrl: './side-nav-user.component.html',
	styleUrls: ['./side-nav-user.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavUserComponent {

	items = this.store.selectSignal(NavigationQuery.getUserNavItems);
	user = this.store.selectSignal(UserQuery.getUser);

	constructor(private store: Store<ApplicationState>) {
	}

	isItemHeadline(item: NavigationItem): boolean {
		return item.parameters['headline'];
	}
}
