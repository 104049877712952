import { ChangeDetectionStrategy, Component, computed, Input, OnInit, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../shared/store/application-state';
import { NavigationItem } from '../../../models/navigationItem';
import { SetActiveNavigationItemAction } from '../../../store/navigation.actions';
import { NavigationQuery } from '../../../store/navigation.reducer';

@Component({
	selector: 'bas-side-nav-item',
	templateUrl: './side-nav-item.component.html',
	styleUrls: ['./side-nav-item.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavItemComponent implements OnInit {

	@Input() isDefaultLink = false;
	@Input() item: NavigationItem;
	@Input() active: Signal<boolean>;

	constructor(private store: Store<ApplicationState>) {
	}

	ngOnInit(): void {
		this.active = this.active || computed(() => this.store.selectSignal(NavigationQuery.getActiveId)() === this.item.id);
	}

	setActive() {
		this.store.dispatch(new SetActiveNavigationItemAction(this.item.id));
	}
}
