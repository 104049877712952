<div class="menuBar">
	<div class="menuLeft">

	</div>
	<div class="menuTitle">
		{{'kpi.dashboard.label' | msgKey}}
	</div>
	<div (click)="logout()" class="menuRight">
		<mat-icon svgIcon="logout"></mat-icon>
	</div>
</div>