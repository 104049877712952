import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../shared/store/application-state';
import { NavigationQuery } from '../../store/navigation.reducer';

@Component({
	selector: 'bas-side-nav-hotlinks',
	templateUrl: './side-nav-hotlinks.component.html',
	styleUrls: ['./side-nav-hotlinks.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavHotlinksComponent {
	activeMainItem = this.store.selectSignal(NavigationQuery.getActiveMainItem);

	constructor(private store: Store<ApplicationState>) {
	}
}
