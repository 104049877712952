import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GetUserAction } from '../shared/store/actions/user.actions';
import { ApplicationState } from '../shared/store/application-state';
import { UserQuery } from '../shared/store/reducers/user.reducer';

export const loggedInGuard: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
	const store = inject(Store<ApplicationState>);
	store.select(UserQuery.getUserRequested)
		.subscribe(userRequested => {
			if (!userRequested) {
				store.dispatch(new GetUserAction());
			}
		})
		.unsubscribe();
	return store.select(UserQuery.getUserRequested)
		.pipe(
			filter(Boolean),
			switchMap(() => store.select(UserQuery.getUser)),
			map(user => !!user),
			tap(loggedIn => {
				if (!loggedIn) {
					inject(Router)
						.navigateByUrl('/');
				}
			}),
		);
};
