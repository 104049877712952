/* tslint:disable */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './legacy/default/dependencyManager';
import './legacy/default/misc/log';
import './legacy/default/lib/jquery.browser.detection';
import './legacy/default/plugins/jquery.animateElementTo';

/**
 * Less imports
 *
 * Folgende Kommentare dürfen NICHT entfernt werden. Die Imports werden vom File-Finder eingelesen,
 * aber der das kompilierte JS bringt den Browser zum sterben, wenn die Imports ausgeführt werden.
 */
// import 'style-loader!./style/default/css/yaml/screen/typography.css';
// import 'style-loader!./legacy/default/plugins/jstree/themes/default/jsTree.extension.css';
// import 'style-loader!./main.less';
// import 'style-loader!./style/default/css/organism/base.less';
// import 'style-loader!./style/default/css/theme.less';
// import 'style-loader!./style/customerTheme.less';
// import './ui.scss';

/**
 * !DO NOT AUTO-FORMAT THIS FILE!
 */

Object.defineProperty(window, 'timestamp', {
	get: () => {
		const element: HTMLInputElement = document.querySelector('input[name="timestamp"]');
		if (element) {
			return element.value;
		}
	},
});

const encode = encodeURIComponent;
window['encodeURIComponent'] = (component: string) => {
	return encode(component)
		.replace(/[!'()*]/g, (c) => {
			// Also encode !, ', (, ), and *
			return '%' + c.charCodeAt(0)
				.toString(16);
		});
};

if (environment.production) {
	enableProdMode();
	jQuery['migrateMute'] = true;
}

let ts = new Date();
window.angularId = ts.getTime();
// InternetExplorer finishes the Iframe before destroying it and thus inits requireJS again. This has to be prevented
if (window.frameElement === null) {
	import('./legacy/default/dependencyManager').then(({ default: dm }) => {
		(<any>$).browserDetection('addClass');
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			.catch(err => console.log(err));
	});
}