import { User } from '../../../../models/user';
import { AddUserAction, UserActions, UserActionTypes } from '../actions/user.actions';
import { ApplicationState } from '../application-state';
import { UserState } from '../states/user.state';

const initialState: UserState = {
	userRequested: false
};

const userEquals = (a: User, b: User) => {
	if (!a || !b) {
		return false;
	}

	const aKeys = Object.keys(a);
	const bKeys = Object.keys(b);

	if (aKeys.length !== bKeys.length) {
		return false;
	}

	return aKeys.every(key => a[key] === b[key]);
};

export function userReducer(state: UserState = initialState, action: UserActions) {

	switch (action.type) {
		case UserActionTypes.AddUser: {
			const user = (<AddUserAction>action).payload;
			if (userEquals(user, state.user)) {
				return state;
			}

			if (state.userRequested) {
				return {
					...state,
					user
				};
			}

			return {
				...state,
				user,
				userRequested: true
			};
		}

		case UserActionTypes.RemoveUser: {
			if (!state.user) {
				return state;
			}

			return {
				...state,
				user: null,
				userRequested: false
			};
		}

		default:
			return state;
	}
}

export namespace UserQuery {
	export const getUser = (state: ApplicationState) => state.userState.user;
	export const getSession = (state: ApplicationState) => state.userState.session;
	export const getUserRequested = (state: ApplicationState) => state.userState.userRequested;
}
