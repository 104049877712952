import { Routes } from '@angular/router';
import { loggedInGuard } from './permissions/logged-in.guard';

export const customerRoutes: Routes = [
	{
		path: 'wkz',
		loadChildren: () => import('./wkz/wkz.module').then(m => m.WkzModule),
		canActivate: [
			loggedInGuard,
		],
	}, {
		path: 'wkz-export',
		loadChildren: () => import('./wkz-export/wkz-export.routes').then(m => m.WKZ_EXPORT_ROUTES),
		canActivate: [
			loggedInGuard,
		],
	},
];
