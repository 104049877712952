import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { GhostButtonComponent } from './ghost-button.component';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		CommonModule,
		RouterModule
	],
	declarations: [
		ButtonComponent,
		GhostButtonComponent
	],
	exports: [
		ButtonComponent,
		GhostButtonComponent
	]
})
export class ButtonModule {
}
