<div
		[class.active]="basketHasItems()"
		[title]="basketTooltip() | msgKey"
		(mouseenter)="open()"
		(mouseleave)="close()"
>
	<div class="icon"></div>
	<div
			*ngIf="basketHasItems()"
			class="basket-content"
			[@openState]="state"
	>
		<div
				*ngFor="let doctype of basketDocTypes()"
				class="basket-item"
		>
			<span>{{ doctype.nameKey | msgKey }}</span>
			<span>({{ doctype.count }})</span>
		</div>
		<div class="basket-link">
			<a
					routerLink="/workflow.do?from=menu&to=basket"
					class="defaultLink"
			>
				<span>{{ 'text.link.tobasket' | msgKey }}</span>
			</a>
		</div>
	</div>
</div>
