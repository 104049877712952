import { ActionReducerMap } from '@ngrx/store';
import { navigationReducer } from '../../navigation/store/navigation.reducer';
import { ApplicationState } from './application-state';
import { breadcrumbReducer } from './reducers/breadcrumbs.reducer';
import { mobileReducer } from './reducers/mobile.reducer';
import { userReducer } from './reducers/user.reducer';

export const applicationReducers: ActionReducerMap<ApplicationState> = {
	userState: userReducer,
	breadcrumbsState: breadcrumbReducer,
	navigationState: navigationReducer,
	mobileState: mobileReducer
};
