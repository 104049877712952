import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * @group Elements
 * @component Link
 */
@Component({
	selector: '[bas-link]',
	template: '<ng-content></ng-content>',
	styleUrls: [ './link.component.less' ],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {}
