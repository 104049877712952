<ng-container *ngIf="('showLinkToPrivacyPolicy' | feature | async)">
	<ng-container *ngIf="user(); else notLoggedIn">
		<div>
			<a [routerLink]="'/img/Datenschutz_' + user().uiLanguage + '.pdf'" target="_blank">
				{{"frame.footer.datenschutz" | msgKey}}
			</a>
		</div>
	</ng-container>
</ng-container>

<ng-template #notLoggedIn>
	<ng-container *ngIf="('showLinkToPrivacyPolicyBeforeLogin' | feature | async)">
		<div>
			<a [routerLink]="'/img/Datenschutz_' + ('i18nDefaultUiLanguage' | featureValue | async) + '.pdf'" target="_blank">
				{{"frame.footer.datenschutz" | msgKey}}
			</a>
		</div>
	</ng-container>
</ng-template>