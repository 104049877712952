<li
		(mouseenter)="open()"
		(mouseleave)="close()"
		[class.active]="isActive()"
>
	<a
			*ngIf="item.url; else noLink"
			#anchor
			bas-anchor-status
			[parameters]="item.parameters"
			class="nav-item top"
			[routerLink]="item.url"
			[queryParams]="item.query"
			(click)="active()"
			(focus)="focus()"
			(blur)="blur()"
	>
		<span>{{ item.name || (item.nameKey | msgKey) }}</span>
	</a>
	<ng-template #noLink>
		<a
			#anchor
			bas-anchor-status
			[parameters]="item.parameters"
			class="nav-item top"
			(focus)="focus()"
			(blur)="blur()"
			style="cursor: context-menu;"
		>
			<span style="border: none;">{{ item.name || (item.nameKey | msgKey) }}</span>
		</a>
	</ng-template>
	<ul
			*ngIf="hasChildren()"
			[@openState]="state"
	>
		<bas-nav-sub-item-level-1
				*ngFor="let subItem of item.children"
				[item]="subItem"
				(itemClick)="close()"
		></bas-nav-sub-item-level-1>
	</ul>
</li>
