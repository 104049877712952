<ng-container *ngIf="('showLinkToTermsOfUse' | feature | async)">
    <ng-container *ngIf="user(); else notLoggedIn">
        <div>
            <a [routerLink]="('frame.footer.nutzungsbedingungen.filename' | msgKey) + (user().uiLanguage) + '.pdf'"
               target="_blank">
                {{"frame.footer.nutzungsbedingungen" | msgKey}}
            </a>
        </div>
    </ng-container>
</ng-container>

<ng-template #notLoggedIn>
    <ng-container *ngIf="('showLinkToTermsOfUseBeforeLogin' | feature | async)">
        <div>
            <a routerLink="{{'frame.footer.nutzungsbedingungen.filename' | msgKey}}{{ ('i18nDefaultUiLanguage' | featureValue | async) }}.pdf"
               target="_blank">
                {{"frame.footer.nutzungsbedingungen" | msgKey}}
            </a>
        </div>
    </ng-container>
</ng-template>

