import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class InputValidationService {

	private basePath = 'rest/validation';

	constructor(private http: HttpClient) { }

	//Wird für rekuMailings verwendet
	validateEmail(name: string, email: string) {
		return this.http.get(`${this.basePath}/validateEmail`, { params: { 'name': name, 'emailAddress': email } });
	}

	zipValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const zip = control.value;
			return this.http.get(`${ this.basePath }/validateZip`, { params: { 'zip': zip } })
				.pipe(
					map(() => null),
					catchError((errorResponse: HttpErrorResponse) => {
						const result: {
							[key: string]: Array<string>
						} = errorResponse.error;
						return of({ format: result.zip[0] });
					}),
				);
		};
	}
}
