import { NgModule } from '@angular/core';
import { PROVIDER } from './additional-permissions/providers/providers';
import { BouncerModule } from './bouncer/bouncer.module';
import { FeatureModule } from './feature/feature.module';
import { NodePermissionFilter } from './node.permission.filter';
import { SystemParameterModule } from './system-parameter/system-parameter.module';
import { UserModule } from './user/user.module';

@NgModule({
	imports: [
		FeatureModule,
		UserModule.forRoot(),
		BouncerModule,
		SystemParameterModule,
	],
	exports: [
		FeatureModule,
		UserModule,
		BouncerModule,
		SystemParameterModule,
	],
	providers: [
		NodePermissionFilter,
		PROVIDER,
	],
})
export class PermissionsModule {}

