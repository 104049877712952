<div (mouseenter)="open()" (mouseleave)="close()">
	<div class="quick-nav-trigger" [ngClass]="state" [title]="'text.logtype.quicknav' | msgKey"></div>
	<div [@openState]="state" class="quick-nav-container mat-elevation-z8">
		<ng-container *ngFor="let item of items()">
			<ng-container *ngIf="item.name === 'root'; else normal">
				<div *ngFor="let rootchild of item.children" class="quick-nav-item">
					<a [routerLink]="rootchild.url" [queryParams]="rootchild.query"
					   (click)="onClick(rootchild)" bas-anchor-status [parameters]="rootchild.parameters">
						<span>{{ rootchild.name || (rootchild.nameKey | msgKey) }}</span>
					</a>
					<div class="quick-nav-sub-item" *ngFor="let child of rootchild.children">
						<a [routerLink]="child.url" [queryParams]="child.query" (click)="onClick(child)" bas-anchor-status
							[parameters]="child.parameters">
							<span>{{ child.name || (child.nameKey | msgKey) }}</span>
						</a>
					</div>
				</div>
			</ng-container>
			<ng-template #normal>
				<div class="quick-nav-item">
					<a [routerLink]="item.url" [queryParams]="item.query"
					   (click)="onClick(item)" bas-anchor-status [parameters]="item.parameters">
						<span>{{ item.name || (item.nameKey | msgKey) }}</span>
					</a>
					<div class="quick-nav-sub-item" *ngFor="let child of item.children">
						<a [routerLink]="child.url" [queryParams]="child.query"
						   (click)="onClick(child)" bas-anchor-status [parameters]="child.parameters">
							<span>{{ child.name || (child.nameKey | msgKey) }}</span>
						</a>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</div>
</div>