import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { UserModule } from '../../permissions/user/user.module';
import { TooltipModule } from '../../shared/generic-compounds/tooltip/tooltip.module';
import { MsgKeyModule } from '../../shared/translate/msg-key.module';
import { HeaderComponent } from './header.component';
import { QuickNavComponent } from './quick-navigation/quick-navigation.component';
import { AnchorStatusModule } from './shared/elements/anchor-status/anchor-status.module';
import { NavItemComponent } from './shared/elements/nav-item/nav-item.component';
import { NavSubItemLevel1Component } from './shared/elements/nav-sub-item/level-1/nav-sub-item-level-1.component';
import { NavSearchFieldComponent } from './shared/generic-compounds/nav-search-field/nav-search-field.component';
import { NavSearchHighlightPipe } from './shared/generic-compounds/nav-search-field/nav-search-highlight.pipe';
import { BasketComponent } from './shared/specific-compounds/basket/basket.component';

@NgModule({
	declarations: [
		HeaderComponent,
		QuickNavComponent,
		NavItemComponent,
		NavSubItemLevel1Component,
		NavSearchFieldComponent,
		BasketComponent,
		NavSearchHighlightPipe
	],
	imports: [
		CommonModule,
		MsgKeyModule,
		BrowserAnimationsModule,
		MatIconModule,
		RouterModule,
		ReactiveFormsModule,
		TooltipModule,
		AnchorStatusModule,
		UserModule
	],
	exports: [
		HeaderComponent,
		QuickNavComponent
	]
})
export class HeaderModule { }
