function TextHelper() {
	this.requestedKeys = {};
	this.awaitedKeys = {};
	this.cachedKeys = {};

	let that = this;

	$(document)
		.on('reinitTextHelper', function () {
			that.requestedKeys = {};
			that.awaitedKeys = {};
		});
}

TextHelper.prototype = {

	initTexts: function (msgKeys, callback, callBackContext) {
		return this.initTextsCore('/rest/messages', msgKeys, callback, callBackContext);
	},

	initTextsHtml: function (msgKeys, callback, callBackContext) {
		return this.initTextsCore('/rest/messagesWithTags', msgKeys, callback, callBackContext);
	},

	/**
	 *
	 * @param {Array<string>} msgKeys
	 * @param {(resolvedKeys:{[key:string]:string})=>any} callback
	 * @param {any} callBackContext
	 */
	initTextsCached: function (msgKeys, callback, callBackContext) {
		let that = this;
		let result = {};
		let cachedKeys = msgKeys.filter(function (key) {
			result[key] = that.cachedKeys[key];
			return that.cachedKeys[key];
		});

		if (cachedKeys.length == msgKeys.length) {
			let resultPromise = jQuery.Deferred();
			resultPromise.resolve(result);
			return resultPromise.promise();
		}

		return this.initTextsCore('/rest/messages', msgKeys, callback, callBackContext)
			.done(function (msgKeys) {
				for (let key in msgKeys) {
					that.cachedKeys[key] = msgKeys[key];
				}
				return msgKeys;
			});

	},

	/**
	 *
	 * @param {string} endpoint
	 * @param {*} msgKeys
	 * @param {(resolvedKeys:{[key:string]:string})=>any} callback
	 * @param {*} callBackContext
	 * @returns {JQueryPromise|JQuery.Deferred<any, any, any>}
	 */

	initTextsCore: function (endpoint, msgKeys, callback?, callBackContext?) {
		let that = this;
		let keysToGet = [];
		/**
		 * @type {{[key:string]:string}}
		 */
		let keysResult = {};
		/**
		 * @type { JQuery.Deferred<any, any, any>|JQuery.Promise2}
		 */
		let awaitedKeysDefer;

		for (let i = 0; i < msgKeys.length; i++) {
			let key = msgKeys[i];
			if (this.requestedKeys.hasOwnProperty(key)) {
				keysResult[key] = this.requestedKeys[key];
			} else {
				if (this.awaitedKeys.hasOwnProperty(key)) {
					// Key is pending
					let defer = $.Deferred();
					if (!awaitedKeysDefer) {
						awaitedKeysDefer = defer;
					} else {
						awaitedKeysDefer = $.when(awaitedKeysDefer, defer);
					}
					this.awaitedKeys[key].push(defer);
				} else {
					keysToGet.push(key);
					this.awaitedKeys[key] = [];
				}
			}
		}
		if (keysToGet.length === 0) {
			if (awaitedKeysDefer) {
				return that.buildAwaitDeferer(awaitedKeysDefer, keysResult, callback, callBackContext);
			}
			let defer = $.Deferred();
			defer.resolve(keysResult);
			if (callback) {
				callback.bind(callBackContext)(keysResult);
			}
			return defer;
		}

		return $.ajax({
			url: context + endpoint,
			type: 'GET',
			dataType: 'json',
			data: { key: keysToGet },
			traditional: true,
		})
			.then(function (result) {
				for (let key in result) {
					let translatedMsg = result[key];
					that.requestedKeys[key] = translatedMsg;
					that.cachedKeys[key] = result[key];
					if (that.awaitedKeys.hasOwnProperty(key)) {
						for (let i = 0; i < that.awaitedKeys[key].length; i++) {
							let resolve = {};
							resolve[key] = translatedMsg;
							that.awaitedKeys[key][i].resolve(resolve);
						}
					}
					keysResult[key] = translatedMsg;

				}
				if (awaitedKeysDefer) {
					return that.buildAwaitDeferer(awaitedKeysDefer, keysResult, callback, callBackContext);
				}
				if (callback) {
					callback.bind(callBackContext)(keysResult);
				}
				return keysResult;
			});
	},
	/**
	 *
	 * @param {*} defer
	 * @param {{[key:string]:string}} keysResult
	 * @param {(resolvedKeys:{[key:string]:string})=>any} callback
	 * @param {*} callBackContext
	 */
	buildAwaitDeferer: function (defer, keysResult, callback, callBackContext) {
		return defer.then(function (res1, res2) {
			let toMerge = [];
			if (res2) {
				toMerge.push(res2);
			}
			let current = res1;
			while (Array.isArray(current)) {
				if (current.length == 2) {
					if (current[1]) {
						toMerge.push(current[1]);
					}
					current = current[0];
				} else {
					break;
				}
			}
			toMerge.push(current);
			for (let i = 0; i < toMerge.length; i++) {
				for (let key in toMerge[i]) {
					keysResult[key] = toMerge[i][key];
				}
			}
			if (callback) {
				callback.bind(callBackContext)(keysResult);
			}
			return keysResult;
		});
	},
};

export default new TextHelper();
