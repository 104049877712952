import { NgModule } from '@angular/core';
import { FeaturePipe } from './feature.pipe';
import { FeatureValuePipe } from './feature-value.pipe';
import { FeatureService } from './feature.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	imports: [
		HttpClientModule
	],
	declarations: [
		FeaturePipe,
		FeatureValuePipe
	],
	providers: [
		FeatureService
	],
	exports: [
		FeaturePipe,
		FeatureValuePipe
	]
})
export class FeatureModule {}
