import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../../elements/button/button.module';
import { LinkModule } from './../../elements/link/link.module';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
	imports: [CommonModule, LinkModule, RouterModule, ButtonModule],
	declarations: [BreadcrumbComponent],
	exports: [BreadcrumbComponent],
	providers: [],
})
export class BreadcrumbModule {
}
