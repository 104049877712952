import { NgModule } from '@angular/core';
import { GroupSeparatorComponent } from './group-separator.component';
import { CommonModule } from '@angular/common';
import { HintModule } from '../../generic-compounds/hint/hint.module';

@NgModule({
	declarations: [
		GroupSeparatorComponent
	],
	imports: [
		CommonModule,
		HintModule
	],
	exports: [
		GroupSeparatorComponent
	]
})
export class GroupSeparatorModule { }
