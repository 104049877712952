import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HideMainNavigationAction, HideSideNavigationAction, RemoveAjaxRotatorAction } from '../navigation/store/navigation.actions';
import { ApplicationState } from '../shared/store/application-state';

@Component({
	selector: 'bas-error-fallback',
	templateUrl: './error-fallback.component.html',
	styleUrls: ['./error-fallback.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorFallbackComponent implements OnInit {

	message: string;
	backToHomepageLink: string;

	constructor(private store: Store<ApplicationState>) {
	}

	ngOnInit() {
		setTimeout(() => {
			this.store.dispatch(new HideMainNavigationAction());
			this.store.dispatch(new HideSideNavigationAction());
			this.store.dispatch(new RemoveAjaxRotatorAction());
		});

		this.message = this.getMessage();
		this.backToHomepageLink = this.getBackToHomePageLink();
	}

	private getMessage(): string {
		if (window.navigator.language.toLowerCase()
			.startsWith('de')) {
			return `Es ist eine unerwartete Ausnahme aufgetreten.<br>
			Bitte kehren Sie zur Startseite zurück.`;
		}
		return `An unexpected exception occurred.<br/>
			Please return to the homepage.`;
	}

	private getBackToHomePageLink(): string {
		if (window.navigator.language.toLowerCase()
			.startsWith('de')) {
			return `Zurück zur Startseite`;
		}
		return `Back to homepage`;
	}
}
