import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MsgKeyModule } from '../../shared/translate/msg-key.module';
import { MobileNavComponent } from './mobile-nav.component';

@NgModule({
	declarations: [
		MobileNavComponent
	],
	imports: [
		CommonModule,
		MsgKeyModule,
		MatIconModule,
		RouterModule,
	],
	exports: [
		MobileNavComponent
	]
})
export class MobileNavModule { }
