export {};

declare global {
	interface Window {
		dontDoThatThing;
	}

	interface JQuery {
		getStyleObject: (a) => any;
		getWholeStyleObject: () => any;
	}
}

$.fn.extend({
	animateElementTo: function (destinationElement, animationType, beforeMethod, callback) {
		if (destinationElement.length !== 1) {
			return;
		}

		var sourceElement = this;
		var sourceElementStyleObject = sourceElement.getStyleObject();
		var clone = sourceElement.clone();
		var destinationCss = $.extend(true, {}, destinationElement.getStyleObject(sourceElementStyleObject), {
			top: destinationElement.offset().top,
			left: destinationElement.offset().left,
		});

		animationType = animationType || 'linear';

		clone.css(sourceElementStyleObject);
		clone.css({
			position: 'absolute',
			top: sourceElement.offset().top,
			left: sourceElement.offset().left,
			'z-index': 10000000,
		});

		if (beforeMethod) {
			beforeMethod(sourceElement, destinationElement, clone, destinationCss);
		}

		$('body')
			.append(clone);

		clone.animate(destinationCss, 500, animationType, function () {
			if (!window.dontDoThatThing) {
				if (callback) {
					callback(sourceElement, destinationElement, clone);
				}
				clone.fadeOut(function () {
					this.remove();
				});
			}
		});
	},
});

$.fn.getStyleObject = function (compareStyleObject) {
	var wholeStyleObject = this.getWholeStyleObject();
	var dom = this.get(0);
	var stubElement = document.createElement(dom.tagName);
	stubElement.style.display = 'none';
	document.body.appendChild(stubElement);
	var stubStyleObject = $(stubElement)
		.getWholeStyleObject();
	document.body.removeChild(stubElement);

	var sanitizedStyleObject = Object.keys(wholeStyleObject)
		.filter(function (key) {
			return wholeStyleObject[key] !== stubStyleObject[key] || (compareStyleObject && compareStyleObject[key] && (compareStyleObject[key] !== wholeStyleObject[key]));
		})
		.reduce(function (styles, key) {
			var tmp = {};
			tmp[key] = wholeStyleObject[key];
			return Object.assign({}, styles, tmp);
		}, {});

	return sanitizedStyleObject;
};
$.fn.getWholeStyleObject = function () {
	var dom = this.get(0);
	var style;
	var returns = {};
	if (window.getComputedStyle) {
		var camelize = function (a, b) {
			return b.toUpperCase();
		};
		style = window.getComputedStyle(dom, null);
		for (var i = 0, l = style.length; i < l; i++) {
			var prop = style[i];
			var camel = prop.replace(/\-([a-z])/g, camelize);
			var val = style.getPropertyValue(prop);
			returns[camel] = val;
		}
		;
		return returns;
	}
	if (style = dom.currentStyle) {
		for (var p in style) {
			returns[p] = style[p];
		}
		return returns;
	}
	return this.css();
};