import { Action } from '@ngrx/store';
import { Breadcrumb } from '../../generic-compounds/breadcrumb/models/breadcrumb';

export enum BreadcrumbsActionTypes {
	GetBreadcrumbs = '[Breadcrumbs] Get Breadcrumbs',
	AddBreadcrumbs = '[Breadcrumbs] Add Breadcrumbs',
	UpdateBreadcrumbs = '[Breadcrumbs] Update Breadcrumbs',
	RemoveBreadcrumbs = '[Breadcrumbs] Remove Breadcrumbs',
	RemoveLastBreadcrumbs = '[Breadcrumbs] Remove Last Breadcrumbs'
}

export class AddBreadcrumbsAction implements Action {
	readonly type: string = BreadcrumbsActionTypes.AddBreadcrumbs;

	constructor(public payload: Array<Breadcrumb>) { }
}

export class GetBreadcrumbsAction implements Action {
	readonly type: string = BreadcrumbsActionTypes.GetBreadcrumbs;
}

export class UpdateBreadcrumbsAction implements Action {
	readonly type: string = BreadcrumbsActionTypes.UpdateBreadcrumbs;

	constructor(public payload: Breadcrumb) { }
}

export class RemoveLastBreadcrumbsAction implements Action {
	readonly type = BreadcrumbsActionTypes.RemoveLastBreadcrumbs;
}

export class RemoveBreadcrumbsAction implements Action {
	readonly type = BreadcrumbsActionTypes.RemoveBreadcrumbs;
}

export type BreadcrumbsActions =
	GetBreadcrumbsAction
	| AddBreadcrumbsAction
	| UpdateBreadcrumbsAction
	| RemoveBreadcrumbsAction;
