import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationState } from '../../../../../shared/store/application-state';
import { NavigationItem } from '../../../../models/navigationItem';
import { SetActiveNavigationItemAction } from '../../../../store/navigation.actions';
import { NavigationQuery } from '../../../../store/navigation.reducer';
import { NavSubItemLevel1Component } from '../nav-sub-item/level-1/nav-sub-item-level-1.component';

@Component({
	selector: 'bas-nav-item',
	templateUrl: './nav-item.component.html',
	styleUrls: ['./nav-item.component.less'],
	animations: [
		trigger('openState', [
			state('open', style({
				transform: 'scaleY(1)',
			})),
			state('closed', style({
				transform: 'scaleY(0)',
				display: 'none',
			})),
			transition('open => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => open', animate('100ms cubic-bezier(0.19, 1, 0.22, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent implements OnInit {
	@Input() item: NavigationItem;
	@ViewChildren(NavSubItemLevel1Component) subItems: QueryList<NavSubItemLevel1Component>;
	@ViewChild('anchor', { static: false }) anchor: ElementRef;

	state: 'closed' | 'open' = 'closed';
	open$: Subject<boolean> = new Subject();
	close$ = new Subject();
	hasFocus = false;

	isActive = computed(() => this.store.selectSignal(NavigationQuery.getActiveMainItem)()?.id === this.item.id);

	// @HostListener('window:keydown.ArrowDown', ['$event'])
	focusNext() {
		if (this.subItems.length === 0) {
			return;
		}
		if (this.hasFocus) {
			if (this.state !== 'open') {
				this.open();
			} else {
				this.subItems.first.anchor.nativeElement.focus();
			}

			return;
		}
		if (this.state !== 'open') {
			return;
		}

		const elementToFocus = this.subItems.find((subItem, index, items) => items[index - 1] && items[index - 1].hasFocus);

		if (!elementToFocus) {
			this.anchor.nativeElement.focus();
			return this.close();
		}

		elementToFocus.anchor.nativeElement.focus();
	}

	// @HostListener('window:keydown.ArrowUp', ['$event'])
	focusBefore() {
		if (this.subItems.length === 0) {
			return;
		}
		const elementToFocus = this.subItems.find((subItem, index, items) => items[index + 1] && items[index + 1].hasFocus);

		if (!elementToFocus) {
			this.anchor.nativeElement.focus();
			return this.close();
		}

		elementToFocus.anchor.nativeElement.focus();
	}

	constructor(
		private store: Store<ApplicationState>,
		private cdr: ChangeDetectorRef,
	) {
	}

	focus() {
		this.hasFocus = true;
	}

	blur() {
		this.hasFocus = false;
	}

	ngOnInit(): void {
		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(map(toOpenState));
		const open$ = this.open$.pipe(
			debounceTime(175),
			map(toOpenState),
		);

		merge(close$, open$)
			.pipe(distinctUntilChanged())
			.subscribe(s => {
				this.state = <'open' | 'closed'>s;
				this.cdr.markForCheck();
			});
	}

	hasChildren(): boolean {
		return this.item.children && this.item.children.length > 0;
	}

	open() {
		this.open$.next(true);
	}

	close() {
		this.open$.next(false);
		this.close$.next(true);
	}

	active() {
		this.store.dispatch(new SetActiveNavigationItemAction(this.item.id));
	}
}
