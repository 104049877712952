function EventHelper() {
	this._debug = false;
	this.emittedEvents = {};
}

EventHelper.prototype = {
	emitEventOnElement: function (eventName, element, initiator) {
		this.addEventToEmittedEvents(eventName, element, initiator);
		element.trigger(eventName);
	},

	addEventToEmittedEvents: function (eventName, element, initiator) {
		if (this.emittedEvents[eventName] === undefined) {
			var mapEventName = {};
			mapEventName[eventName] = {};
			this.emittedEvents = $.extend(true, {}, this.emittedEvents, mapEventName);
		}
		if (this.emittedEvents[eventName][initiator] === undefined) {
			var mapInitiator = {};
			mapInitiator[initiator] = [];
			this.emittedEvents[eventName] = $.extend(true, {}, this.emittedEvents[eventName], mapInitiator);
		}
		this.emittedEvents[eventName][initiator].push(element);
		if (this._debug) {
			console.log(this.emittedEvents);
		}
	},

	reset: function () {
		this.emittedEvents = {};
	},

	isEventEmittedOnElement: function (eventName, element, initiator) {
		if (this._debug) {
			console.log('looking for eventEmit "' + eventName + '" (by: ' + (initiator
				? initiator
				: 'n/a') + ') on ', element);
		}
		var eventWasCalled = false;
		$.each(this.emittedEvents, function (calledEvent, initiatorList) {
			if (calledEvent === eventName) {
				$.each(initiatorList, function (initiatorOfEvent, calledElement) {
					if (initiator && initiator !== initiatorOfEvent) {
						return true;
					}
					if ($(calledElement[0])
						.is(element)) {
						if (this._debug) {
							console.log('event ' + eventName + ' (emit by' + initiatorOfEvent + ') was allready called');
						}
						eventWasCalled = true;
					}
				}.bind(this));
			}
		}.bind(this));
		return eventWasCalled;
	},

	deferredEmit: function (callback, event, element) {
		if (this.isEventEmittedOnElement(event, element)) {
			callback();
		} else {
			element.one(event, callback);
		}
	},
};

export default new EventHelper();
