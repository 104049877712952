import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bas-group',
	templateUrl: './group.component.html',
	styleUrls: [ './group.component.less' ],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupComponent {
	@Input()
	name: string;

	@Input()
	hint: string;
}
