import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MsgKeyModule } from '../../translate/msg-key.module';
import { PanelComponent } from './panel.component';

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MsgKeyModule
	],
	declarations: [PanelComponent],
	exports: [PanelComponent]
})
export class PanelModule { }
