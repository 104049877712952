import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnchorStatusComponent } from './anchor-status.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		AnchorStatusComponent
	],
	exports: [
		AnchorStatusComponent
	]
})
export class AnchorStatusModule {}
