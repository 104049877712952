import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'navSearchHighlight',
	pure: true
})
export class NavSearchHighlightPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string, searchTerm: string): SafeHtml {
		const valueWithStrongs = value.replace(new RegExp(searchTerm, 'g'), `<strong>${searchTerm}</strong>`);
		return this.sanitizer.bypassSecurityTrustHtml(valueWithStrongs);
	}

}
