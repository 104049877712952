import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WebserviceExceptionService } from './webservice-exception.service';

@Injectable()
export class WebserviceExceptionInterceptor implements HttpInterceptor {
	constructor(private injector: Injector) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				catchError((error) => {
					if (error instanceof HttpErrorResponse && error.headers.get('content-type') === 'application/problem+json') {
						this.injector.get(WebserviceExceptionService)
							.handleError(error.error);
					}
					throw error;
				}),
			);
	}
}