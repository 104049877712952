import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserAttributePipe } from './user-attribute.pipe';
import { UserGroupPipe } from './user-group.pipe';
import { UserPermissionPipe } from './user-permission.pipe';
import { UserService } from './user.service';

@NgModule({
	imports: [
		HttpClientModule
	],
	declarations: [
		UserPermissionPipe,
		UserGroupPipe,
		UserAttributePipe,
	],
	exports: [
		UserPermissionPipe,
		UserGroupPipe,
		UserAttributePipe,
	]
})
export class UserModule {
	static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: UserModule,
			providers: [UserService]
		}
	}
}
