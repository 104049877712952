import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TooltipPosition, TooltipSpace } from './tooltip-position';

/**
 * @group Generic Compounds
 * @component Tooltip
 */
@Component({
	selector: '[bas-tooltip]',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
	/** Der Inhaltstext des Tooltips; kein HTML möglich */
	@Input('bas-tooltip')
	tooltip: string;

	@Input('tabbar')
	tabbar: boolean = true;
	/** Nur von View aus benutzt */
	showTooltipTopLeft = false;
	/** Nur von View aus benutzt */
	showTooltipBottomLeft = false;
	/** Nur von View aus benutzt */
	showTooltipBottomRight = false;
	/** Nur von View aus benutzt */
	showTooltip$: Observable<boolean>;
	/** Nur von View aus benutzt */
	hasMaxWidth = false;
	/** Nur von View aus benutzt */
	width = null;
	tooltipvisible: boolean = false;
	private maxWidth = 500;
	private showTooltipTrigger$: Subject<boolean> = new Subject();

	@ViewChild('tooltipContent', { static: false })
	private tooltipContent: ElementRef;
	@ViewChild('tooltipContainer', { static: false })
	private tooltipContainer: ElementRef;

	constructor() {
		this.showTooltip$ = this.showTooltipTrigger$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			tap(show => {
				this.width = show ? this.width : null;
				if (show) {
					this.calculateTooltipWidthAndPosition();
				}
			}));
	}

	showTooltip() {
		this.tooltipvisible = true;
		this.showTooltipTrigger$.next(this.tooltipvisible);
	}

	hideTooltip() {
		this.tooltipvisible = false;
		this.showTooltipTrigger$.next(this.tooltipvisible);
	}
	showHideTooltipOnEnter() {
		this.tooltipvisible = !this.tooltipvisible;
		this.showTooltipTrigger$.next(this.tooltipvisible);
	}
	onMouseEnter() {
		this.showTooltip();
	}

	onMouseLeave() {
		this.hideTooltip();
	}

	private calculateTooltipWidthAndPosition() {
		const tooltipPosition = this.getTooltipPosition();
		if (tooltipPosition.width === this.maxWidth) {
			this.width = null;
		} else {
			this.width = tooltipPosition.width;
		}

		this.showTooltipTopLeft = tooltipPosition.position === TooltipPosition.TOP_LEFT;
		this.showTooltipBottomLeft = tooltipPosition.position === TooltipPosition.BOTTOM_LEFT;
		this.showTooltipBottomRight = tooltipPosition.position === TooltipPosition.BOTTOM_RIGHT;
	}

	private getAvailableSpacesForPositions(): Array<TooltipSpace> {
		const container = this.tooltipContainer.nativeElement.getBoundingClientRect();
		const computedStyles = getComputedStyle(this.tooltipContent.nativeElement);
		const computedPseudoStyles = getComputedStyle(this.tooltipContent.nativeElement, '::before');
		const widthPx = computedStyles.getPropertyValue('width');

		let width = this.maxWidth;
		if (widthPx !== '0px') {
			width = parseFloat(widthPx);
			this.hasMaxWidth = this.width !== null || width >= this.maxWidth;
		}
		const paddingTop = parseFloat(computedStyles.getPropertyValue('padding-top'));
		const paddingBottom = parseFloat(computedStyles.getPropertyValue('padding-bottom'));
		const padding = paddingTop + paddingBottom;
		const arrowTopHeight = parseFloat(computedPseudoStyles.getPropertyValue('border-top-width'));
		const arrowBottomHeight = parseFloat(computedPseudoStyles.getPropertyValue('border-bottom-width'));
		const arrowHeight = arrowTopHeight + arrowBottomHeight;

		const containerX = container.left;
		const containerY = container.top;
		const tooltipHeight = parseFloat(computedStyles.getPropertyValue('height')) + padding + arrowHeight;
		const containerPaddingLeft = parseFloat(computedStyles.getPropertyValue('padding-left'));
		const containerPaddingRight = parseFloat(computedStyles.getPropertyValue('padding-right'));
		const containerPaddingX = containerPaddingLeft + containerPaddingRight;
		const maxX = document.body.clientWidth;
		const maxY = window.innerHeight;

		const maxWidthRight = Math.min(maxX - containerX - containerPaddingX, this.maxWidth, width);
		const maxWidthLeft = Math.min(containerX - containerPaddingX + container.width, this.maxWidth, width);

		const fitsY = containerY - tooltipHeight > 0;
		const fitsYToBottom = containerY + tooltipHeight <= maxY;

		let result: Array<TooltipSpace> = [];

		if (fitsY) {
			// Top
			result = [
				...result,
				{
					position: TooltipPosition.TOP_RIGHT,
					width: maxWidthRight
				},
				{
					position: TooltipPosition.TOP_LEFT,
					width: maxWidthLeft
				}
			];
		}
		if (fitsYToBottom) {
			// Bottom
			result = [
				...result,
				{
					position: TooltipPosition.BOTTOM_RIGHT,
					width: maxWidthRight
				},
				{
					position: TooltipPosition.BOTTOM_LEFT,
					width: maxWidthLeft
				}
			];
		}

		return result;
	}

	private getTooltipPosition(): TooltipSpace {
		const spaces = this.getAvailableSpacesForPositions();
		if (spaces.length === 0) {
			return {
				position: TooltipPosition.TOP_RIGHT,
				width: this.maxWidth
			};
		}
		const sortedSpaces = spaces.sort(this.sortSpaces);

		return sortedSpaces[0];
	}

	private sortSpaces(a: TooltipSpace, b: TooltipSpace): number {
		const difference = b.width - a.width;
		if (difference === 0) {
			return a.position - b.position;
		}

		return difference;
	}

	public getTabIndex() {
		return this.tabbar ? 0 : -1;
	}
}
