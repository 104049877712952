import { Action } from '@ngrx/store';
import { User } from '../../../../models/user';

export enum UserActionTypes {
	RefreshUser = '[User] Refresh User',
	AddUser = '[User] Add User',
	RemoveUser = '[User] Remove User',
	GetUser = '[User] Get User'
}

export class RefreshUserAction implements Action {
	readonly type: string = UserActionTypes.RefreshUser;
}

export class AddUserAction implements Action {
	readonly type: string = UserActionTypes.AddUser;

	constructor(public payload: User) { }
}

export class RemoveUserAction implements Action {
	readonly type: string = UserActionTypes.RemoveUser;
}

export class GetUserAction implements Action {
	readonly type: string = UserActionTypes.GetUser;
}


export type UserActions =
	RefreshUserAction
	| AddUserAction
	| RemoveUserAction
	| GetUserAction;
