import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,


	OnInit, Output,
	ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../shared/store/application-state';
import { NavigationItem } from '../../../../../models/navigationItem';
import { SetActiveNavigationItemAction } from '../../../../../store/navigation.actions';

@Component({
	selector: 'bas-nav-sub-item-level-1',
	templateUrl: './nav-sub-item-level-1.component.html',
	styleUrls: ['./nav-sub-item-level-1.component.less'],
	animations: [
		trigger('openState', [
			state('open', style({
				transform: 'scaleY(1) scaleX(1)'
			})),
			state('closed', style({
				transform: 'scaleY(0) scaleX(0)',
				display: 'none'
			})),

			transition('open => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => open', animate('100ms cubic-bezier(0.19, 1, 0.22, 1)'))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSubItemLevel1Component implements OnInit {

	@Input() item: NavigationItem;
	@Output() itemClick: EventEmitter<void> = new EventEmitter();

	@ViewChild('anchor', { static: false }) anchor: ElementRef;
	@ViewChild('menu', { static: false }) menu: ElementRef;

	state: 'closed' | 'open' = 'closed';
	hasFocus = false;
	openToLeft = false;

	constructor(private store: Store<ApplicationState>, private cdr: ChangeDetectorRef) {
	}

	hasChildren(): boolean {
		return this.item.children && this.item.children.length > 0;
	}

	ngOnInit(): void { }

	open() {
		const menu = this.menu.nativeElement as HTMLLIElement;
		const boundingClientRect = menu.getBoundingClientRect();

		this.openToLeft = boundingClientRect.left + (boundingClientRect.width * 2) > window.innerWidth;

		this.state = 'open';
		this.cdr.markForCheck();
	}

	close() {
		this.state = 'closed';
		this.cdr.markForCheck();
	}

	onClick() {
		this.close();
		this.itemClick.emit();
	}

	active() {
		if (!this.item.externalLink) {
			this.store.dispatch(new SetActiveNavigationItemAction(this.item.id));
		}
		this.onClick();
	}

	focus() {
		this.hasFocus = true;
	}

	blur() {
		this.hasFocus = false;
	}
}
