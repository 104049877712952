import { FormGroup, NgForm } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ControlErrors } from '../elements/error/models/errors';
import { MsgKeyService } from '../translate/msg-key.service';
import { TypedFormGroup } from '../validators/abstract-form-group';
import { markParents } from '../validators/form.utils';

export class ErrorHandling {
	static errorHandlingInstance: ErrorHandling;

	errorsWithoutInputField: Array<string> = [];

	constructor() {
		ErrorHandling.errorHandlingInstance = this;
	}

	public static addSingleErrorMsgKey(key: string, msgKey: string, form: NgForm, msgKeyService: MsgKeyService): void {
		msgKeyService.getMessageWithCache(msgKey)
			.subscribe(errorText => {
				this.handleErrors({ [key]: [errorText] }, form);
			});
	}

	public static addSingleError(key: string, errorText: string, form: NgForm): void {
		this.handleErrors({ [key]: [errorText] }, form);
	}

	public static handleErrors(errors: ControlErrors, form: NgForm): void {
		if (!ErrorHandling.errorHandlingInstance) {
			ErrorHandling.errorHandlingInstance = new ErrorHandling();
		}
		ErrorHandling.errorHandlingInstance.handleErrors(errors, form);
	}

	public static scrollToFirstError(): void {
		const error: HTMLElement = document.querySelector('.has-error') || document.querySelector('.hasErrors');
		if (error) {
			let parent = error.parentElement;
			while (parent) {
				if (parent.style.display === 'none') {
					parent.style.display = 'initial';
				}
				parent = parent.parentElement;
			}

			error.scrollIntoView();
			error.focus();
			window.scrollBy(0, -50);
		}
	}

	public static scrollToElement(element: HTMLElement | null): void {
		if (element) {
			if (!this.isInView(element, 0.9)) {
				element.scrollIntoView();
				window.scrollBy(0, -50);
			}
		} else {
			window.scroll(0, 0);
		}
	}

	private static isInView(element: HTMLElement, percentageRectangle: number = 1): boolean {

		const rect = element.getBoundingClientRect();

		const height = window.innerHeight || document.documentElement.clientHeight;
		const width = window.innerWidth || document.documentElement.clientWidth;
		return (
			rect.top >= (0 + ((1 - percentageRectangle) * height)) &&
			rect.left >= (0 + ((1 - percentageRectangle) * width)) &&
			rect.bottom <= height * percentageRectangle && /*or $(window).height() */
			rect.right <= width * percentageRectangle /*or $(window).width() */
		);
	}

	protected scrollToFirstError(): void {
		ErrorHandling.scrollToFirstError();
	}

	protected async handleErrors(
		errors: ControlErrors,
		form: NgForm | FormGroup | TypedFormGroup,
		messageService?: MsgKeyService,
	): Promise<void> {
		const selector = Object.keys(errors)
			.map(name => `*[name="${ name }"]`)
			.join(',');
		if (!selector) {
			window.logRemoteError('exception', { message: 'selector was empty' });
			return;
		}
		let firstErrorElement: HTMLElement = document.querySelector(selector);

		const combinedErrors: {
			[key: string]: Array<string>
		} = {};
		const errorNames = Object.keys(errors)
			.filter(key => {
				if (key.includes('#') && !form.controls[key]) {
					const namePrefix = key.split('#')[0];
					combinedErrors[namePrefix] = combinedErrors[namePrefix] || [];
					combinedErrors[namePrefix].push(key);
					return false;
				}
				return true;
			});

		Object.keys(combinedErrors)
			.forEach(name => {
				const control = form.controls[name];
				control.markAsTouched();

				const errorObject = {
					literal: true,
				};
				combinedErrors[name].forEach(key => {
					errorObject[key] = errors[key];
				});
				control.setErrors(errorObject, {
					emitEvent: true,
				});
			});

		for (const name of errorNames) {
			if (name !== 'length') {
				const errorsOfProperty = errors[name];
				const errorList: Array<string> = [];
				for (const error of errorsOfProperty) {
					if (typeof error === 'object') {
						if (!messageService) {
							throw new Error('missing msgKeyService');
						}
						const resolved = await lastValueFrom(messageService.getMessageWithCache(error.key));
						errorList.push(resolved);
					} else {
						errorList.push(error);
					}

				}
				let control = form.controls[name];
				if (!control && form instanceof NgForm) {
					control = form.control.get(name);
				}

				if (!control && name.includes('#') && errors) {
					const namePrefix = name.split('#')[0];
					control = form.controls[namePrefix];
					control.markAsTouched();

					const errorObject = {
						literal: true,
					};
					Object.keys(errors)
						.filter(key => key.startsWith(namePrefix))
						.forEach(key => {
							errorObject[key] = errors[key];
						});
					control.setErrors(errorObject, {
						emitEvent: true,
					});
					continue;
				}

				if (!control) {

					this.errorsWithoutInputField = [...this.errorsWithoutInputField, ...errorList];

					firstErrorElement = null;

					continue;
				}
				control.markAsTouched();
				control.updateValueAndValidity();
				control.setErrors({ 'literal': errorList });
				// update validity for groupDirectives (like tabs or panels(TODO panels))
				markParents(control, { updateValidity: true, selector: ctrl => ctrl['groupDirective'] });
			}

		}
		if (firstErrorElement) {
			this.scrollToElement(firstErrorElement);
		} else {
			this.scrollToFirstError();
		}
	}

	protected scrollToElement(element: HTMLElement | null): void {
		ErrorHandling.scrollToElement(element);
	}

}
