import { BreadcrumbsActions, BreadcrumbsActionTypes, AddBreadcrumbsAction } from '../actions/breadcrumbs.actions';
import { ApplicationState } from '../application-state';
import { BreadcrumbsState } from '../states/breadcrumbs.state';
import { Breadcrumb } from '../../generic-compounds/breadcrumb/models/breadcrumb';
import { createSelector } from '@ngrx/store';

const initialBreadcrumbState: BreadcrumbsState = {
	breadcrumbs: []
};

export function breadcrumbReducer(state: BreadcrumbsState = initialBreadcrumbState, action: BreadcrumbsActions): BreadcrumbsState {
	switch (action.type) {
		case BreadcrumbsActionTypes.AddBreadcrumbs: {
			const breadcrumbs = (action as AddBreadcrumbsAction).payload;
			if (breadcrumbs.length === 0) {
				console.warn(`Action ${action.type} called with no breadcrumbs.`);
			}
			const breadcrumEquals = (a: Breadcrumb, b: Breadcrumb) => a && b && a.url === b.url;
			const hasChanged = breadcrumbs.reduce((prev, item, index) => prev || !breadcrumEquals(item, state.breadcrumbs[index]), false);
			if (!hasChanged) {
				return state;
			}

			return {
				...state,
				breadcrumbs
			}
		}

		case BreadcrumbsActionTypes.RemoveBreadcrumbs: {
			return {
				...state,
				breadcrumbs: []
			};
		}

		default:
			return state;
	}
}

const findFirstBacklink = (breadcrumbs: Array<Breadcrumb>) => [...breadcrumbs].reverse()
	.slice(1)
	.find(breadcrumb => !breadcrumb.noUseForBacklink);

export namespace BreadcrumbsQuery {
	export const getBreadcrumbs = (state: ApplicationState) => state.breadcrumbsState.breadcrumbs;
	export const getBacklink = createSelector(getBreadcrumbs, breadcrumbs => findFirstBacklink(breadcrumbs));
}
