<a
		*ngIf="!item.parameters['external']; else external"
		[class.active]="active()"
		[class.defaultLink]="isDefaultLink"
		bas-anchor-status
		[parameters]="item.parameters"
		[routerLink]="item.url"
		(click)="setActive()"
>
	<span [innerHTML]="item.name || (item.nameKey | msgKey)"></span>
</a>

<ng-template #external>
	<a
			[class.active]="active()"
			[class.defaultLink]="isDefaultLink"
			bas-anchor-status
			[parameters]="item.parameters"
			[href]="item.url"
			target="_blank"
	>
		<span [innerHTML]="item.name || (item.nameKey | msgKey)"></span>
	</a>
</ng-template>
