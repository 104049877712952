import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ButtonModule} from '../../elements/button/button.module';
import {MsgKeyModule} from '../../translate/msg-key.module';
import {DialogDirective} from './dialog.directive';
import {DialogService} from './dialog.service';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MsgKeyModule,
		ButtonModule
	],
	declarations: [DialogDirective],
	exports: [DialogDirective]
})
export class DialogModule {
	static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: DialogModule,
			providers: [DialogService]
		};
	}
}
