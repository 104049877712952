import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Session } from '../../../models/session';
import { User } from '../../../models/user';
import { ApplicationState } from '../../shared/store/application-state';
import { UserQuery } from '../../shared/store/reducers/user.reducer';
import { UserProvider } from '../models/user-provider';

@Injectable()
export class UserService implements UserProvider {

	private static lastFetch = -1;
	private static userObjCache: User;

	public is(state: string, value: any): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => user.states[state] === value),
				first());
	}

	public isAttribute(attribute: string, value: any): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => user[attribute] === value),
				first());
	}

	public isAttributeNoSubAccount(attribute: string, value: string): Observable<boolean> {
		return this.http.get<string>(`rest/user/attributes/no-subaccount/${attribute}`)
			.pipe(map(attributeValue => attributeValue === value));
	}

	public hasState(state: string): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => !!user.states[state]),
				first());
	}

	public hasGroup(group: string): Observable<boolean> {
		return this.http.get<boolean>(`rest/user/groups/${group}`);
	}

	public has(perm: string): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => user.permissions.includes(perm)),
				first());
	}

	public any(perms: Array<string>): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => perms.some(perm => user.permissions.includes(perm))),
				first());
	}

	public isSubuser(): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => user.isSubuser),
				first());
	}

	public hasCountryCode(code: string): Observable<boolean> {
		return this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				map((user: User) => user.countryCode === code),
				first());
	}

	public hasAttribute(id: string): Observable<boolean> {
		return this.store.select(UserQuery.getSession)
			.pipe(
				filter(Boolean),
				map((session: Session) => !!session[id]),
				first());
	}

	public attribute(id: string): Observable<Array<string>> {
		return this.store.select(UserQuery.getSession)
			.pipe(
				filter(Boolean),
				map((session: Session) => session[id]),
				first());
	}

	public getUser(): Observable<User> {

		if (UserService.lastFetch > Date.now() - (5000) && UserService.userObjCache) {
			return of(UserService.userObjCache);
		}

		const flattenUser = (userProperties: Array<string>, response: object) =>
			(user, key) => userProperties.includes(key) ? ({ ...user, [key]: response[key] })
				: ({ ...user, states: { ...(user.states ? user.states : {}), [key]: response[key] } });

		const properties = [
			'accessGroup',
			'firstname',
			'surname',
			'username',
			'userMail',
			'confirmationMail',
			'phone',
			'origUserName',
			'userId',
			'uiLanguage',
			'formattingLocale',
			'countryCode',
			'basket',
			'isSubuser',
			'shoppingCartSize',
			'prerequisits',
			'formOfAddress'
		];

		return this.http.get('rest/user/attributes')
			.pipe(
				tap(this.addLocalePropertiesToBody),
				tap(this.addLoginForClassToBody),
				map(res => Object.keys(res)
					.reduce(flattenUser(properties, res), {} as User)),
				switchMap(user => {
					return this.http.get<Array<string>>('rest/user/rechte')
						.pipe(
							map(permissions => ({ ...user, permissions })),
							tap(userObj => {
								UserService.lastFetch = Date.now();
								UserService.userObjCache = userObj;
							})
						);
				})
			);
	}

	addLocalePropertiesToBody(properties) {
		const body = document.getElementsByTagName('body')[0];
		body.setAttribute('formattinglocale', properties['formattingLocale']);
		body.setAttribute('uilanguage', properties['uiLanguage']);
		$(document)
			.trigger('reinitLocaleHelper');
	}

	addLoginForClassToBody(properties) {
		const body = document.querySelector('body');
		if (!!properties['isLoginForActive']) {
			body.classList.add('changedBackground');
		} else {
			body.classList.remove('changedBackground');
		}
	}

	constructor(private http: HttpClient,
		private store: Store<ApplicationState>) {
	}
}
