import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, signal, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'bas-nav-search-field',
	templateUrl: './nav-search-field.component.html',
	styleUrls: ['./nav-search-field.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavSearchFieldComponent implements OnInit {
	term: FormControl = new FormControl();
	autoComplete = signal<Array<string>>(null);
	hasFocus = signal(false);
	current = signal<number>(null);
	initialTerm = signal<string>(null);

	@ViewChildren('seachTerm')
	private results: QueryList<ElementRef>;
	@ViewChild('autoCompleteContainer', { static: false })
	private autoCompleteContainer: ElementRef;
	private searchUrl = '/workflow.do?from=menu' +
		'&to=booking-choose' +
		'&byPath(navigation.currentNodeByPath)=/tree=booking/' +
		'&byPath(navigation.currentNode.tree.node(booking).filterValue(fulltextsearch_filter))=';

	constructor(
		private router: Router,
		private http: HttpClient,
	) {
	}

	ngOnInit() {
		this.term.valueChanges
			.pipe(
				debounceTime(400),
				distinctUntilChanged(),
				tap(() => this.current.set(null)),
				tap(term => this.initialTerm.set(term)),
				switchMap(term => this.getAutoCompletionResults(term)),
			)
			.subscribe(results => this.autoComplete.set([...results].slice(0, 5)));
	}

	getAutoCompletionResults(term: string): Observable<Array<string>> {
		const type = 'templatefulltextsearch';

		if (!term || term.length < 3) {
			return of([]);
		}

		return this.http.get<Array<string>>('externalservices/autocompletion.do', { params: { type, term } });
	}

	onArrowDown() {
		this.current.update(current => current < this.results.length ? current + 1 : 0);
		this.selectTerm();
	}

	onArrowUp() {
		this.current.update(current => current > 0 ? current - 1 : this.results.length);
		this.selectTerm();
	}

	selectTerm() {
		let newTerm;
		const result = this.results.toArray()[this.current() - 1];
		if (result) {
			const node = result.nativeElement;
			newTerm = node.getAttribute('data-term');

			const nodeOffset = node.offsetTop;
			const nodeHeight = node.offsetHeight;
			const containerScroll = this.autoCompleteContainer.nativeElement.scrollTop;
			const containerHeight = this.autoCompleteContainer.nativeElement.offsetHeight;

			if (nodeOffset + nodeHeight >= containerHeight + containerScroll) {
				node.scrollIntoView(false);
			} else if (nodeOffset < containerScroll) {
				node.scrollIntoView(true);
			}
		} else if (this.initialTerm()) {
			newTerm = this.initialTerm();
		}

		this.term.setValue(newTerm, { emitEvent: false });
	}

	search() {
		const term = this.term.value;
		this.term.setValue('');
		const url = this.searchUrl + encodeURIComponent(term || '');
		this.router.navigate([url]);
		this.hasFocus.set(false);
	}

	selectAutocomplete(term: string) {
		this.term.setValue(term);
		this.search();
	}
}
