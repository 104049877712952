import { NodeFunction } from '../../permissions/models/node-function';

export const getNodes: NodeFunction = (user, feature, bouncer, and, or, not, config) => [
	{
		nameKey: 'image.nav.login.alt',
		url: '/workflow.do?from=menu&to=home',
		id: 'home',
	},
	{
		treeName: 'booking',
		name: 'bookingTree',
		url: '',
		id: 'bookingTree',
		permission: user.isSubuser(),
	},
	{
		nameKey: 'text.mp',
		url: '/workflow.do?from=menu&to=marketingplaner',
		id: 'marketingplaner',
		permission: and(feature.has('marketingplaner'), user.has('marketingplaner'), user.isSubuser()),
	},
	{
		nameKey: 'image.nav.my.wbk.alt',
		url: '/workflow.do?from=menu&to=prefs',
		id: 'prefs',
		children: [
			{
				nameKey: 'image.metanav.preferences.alt',
				url: '/workflow.do?from=menu&to=preferences',
				id: 'preferences',
				parameters: {
					showInUserNav: true,
				},
			},

			{
				nameKey: 'image.nav.archive.alt',
				url: '/workflow.do?from=menu&to=archive',
				id: 'archive',
				permission: feature.has('archive'),
				parameters: {
					showInUserNav: true,
				},
			},
			{
				nameKey: 'text.select.prefs.budget',
				url: '/workflow.do?from=menu&to=budgetUser',
				id: 'budget',
				parameters: {
					showInUserNav: true,
				},
				permission: bouncer.check('wkzVisible'),

			},
			{
				nameKey: 'text.select.prefs.requests',
				url: '/workflow.do?from=menu&to=requestsUser',
				id: 'request',
				permission: bouncer.check('wkzVisible'),
			},
			{
				nameKey: 'navi.voucher.management.user',
				url: '/workflow.do?from=menu&to=voucher',
				id: 'voucher',
				parameters: {
					showInUserNav: true,
				},
				permission: bouncer.check('voucherVisible'),
			},
			{
				treeName: 'userpicture',
				name: 'userpictureTree',
				url: '',
				id: 'userpictureTree',
				permission: and(feature.has('userpicture'), user.has('userpicture')),
			},
			{
				nameKey: 'text.leistungsversprechen.meine',
				url: '/workflow.do?from=menu&to=meine-leistungsversprechen',
				id: 'leistungsversprechen',
				permission: user.isSubuser(),
			},
			{
				nameKey: 'text.koprauserprices',
				url: '/workflow.do?from=menu&to=kopra',
				id: 'kopra',
				permission: feature.has('kopra'),
			},
			{
				nameKey: 'kpi.dashboard.only.label',
				id: 'kpiDashboard',
				url: 'kpi-dashboard',
				query: { resetBreadcrumbs: true },
				permission: and(
					feature.has('showKpiDashboard'),
					not(
						feature.has('socialmedia')),
				),
			},
			{
				nameKey: 'kpi.some-dashboard.label',
				id: 'socialMediaDashboard',
				url: 'social-media/social-media-dashboard',
				query: { resetBreadcrumbs: true },
				permission:
					and(
						feature.has('socialmedia'),
						not(
							feature.has('showKpiDashboard')),
					),
			},
			{
				nameKey: 'kpi.overview-dashboards.label',
				id: 'dashboardsOverview',
				url: 'kpi-dashboard/overview',
				query: { resetBreadcrumbs: true },
				permission:
					and(
						feature.has('socialmedia'),
						and(
							feature.has('showKpiDashboard')),
					),
			},
			{
				nameKey: 'text.mailingManagement',
				url: '/workflow.do?from=menu&to=mailingManagement',
				id: 'mailingManagement',
				permission: feature.has('mailingManagement'),
			},
		],
	},
	{
		treeName: 'newsletterxslt',
		name: 'newsletterTree',
		url: '',
		id: 'newsletterTree',
		permission: and(feature.has('newsletterxslt'), bouncer.check('allowedNewsletterAccess')),
	},
	{
		nameKey: 'image.nav.admin.alt',
		url: 'workflow.do?from=menu&to=admin',
		id: 'admin',
		permission:
			user.any([
				'service',
				'konfiguration',
				'lektorat',
				'showReport',
				'showAllReports',
				'serviceWkz',
				'exportRealPriceWkz',
				'exportGutschriftenWkz',
				'approvalWkz',
			]),
		children: [

			{
				treeName: 'report',
				name: 'reportTree',
				url: '',
				id: 'reportTree',
				permission: user.any(['showReport', 'showAllReports']),
			},

			{
				nameKey: 'image.nav.service.alt',
				url: 'workflow.do?from=menu&to=service',
				id: 'service',
				permission: and(feature.has('service'), user.has('service')),
			},
			{
				nameKey: 'image.nav.konfiguration.alt',
				url: 'workflow.do?from=menu&to=konfiguration',
				id: 'konfiguration',
				permission: and(feature.has('konfiguration'), user.has('konfiguration')),
			},
			{
				nameKey: 'image.nav.wkz.admin.alt',
				url: 'workflow.do?from=menu&to=budgetAdmin',
				id: 'budgetAdmin',
				permission:
					and(
						feature.has('budget'),
						user.any([
							'serviceWkz', 'importRealPriceWkz', 'exportRealPriceWkz', 'exportGutschriftenWkz', 'approvalWkz',
							'importAutomaticSeaWkzRequest',
						]),
					),
			},
			{
				treeName: 'webtm',
				name: 'webtmTree',
				url: '',
				id: 'webtmTree',
				permission: and(feature.has('webtm'), user.has('adminWebTmGrid')),
			},
			{
				nameKey: 'image.nav.calculations.alt',
				url: '/workflow.do?from=menu&to=calculations',
				id: 'calculations',
				permission: and(feature.has('calculations'), user.has('listCalculation')),
			},
		],
	},
	/**
	 * Links shown in external-links
	 */

	{
		nameKey: 'image.metanav.iph.alt',
		url: 'https://cms3-author.audi.de',
		id: null,
		parameters: {
			showInAltNav: true,
			external: true,
		},
	},
	{
		nameKey: 'image.metanav.portal.alt',
		url: 'http://portal.cpn.vwg/portal.asp',
		id: null,
		parameters: {
			showInAltNav: true,
			external: true,
		},
	},
	{
		nameKey: 'image.nav.documents.alt',
		url: '/workflow.do?from=menu&to=documents',
		id: null,
		parameters: {
			showInAltNav: true,
		},
	},
	{
		nameKey: 'image.metanav.ciportal.alt',
		url: 'http://www.audi.com/ci',
		id: null,
		parameters: {
			showInAltNav: true,
			external: true,
		},
	},
];
