import { FieldComponent } from './field.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [ FieldComponent ],
	exports: [
		FieldComponent
	]
})
export class FieldModule {
}
