import {
	HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import UploadProgressHelper from 'helper/uploadProgressHelper';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WebHttpUrlEncodingCodec } from '../http/encoding/WebHttpUrlEncodingCodec';
import { LoggingService } from '../logging/logging.service';
import {
	ClearMainNavigationAction, HideMainNavigationAction, HideSideNavigationAction, RefreshNavigationAction, SetActiveNavigationItemAction,
	SetFixedWidthAction, SetResponsiveWidthAction, ShowMainNavigationAction, ShowSideNavigationAction,
} from '../navigation/store/navigation.actions';
import { ApplicationState } from '../shared/store/application-state';
import { UserQuery } from '../shared/store/reducers/user.reducer';

@Injectable()
export class StrutsService {

	private static FORM_CONTENT_TYPE_MULTIPART = 'multipart/form-data';

	isLogout = false;

	html$: Observable<string> = new Subject();
	backActionCodes = [
		'doCancelSubaccount', 'doAction.previous', 'doPrevious', 'doPrevious1', 'doActionNoCheck.restart',
		'doAction.cancel', 'doCancel', 'doAbort', 'doCancelEditComponent', 'doPreviousToIntroArticle',
	];

	private backButtonNode: HTMLAnchorElement;
	private uploadTickCount = 0;
	private uploadProgressHelper = UploadProgressHelper;
	private keepAliveTimer: Subscription;
	private isBrowserBack = false;

	static decodeURIComponent(component: string): string {
		const decoded = decodeURIComponent(component);
		if (component === decoded || decoded.includes('filterValue(fulltextsearch_filter)')) {
			return decoded;
		}
		return StrutsService.decodeURIComponent(decoded);
	}

	private closeListener = (event: BeforeUnloadEvent) => {
		// Don't show dialog on mailto-links
		if (document.activeElement instanceof HTMLAnchorElement && (document.activeElement as HTMLAnchorElement).href.startsWith('mailto:')) {
			return;
		}
		if (document.URL.includes('logout')) {
			return;
		}

		event.preventDefault();
		event.returnValue = '';
		return '';
	};

	constructor(
		private http: HttpClient,
		private router: Router,
		private store: Store<ApplicationState>,
		private zone: NgZone,
		private loggingService: LoggingService,
	) {

		this.store.select(UserQuery.getUser)
			.subscribe(user => user ? this.enableAccidentalExitPrevention() : this.disableAccidentalExitPrevention());

		window.$(document)
			.on('navigateTo', (event, url) => this.navigateTo(url));
		window.$(document)
			.on('downloadUrl', (event, url) => this.get(url));
		window.$(document)
			.on('formSubmit', (event, data) => this.onSubmitForm(data));
		window.onpopstate = () => this.isBrowserBack = true;
		HTMLFormElement.prototype._submit = HTMLFormElement.prototype.submit;
		const that = this; // Sad moment for all of us
		HTMLFormElement.prototype.submit = function () {
			const target = this.target;
			if (target) {
				const input = document.createElement('input');
				input.type = 'hidden';
				input.name = 'byPassFrameworkCheck';
				input.value = 'true';
				this.appendChild(input);
				this._submit();
			} else {
				that.post(this)
					.subscribe({
						next: res => (res instanceof HttpResponse) && that.handleResponse(res),
						error: error => that.handleError(error),
					});
			}
		};
	}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (this.isBrowserBack) {
			if (this.backButtonNode) {
				this.backButtonNode.click();
				this.backButtonNode = undefined;
			} else {
				this.ajaxPageLoaded();
			}
			this.isBrowserBack = false;
			return false;
		}

		return true;
	}

	keepAlive(time: number) {
		if (this.keepAliveTimer) {
			return;
		}

		this.keepAliveTimer = interval(time)
			.pipe(switchMap(() => this.http.get(window.context + '/keepAlive.do')))
			.subscribe();
	}

	setBackButton(node?: HTMLAnchorElement) {
		this.backButtonNode = node;
	}

	ajaxPageLoaded(node?: HTMLElement) {
		if (node) {
			window.$(document)
				.trigger('ajaxPageLoaded', node);
		} else {
			window.$(document)
				.trigger('ajaxPageLoaded');
		}
	}

	ajaxPageLoadStart(url?: string) {
		window.$(document)
			.trigger('ajaxPageLoadStart', url);
	}

	get(url: string) {
		const urlAction = url.substring(url.lastIndexOf('/') + 1);
		this.isLogout = urlAction === 'logout.do' || urlAction === 'logoutsso.do';
		// this.ajaxPageLoadStart(url);
		if (this.isLogout) {
			this.store.dispatch(new HideMainNavigationAction());
		}
		if (url == null) {
			window.logRemoteError('exceptiontrack', 'startsWith error strutsservice get');
		}
		if (url.startsWith('enc::')) {
			url = encodeURIComponent(url.substring(5));
		}
		const decodedUrl = decodeURIComponent(url);
		return this.http.get(decodedUrl, {
			headers: new HttpHeaders().set('Framework', 'angular')
				.set('AngularId', `${ angularId }`),
			responseType: 'blob',
			observe: 'response',
		})
			.subscribe({
				next: response => this.handleResponse(response),
				error: error => this.handleError(error),
			});
	}

	private navigateTo(url: string) {
		const decodedUrl = StrutsService.decodeURIComponent(url);
		const decodedCurrentUrl = StrutsService.decodeURIComponent(location.pathname);
		this.setBackButton();
		if (decodedCurrentUrl === decodedUrl) {
			this.get(url);
		} else {
			if (url.indexOf(window.context) !== -1) {
				url = url.substring(window.context.length);
			}
			// Please delete your InternetExplorer! Everytime a user opens an InternetExplorer, a developer cries.
			if (decodedUrl.match(/[äüöÄÜÖßẞ|]/)) {
				url = url.replace(/(?!\/).+/, u => encodeURIComponent(u));
			}
			this.router.navigateByUrl(url);
		}
	}

	private onSubmitForm(data: {
		ajaxReloadElement?: string,
		form: JQuery<HTMLFormElement>
	}) {
		data.form.trigger('beforeSend');
		this.post(data.form.get(0), data.ajaxReloadElement)
			.subscribe({
				next: (event) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.uploadTickCount++;
						const percentDone = Math.round(100 * event.loaded / event.total);
						this.uploadProgressHelper.process(event.loaded, event.total, percentDone, this.uploadTickCount);
					} else if (event instanceof HttpResponse) {
						this.handleResponse(event);
					}
				},
				error: error => this.handleError(error),
			});
	}

	private getFileName(res: HttpResponse<Blob>): string {
		const fileNameIdendifier = 'filename="';
		const contentDisposition = res.headers.get('Content-Disposition');
		if (!contentDisposition) {
			const indexOfLastSlash = res.url.lastIndexOf('/');
			if (indexOfLastSlash != -1) {
				const fileAndParams = res.url.substring(indexOfLastSlash + 1);
				const indexOfParams = fileAndParams.lastIndexOf('?');
				let filename;
				if (indexOfParams == -1) {
					filename = fileAndParams;
				} else {
					filename = fileAndParams.substring(0, indexOfParams);
				}
				return filename;
			}
		}
		const startIndex = contentDisposition.indexOf(fileNameIdendifier) + fileNameIdendifier.length;
		const fileNamePart = contentDisposition.substring(startIndex);
		const endIndex = fileNamePart.indexOf('\"');

		return fileNamePart.substring(0, endIndex);
	}

	private post(form: HTMLFormElement, ajaxReloadElement?: string): Observable<HttpEvent<Blob>> {
		this.isLogout = false;
		const body = this.getPostBody(form, ajaxReloadElement);
		const url = form.action;
		if (form.action.constructor.name === 'HTMLInputElement') {
			console.error('adding an input field with the name action ... not a good idea');
		}

		const req = new HttpRequest('POST', url, body, {
			reportProgress: true,
			headers: new HttpHeaders()
				.set('Framework', 'angular')
				.set('AngularId', `${ window.angularId }`),
			responseType: 'blob',
		});
		this.ajaxPageLoadStart(url);

		return this.http.request(req);
	}

	private getPostBody(form: HTMLFormElement, ajaxReloadElement?: string): FormData | HttpParams {
		const data = this.getDataFromForm(form);
		if (this.isFormMultipart(form) || this.containsFile(data)) {
			const formData = new FormData();
			if (ajaxReloadElement) {
				formData.append('ajaxReloadElement', ajaxReloadElement);
			}
			data.forEach((value, name) => {
				value.forEach(val => formData.append(name, val));
			});
			return formData;
		}
		let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
		if (ajaxReloadElement) {
			params = params.append('ajaxReloadElement', ajaxReloadElement);
		}
		data.forEach((value, name) => {
			value.forEach(val => params = params.append(name, val as string));
		});

		return params;
	}

	private isFormMultipart(form: HTMLFormElement): boolean {
		return (form.getAttribute('enctype') === StrutsService.FORM_CONTENT_TYPE_MULTIPART ||
			form.getAttribute('encoding') === StrutsService.FORM_CONTENT_TYPE_MULTIPART);
	}

	private containsFile(data: Map<string, Array<string | File>>): boolean {
		return Array.from(data.values())
			.some(values => values.some(value => value instanceof File));
	}

	private getDataFromForm(form: HTMLFormElement): Map<string, Array<string | File>> {
		const elements = form.querySelectorAll('input[name]:not([disabled]), select[name]:not([disabled]), textarea[name]:not([disabled])');
		const params = new Map<string, Array<string | File>>();
		for (let i = 0; i < elements.length; ++i) {
			const element = elements[i] as HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement;
			// Edge apprently removes the disabled-attribute after rendering so we have to check on the property here again
			if (!element.disabled) {
				const name = element.getAttribute('name');
				const values = this.getValueFromFormElement(element);
				if (values) {
					const value = params.has(name) ? [...params.get(name), ...values] : values;
					params.set(name, value);
				}
			}
		}

		return params;
	}

	private getValueFromFormElement(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): Array<string> | Array<File> | null {
		if (this.isHTMLElement<HTMLSelectElement>('select', element)) {
			if (element.selectedIndex === -1) {
				return null;
			}
			const options = Array.from(element.getElementsByTagName('option'))
				.filter(option => option.selected)
				.map(option => option.value);

			return options.length === 0 && !element.multiple ? [''] : options;
		}
		if (this.isHTMLElement<HTMLInputElement>('input', element)) {
			if (element.type === 'file') {
				const values = [];
				const length = element.files.length;
				for (let i = 0; i < length; ++i) {
					values.push(element.files.item(i));
				}
				if (length === 0) {
					values.push('');
				}
				return values;
			} else if (element.type === 'checkbox' || element.type === 'radio') {
				return element.checked ? [element.value] : [];
			}
		}
		return [element.value];
	}

	private isHTMLElement<T extends HTMLElement>(tagname: string, el: HTMLElement): el is T {
		return el.tagName.toLowerCase() === tagname.toLowerCase();
	}

	private handleResponse(res: HttpResponse<Blob>) {
		const hasRedirect = res.headers.has('redirectUrl');
		if (hasRedirect) {
			const redirectUrl = res.headers.get('redirectUrl');
			const isExternal = /^https?:\/\//.test(redirectUrl);
			if (isExternal) {
				location.replace(redirectUrl);
			} else {
				this.handleHtmlResponse(res);
				this.router.navigateByUrl(redirectUrl);
			}
			return;
		}
		const blob = res.body;
		const url = URL.createObjectURL(blob);
		if (!blob.type) {
			window.logRemoteError('exceptiontrack', {
				message: 'startsWith blobtype is undeifned #fixed if no startsWith of undefined',
				requestBody: blob,
				blobType: blob.constructor.name,
			});

		}
		if ((!blob.type && blob.constructor.name === 'String') || blob.type.startsWith('text/html')) {
			this.handleHtmlResponse(res);
		} else {
			// Download
			this.handleDownloadResponse(res);
		}

		if (res.headers.has('keepAliveTimer')) {
			this.keepAlive(Number(res.headers.get('keepAliveTimer')));
		}
	}

	private handleHtmlResponse(res: HttpResponse<Blob>) {
		this.zone.runOutsideAngular(() => {
			this.zone.run(() => {
				if (res.headers.has('Navigation-Changed')) {
					this.store.dispatch(new ClearMainNavigationAction());
					this.store.dispatch(new RefreshNavigationAction());
				}
				if (res.headers.has('Navigation-Main-Visible')) {
					const showMainNavigation = res.headers.get('Navigation-Main-Visible') === 'true';
					const action = showMainNavigation ? new ShowMainNavigationAction() : new HideMainNavigationAction();
					this.store.dispatch(action);
				}
				if (res.headers.has('Navigation-Side-Visible')) {
					const showSideNavigation = res.headers.get('Navigation-Side-Visible') === 'true';
					const action = showSideNavigation ? new ShowSideNavigationAction() : new HideSideNavigationAction();
					this.store.dispatch(action);
				}
				if (res.headers.has('Is-Page-Responsive')) {
					const isPageResponsive = res.headers.get('Is-Page-Responsive') === 'true';
					const action = isPageResponsive ? new SetResponsiveWidthAction() : new SetFixedWidthAction();
					this.store.dispatch(action);
				}
				if (res.headers.has('Navigation-Current-Id')) {
					const activeNavigationId = res.headers.get('Navigation-Current-Id');
					this.store.dispatch(new SetActiveNavigationItemAction(activeNavigationId));
				}
			});
		});

		let blob = res.body;

		if ((!blob.type && blob.constructor.name === 'String')) {
			blob = new Blob([blob], { type: 'text/html' });
		}

		const url = URL.createObjectURL(blob);

		this.http.get(url, {
			responseType: 'text',
		})
			.subscribe(text => {
				(<Subject<string>>this.html$).next(text);
				URL.revokeObjectURL(url);
			});
	}

	private handleDownloadResponse(res: HttpResponse<Blob>) {
		window.skipPanelCheck = true;
		this.ajaxPageLoaded();

		const fileName = this.getFileName(res);
		const blob = res.body;
		const url = URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}

	private handleError(err: HttpErrorResponse) {
		const errorPage = '/strutsreturn.do?forwardTo=error-default-message';
		try {
			if (err.status === 0) {
				this.loggingService.log({
					name: err.name,
					message: err.message + ' Vermutlich lag ein Verbindungsabbruch vor.',
				}, 'Angular_Fehler');
			} else {
				this.loggingService.logError(err, '', {
					statusCode: err.status,
					statusText: err.statusText,
					headers: `${ err.headers }`,
					errorText: `${ err.error }`,
					errorType: `${ err.type }`,
				});

			}
		} catch (e) {
			// es steht keine Verbindung zum Server -> wir machen nichts
		}
		if (err.headers.has('WWW-Authenticate')) {
			const authHeader = err.headers.get('WWW-Authenticate');
			if (err.status === 401 && authHeader.split(' ')[0] === 'FORM') {
				return;
			}
		}
		if (err.status >= 500 || err.url.indexOf(errorPage) > 0) {
			this.router.navigateByUrl('error-fallback');
			return;
		}
		this.setBackButton();
		this.router.navigateByUrl(errorPage);
	}

	enableAccidentalExitPrevention(): void {
		window.addEventListener('beforeunload', this.closeListener);
	}

	disableAccidentalExitPrevention(): void {
		window.removeEventListener('beforeunload', this.closeListener);
	}
}
