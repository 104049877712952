import { Action } from '@ngrx/store';
import { Basket } from '../models/basket';
import { NavigationItem } from '../models/navigationItem';

export enum NavigationActionTypes {
	RefreshNavigation = '[Navigation] Refresh Navigation',
	AddAjaxRotator = '[Navigation] Add AjaxRotator',

	RemoveAjaxRotator = '[Navigation] Remove AjaxRotator',
	ShowMainNavigation = '[Navigation] Show main navigation',
	HideMainNavigation = '[Navigation] Hide main navigation',
	SetMainNavigation = '[Navigation] Set main navigation visibility',
	ShowSideNavigation = '[Navigation] Show side navigation',
	HideSideNavigation = '[Navigation] Hide side navigation',
	SetResponsiveWidth = '[Navigation] Set resposive width',
	SetFixedWidth = '[Navigation] Set fixed width',
	AddMainNavigationItems = '[Navigation] Add to main navigation',
	SetActiveNavigationItem = '[Navigation] Set active item',
	ClearMainNavigationAction = '[Navigation] Clear main navigation',
	ClearSideNavigationAction = '[Navigation] Clear side navigation',
	ShowNavigationHeader = '[Navigation] Show header navigation',
	HideNavigationHeader = '[Navigation] Hide header navigation',
	ShowNavigationFooter = '[Navigation] Show footer navigation',
	HideNavigationFooter = '[Navigation] Hide footer navigation',
	ShowMobileNavigation = '[Navigation] Show mobile navigation',
	HideMobileNavigation = '[Navigation] Hide mobile navigation',

	RefreshBasketAction = '[Navigation] Refresh basket',
	SetBasketAction = '[Navigation] Set basket',
	RemoveBasketAction = '[Navigation] Remove basket'
}

export class RefreshNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.RefreshNavigation;
}

export class AddAjaxRotatorAction implements Action {
	readonly type: string = NavigationActionTypes.AddAjaxRotator;
}

export class RemoveAjaxRotatorAction implements Action {
	readonly type: string = NavigationActionTypes.RemoveAjaxRotator;
}

export class ShowMainNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.ShowMainNavigation;
}

export class HideMainNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.HideMainNavigation;
}

export class SetMainNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.SetMainNavigation;

	constructor(public payload: boolean) { }
}

export class ShowSideNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.ShowSideNavigation;
}

export class HideSideNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.HideSideNavigation;
}

export class SetResponsiveWidthAction implements Action {
	readonly type: string = NavigationActionTypes.SetResponsiveWidth;
}

export class SetFixedWidthAction implements Action {
	readonly type: string = NavigationActionTypes.SetFixedWidth;
}

export class AddMainNavigationItemsAction implements Action {
	readonly type: string = NavigationActionTypes.AddMainNavigationItems;

	constructor(public payload: Array<NavigationItem>) {
	}
}

export class SetActiveNavigationItemAction implements Action {
	readonly type: string = NavigationActionTypes.SetActiveNavigationItem;

	constructor(public payload: string) {
	}
}

export class RefreshBasketAction implements Action {
	readonly type: string = NavigationActionTypes.RefreshBasketAction;
}

export class SetBasketAction implements Action {
	readonly type: string = NavigationActionTypes.SetBasketAction;

	constructor(public payload: Basket) {
	}
}

export class RemoveBasketAction implements Action {
	readonly type: string = NavigationActionTypes.RemoveBasketAction;
}

export class ClearMainNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.ClearMainNavigationAction;
}

export class ClearSideNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.ClearSideNavigationAction;
}

export class ShowNavigationHeaderAction implements Action {
	readonly type: string = NavigationActionTypes.ShowNavigationHeader;
}

export class HideNavigationHeaderAction implements Action {
	readonly type: string = NavigationActionTypes.HideNavigationHeader;
}

export class ShowNavigationFooterAction implements Action {
	readonly type: string = NavigationActionTypes.ShowNavigationFooter;
}

export class HideNavigationFooterAction implements Action {
	readonly type: string = NavigationActionTypes.HideNavigationFooter;
}

export class ShowMobileNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.ShowMobileNavigation;
}

export class HideMobileNavigationAction implements Action {
	readonly type: string = NavigationActionTypes.HideMobileNavigation;
}

export type NavigationActions =
	RefreshNavigationAction
	| AddAjaxRotatorAction
	| RemoveAjaxRotatorAction
	| ShowMainNavigationAction
	| HideMainNavigationAction
	| SetResponsiveWidthAction
	| SetFixedWidthAction
	| AddMainNavigationItemsAction
	| SetActiveNavigationItemAction
	| SetMainNavigationAction
	| RefreshBasketAction
	| SetBasketAction
	| RemoveBasketAction
	| ClearMainNavigationAction
	| ClearSideNavigationAction
	| ShowNavigationHeaderAction
	| HideNavigationHeaderAction
	| ShowNavigationFooterAction
	| HideNavigationFooterAction
	| ShowMobileNavigationAction
	| HideMobileNavigationAction;
