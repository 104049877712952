import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

export enum LogLevel {
	Info = 'info',
	Error = 'error'
}

@Injectable()
export class LoggingService {

	private endpoint = 'rest/clientlog/';
	private errortype = 'Angular_Fehler';

	constructor(private http: HttpClient, router: Router) {
		router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.log({
					'url': event.url,
				}, 'routing');
			});
	}

	log(message: { [key: string]: string }, type: String) {
		this.http.post(this.endpoint + LogLevel.Info + '/' + type, message)
			.pipe(catchError(() => of(null)))
			.subscribe();
	}

	logError(error: Error | HttpErrorResponse, additionalErrorMessage: string = '',
	         additionalProperties?: { [key: string]: string | number },
	) {
		const json: { [key: string]: string } = {
			name: error.name,
			message: error.message,
			additionalmessage: additionalErrorMessage,
			origin_stack: new Error().stack,
			currentUrl: location.href,
		};
		try {
			if (typeof error === 'string') {
				json.message = error;
			} else if (typeof error === 'object' && 'status' in error) {
				json.httpStatus = `${ error.status }`;
				json.httpStatusText = `${ error.statusText }`;
				json.httpUrl = `${ error.url }`;
			} else {
				json.stack = error.stack;
			}
		} catch (e) {

		}

		if (additionalProperties) {
			Object.keys(additionalProperties)
				.forEach(i => {
					json[i] = `${ additionalProperties[i] }`;
				});
		}

		this.http.post(this.endpoint + LogLevel.Error + '/' + this.errortype, json)
			.pipe(catchError(() => of(null)))
			.subscribe();
	}

}
