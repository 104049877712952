<input type="text"
       (focus)="hasFocus.set(true)"
       (blur)="hasFocus.set(false)"
       (keydown.enter)="search()"
       (keydown.arrowdown)="onArrowDown()"
       (keydown.arrowup)="onArrowUp()"

       [formControl]="term"
       [title]="'label.searchfield' | msgKey"
       [placeholder]="'label.searchfield' | msgKey">

<div class="searchfieldTrigger" [title]="'button.search.alt' | msgKey" (click)="search()"></div>

<div
		*ngIf="hasFocus() && autoComplete()?.length"
		#autoCompleteContainer
		class="autocomplete-result"
		(mousedown)="hasFocus.set(true)"
>
	<li *ngFor="let term of autoComplete(); let i = index"
	    #seachTerm
	    (mousedown)="selectAutocomplete(term)"
	    [class.focus]="current() === i + 1"
	    [attr.data-term]="term"
	    [innerHTML]="term | navSearchHighlight:initialTerm()"
	></li>
</div>
