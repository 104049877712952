import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { merge, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { MsgKeyService } from './msg-key.service';

export class BaseMsgKeyPipe {
	protected translate$ = of(true);

	constructor(
		private router: Router,
		protected ref: ChangeDetectorRef,
		protected service: MsgKeyService
	) {
		this.translate$ = merge(
			this.translate$,
			this.router.events.pipe(
				filter(event => event instanceof NavigationStart),
				map((event: NavigationStart) => event.url),
				map(url => this.decodeUriComponent(url)),
				map(url => url.indexOf('attribute(de.brandad.debug.showkeys)=yes') === -1))
		);
	}

	protected decodeUriComponent(comp: string): string {
		const decodedComp = decodeURIComponent(comp);
		return decodedComp === comp ? decodedComp : this.decodeUriComponent(decodedComp);
	}

	protected resolver(key: string, replacements?: object[]): Observable<string> {
		return this.service.getMessage(key, replacements);
	}
}

@Pipe({
	name: 'msgKey',
	pure: false
})
export class MsgKeyPipe extends BaseMsgKeyPipe implements PipeTransform {

	private message: string;
	private lastMessageKey: string;
	lastReset = -1;

	constructor(
		router: Router,
		ref: ChangeDetectorRef,
		service: MsgKeyService
	) {
		super(router, ref, service);
	}

	transform(key: string, replacements?: Array<object>): string {
		if (MsgKeyService.showKeys) {
			return `[${key}]`;
		}
		if (this.lastMessageKey === key && this.lastReset === MsgKeyService.getLastReset()) {
			return this.message;
		}
		this.lastMessageKey = key;
		this.resolveMessageKey(key, replacements)
			.pipe(
				filter(() => key === this.lastMessageKey)
			) // Only emit changes when the messageKey has not changed
			.subscribe(message => this.onMessageResolve(message));
		return this.message;
	}

	private onMessageResolve(message: string): void {
		this.message = message;
		this.lastReset = MsgKeyService.getLastReset();
		this.ref.markForCheck();
	}

	private resolveMessageKey(key: string, replacements?: Array<object>): Observable<string> {
		return this.translate$
			.pipe(
				switchMap(translate => translate ? this.resolver(key, replacements) : of(`[${key}]`)),
				take<string>(1)
			);
	}

}

@Pipe({
	name: 'msgKeyHtml',
	pure: false
})
export class MsgKeyHtmlPipe extends MsgKeyPipe implements PipeTransform {

	constructor(
		router: Router,
		ref: ChangeDetectorRef,
		service: MsgKeyService
	) {
		super(router, ref, service);
	}

	protected resolver(key: string, replacements: object[]): Observable<string> {
		return this.service.getMessageHtml(key, replacements);
	}
}

@Pipe({
	name: 'msgKeyHtmlCached',
	pure: false
})
export class MsgKeyHtmlCachedPipe extends MsgKeyPipe implements PipeTransform {

	constructor(
		router: Router,
		ref: ChangeDetectorRef,
		service: MsgKeyService
	) {
		super(router, ref, service);
	}

	protected resolver(key: string, replacements: object[]): Observable<string> {
		return this.service.getMessageHtmlCached(key, replacements);
	}
}

@Pipe({
	name: 'msgKeyExists',
	pure: false
})
export class MsgKeyExistsPipe extends BaseMsgKeyPipe implements PipeTransform {

	private messageExists: boolean;
	private lastMessageKey: string;

	constructor(
		router: Router,
		ref: ChangeDetectorRef,
		service: MsgKeyService
	) {
		super(router, ref, service);
	}

	transform(key: string): boolean {
		if (this.lastMessageKey === key) {
			return this.messageExists;
		}
		this.lastMessageKey = key;
		this.resolveMessageKey(key)
			.pipe(filter(() => key === this.lastMessageKey)) // Only emit changes when the messageKey has not changed
			.subscribe(messageExists => this.onMessageResolve(messageExists));

		return this.messageExists;
	}

	private onMessageResolve(messageExists: boolean): void {
		this.messageExists = messageExists;
		this.ref.markForCheck();
	}

	private resolveMessageKey(key: string): Observable<boolean> {
		return this.translate$
			.pipe(
				switchMap(translate => this.resolver(key)),
				map(message => message.charAt(0) !== '['),
				take<boolean>(1)
			);
	}
}

@Pipe({
	name: 'msgKeyCached',
	pure: false
})
export class MsgKeyCachePipe extends MsgKeyPipe implements PipeTransform {

	constructor(
		router: Router,
		ref: ChangeDetectorRef,
		service: MsgKeyService
	) {
		super(router, ref, service);
	}

	resolver(key: string): Observable<string> {
		return this.service.getMessageWithCache(key);
	}

}
