import configuration from 'globalConfiguration';
import composer from 'helper/htmlComposer';

function UploadProgressHelperBase() {
	this.active = false;
	this.progressMonitor = $();
	this.rotatorClone = $();
	this.overlay = $();
	this.configuration = configuration.AjaxRotator.progressMonitor;

	this.progressMonitorData = {
		current: 0,
		delimiter: this.configuration.percentageConf.delimiter,
		total: 100,
	};

	this.init();
}

UploadProgressHelperBase.prototype = {

	init: function () {
	},

	start: function () {
		this.active = true;
		this.hijackAjaxOverlay();
	},

	stop: function () {
		this.active = false;
		this.progressMonitorData.current = 0;
	},

	remove: function () {
		this.progressMonitor.replaceWith(this.rotatorClone);
		this.progressMonitor.remove();
	},

	hijackAjaxOverlay: function () {
		this.overlay = $('.overlay.ajax');
		var rotator = this.overlay.find(this.configuration.rotatorSelector),
			progressMonitor = this.createProgressMonitor();

		this.rotatorClone = rotator;
		rotator.replaceWith(progressMonitor);
	},

	createProgressMonitor: function () {
		var progressMonitor = composer.generateDiv('progressMonitor upload');

		if (this.configuration.percentageConf.show) {
			this.createPercentage(progressMonitor);
		}
		if (this.configuration.animationConf.show) {
			this.createAnimation(progressMonitor);
		}

		this.progressMonitor = $(progressMonitor);

		return this.progressMonitor;
	},

	createPercentage: function (progressMonitor) {
		var container = composer.generateDiv('progressPercentageContainer'),
			current = composer.generateText(this.progressMonitorData.current, [
				[
					'class', 'current',
				],
			]),
			delimiter = composer.generateText(this.configuration.percentageConf.delimiter, [
				[
					'class', 'delimiter',
				],
			]),
			total = composer.generateText(this.progressMonitorData.total, [
				[
					'class', 'total',
				],
			]);

		current.text(this.progressMonitorData.current);
		delimiter.text(this.progressMonitorData.delimiter);
		container.append(current)
			.append(delimiter);
		if (this.configuration.percentageConf.showTotal) {
			total.text(this.progressMonitorData.total);
			container.append(total);
		}
		progressMonitor.append(container);
	},

	createAnimation: function (progressMonitor) {
		var animationContainer;
		if (this.configuration.animationConf.mode == 'circle') {
			progressMonitor.addClass('mode-circle');
			animationContainer = this.generateAnimationCircle();
		} else if (this.configuration.animationConf.mode == 'custom') {
			progressMonitor.addClass('mode-custom');
			animationContainer = this.generateAnimationCustom();
		} else {
			animationContainer = this.generateAnimationBar();
		}
		progressMonitor.append(animationContainer);
	},

	generateAnimationCustom: function () {
		var animationContainer;
		this.customAnimationData = {
			customAnimationContainer: composer.generateDiv('customAnimationContainer'),
			customAnimation: composer.generateDiv('step-0', 'customAnimation'),
			steps: Math.floor((100 / this.configuration.animationConf.customAnimationConf.loops) / this.configuration.animationConf.customAnimationConf.steps),
			currentStep: 0,
		};
		animationContainer = this.customAnimationData.customAnimationContainer;
		animationContainer.append(this.customAnimationData.customAnimation);
		return animationContainer;
	},

	generateAnimationCircle: function () {
		var radius = 8,
			circumference = 2 * radius * Math.PI;
		var svg = composer.generateSVG('radial-progress'),
			progressCircle = composer.generateSVGCircle('radial-progress-circle', (radius / 2) + 'em', (radius / 2) + 'em', 'transparent');
		svg.appendChild(progressCircle);
		progressCircle.setAttribute('r', radius + 'em');
		progressCircle.setAttribute('stroke-dasharray', circumference + 'em');
		progressCircle.setAttribute('stroke-dashoffset', circumference + 'em');
		return $(svg);
	},

	generateAnimationBar: function () {
		var animationContainer = composer.generateDiv('progressBarContainer');
		animationContainer.append(composer.generateDiv('progressBar'));
		return animationContainer;
	},

	refresh: function (percentComplete) {
		this.progressMonitorData.current = percentComplete;
		if (this.configuration.percentageConf.show) {
			this.progressMonitor.find('.current')
				.text(percentComplete);
		}
		if (this.configuration.animationConf.show) {
			if (this.configuration.animationConf.mode == 'circle') {
				this.updateAnimationCircle(percentComplete);
			} else if (this.configuration.animationConf.mode == 'custom') {
				this.updateAnimationCustom(percentComplete);
			} else {
				this.updateAnimationBar(percentComplete);
			}
		}
	},

	updateAnimationCircle: function (percentComplete) {
		var background = this.progressMonitor.find('.radial-progress-circle'),
			dashArr = background.attr('stroke-dasharray');
		background.attr('stroke-dashoffset', ((parseFloat(dashArr) / 100) * (100 - percentComplete)) + 'em');
	},

	updateAnimationBar: function (percentComplete) {
		this.progressMonitor.find('.progressBar')
			.css('width', percentComplete + '%');
	},

	updateAnimationCustom: function (percentComplete) {
		var doStepLoop = percentComplete % (100 / this.configuration.animationConf.customAnimationConf.loops) == 0,
			doChangeLoop = percentComplete % this.customAnimationData.steps == 0;
		this.customAnimationData.customAnimation.css({
			'left': percentComplete + this.configuration.animationConf.customAnimationConf.offset + '%',
		});

		if (percentComplete != 100) {
			if (doStepLoop) {
				this.customAnimationData.currentStep = 0;
			}
			if (doChangeLoop) {
				this.customAnimationData.customAnimation.removeClass();
				this.customAnimationData.currentStep = this.customAnimationData.currentStep + 1 == this.configuration.animationConf.customAnimationConf.steps
					? 1
					: this.customAnimationData.currentStep += 1;
				this.customAnimationData.customAnimation.addClass('step-' + this.customAnimationData.currentStep);
			}
		} else {
			this.customAnimationData.customAnimation.removeClass()
				.addClass('step-0');
		}
	},

	process: function (position, total, percentComplete, tickCount) {
		if ((tickCount === 5 && percentComplete < 15) || this.active) {
			if (!this.active) {
				this.start();
			}
			if (percentComplete != this.progressMonitorData.current) {
				this.refresh(percentComplete);
				if (percentComplete == 100) {
					this.stop();
					this.remove();
				}
			}
		}
	},
};

export default new UploadProgressHelperBase();
