<div>
	<div class="tooltip-container"
		 #tooltipContainer>
		<div class="tooltip-content"
			 #tooltipContent
			 (mouseenter)="showTooltip()"
			 (mouseleave)="hideTooltip()"
			 [style.width.px]="width"
			 [ngClass]="{
		     'has-max-width': hasMaxWidth,
		     'show-tooltip': showTooltip$ | async,
		     'show-top-left': showTooltipTopLeft,
		     'show-bottom-left': showTooltipBottomLeft,
		     'show-bottom-right': showTooltipBottomRight }">
			<ng-content select="bas-tooltip-content"></ng-content>
			<span [innerHTML]="tooltip"></span>
		</div>
	</div>
	<div (mouseenter)="onMouseEnter()"
		 (mouseleave)="onMouseLeave()"
		 tabindex={{getTabIndex()}}
		 (keydown.enter)="showHideTooltipOnEnter()"
		 (focusout)="hideTooltip()">
		<ng-content></ng-content>
	</div>
</div>