import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Pipe({
	name: 'userPermission'
})
export class UserPermissionPipe implements PipeTransform {

	constructor(@Inject(UserService) private user: UserService) { }

	transform(permission: string): Observable<boolean> {
		return this.user.has(permission);
	}

}
