import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import errorHandler from 'handler/handler.error';
import { PanelComponent } from '../../generic-compounds/panel/panel.component';
import { MsgKeyService } from '../../translate/msg-key.service';
import { config } from './config/error.config';
import { ERROR_CONTAINER } from './error-container.token';
import { ErrorComponent } from './error.component';
import { ErrorOptions } from './models/error-options';
import { ERROR_TYPE } from './models/error-type';

interface Error {
	tooltip?: JQuery<HTMLInputElement>;
	viewContainerRef: ViewContainerRef;
}

@Injectable()
export class ErrorService {

	private errors: {
		[key: number]: Error
	} = {};
	private incrementedId = 1;
	private errorHandler = errorHandler;

	constructor(private msgKeyService: MsgKeyService) {
	}

	hasError(id: number): boolean {
		return id && !!this.errors[id];
	}

	hideError(id: number) {
		if (this.hasError(id)) {
			const instances: Error = this.errors[id];
			if (instances.tooltip) {
				this.errorHandler.removeError(instances.tooltip);
			}
			const panelComponent: PanelComponent = instances.viewContainerRef?.injector.get(PanelComponent, null, { optional: true });
			const errorViewRef: ViewContainerRef = instances.viewContainerRef?.injector.get(ERROR_CONTAINER, null, { optional: true });
			if (panelComponent) {
				panelComponent.hasError = false;
				panelComponent.cdr.markForCheck();
			}
			if (errorViewRef) {
				errorViewRef.clear();
			}
		}
		this.errors[id] = null;
	}

	showErrorOnContainerRef(
		options: ErrorOptions | NgControl,
		viewContainerRef?: ViewContainerRef,
		type: ERROR_TYPE = config.default_type,
	) {
		return this.showError(viewContainerRef.element.nativeElement, options, viewContainerRef, type);
	}

	showError(
		nativeElement: HTMLElement,
		options: ErrorOptions | NgControl,
		viewContainerRef?: ViewContainerRef,
		type: ERROR_TYPE = config.default_type,
	) {
		if (type === ERROR_TYPE.BLOCK && !viewContainerRef) {
			throw Error('No ViewContainerRef provided, but ErrorType: Block!');
		}
		const literalErrorKey = 'literal';
		const literalErrors: string | string[] = options.errors[literalErrorKey] || [];
		const genericErrorName: string = nativeElement.getAttribute('data-generic-error-name');
		const id: number = ++this.incrementedId;
		const errorKeys = Object.keys(options.errors)
			.filter(error => error !== literalErrorKey)
			.reduce((errors, error) => {
				const errorKey = `${ genericErrorName || options.name }.error.${ error }`;
				const errorReplacement = Array.isArray(options.errors[error]) ? options.errors[error] : [];
				return {
					...errors,
					[errorKey]: errorReplacement,
				};
			}, {});

		const errorObject = {
			viewContainerRef,
			tooltip: null,
		};
		const panelComponent: PanelComponent = viewContainerRef?.injector.get(PanelComponent, null, { optional: true });
		if (panelComponent) {
			panelComponent.hasError = true;
		}

		if ('cached' in options) {
			// TODO
		}

		this.msgKeyService.resolveWithReplacements(errorKeys)
			.subscribe(keys => {
				const errorMessage: Array<string> = [
					...Object.keys(errorKeys)
						.map(key => keys[key]),
					...literalErrors,
				];

				if (this.errors[id] === null) {
					return;
				}

				if (type === ERROR_TYPE.BLOCK) {
					const viewRef: ViewContainerRef = viewContainerRef.injector.get(ERROR_CONTAINER);
					if (!viewRef) {
						throw new Error(`No Error-container found for ${ options.name }.`);
					}
					viewRef.clear();
					const componentRef: ComponentRef<ErrorComponent> = viewRef.createComponent(ErrorComponent);
					componentRef.instance.error = errorMessage.join('\n');
					componentRef.instance.cdr.markForCheck();
					this.errors[id] = errorObject;
					return;
				}
				if (type === ERROR_TYPE.TOOLTIP) {
					this.errorHandler.determineMarkerClass(nativeElement.classList.contains('hasWarnings'));

					$(nativeElement)
						.attr('errorpos', '0');
					const tooltip: JQuery<HTMLInputElement> = this.errorHandler.initTooltip({
						trigger: nativeElement,
						errors: [
							...Object.keys(errorKeys)
								.map(key => keys[key]),
							...literalErrors,
						],
					});
					if (!$(nativeElement)
						.attr('errorpos')) {
						$(nativeElement)
							.attr('errorpos', 'errorOnTop');
					}
					this.errors[id] = {
						...errorObject,
						tooltip: tooltip,
					};
					return;
				}

				throw Error(`No Error-strategy for error-type: ${ ERROR_TYPE[type] }`);
			});
		return id;
	}

}
