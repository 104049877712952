import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const JSON_MIME_TYPES = ['application/json', 'application/problem+json'];

export class BlobErrorInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req)
			.pipe(
				catchError(error => {
					if (error instanceof HttpErrorResponse && error.error instanceof Blob && JSON_MIME_TYPES.includes(error.error.type)) {
						return new Promise<HttpEvent<any>>((resolve, reject) => {
							let reader = new FileReader();
							reader.onload = (e: Event) => {
								try {
									const body = JSON.parse((e.target as any).result);
									reject(new HttpErrorResponse({
										error: body,
										headers: error.headers,
										status: error.status,
										statusText: error.statusText,
										url: error.url,
									}));
								} catch (e) {
									reject(error);
								}
							};
							reader.onerror = e => reject(error);
							reader.readAsText(error.error);
						});
					}
					throw error;
				}),
			);
	}

}