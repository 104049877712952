import { createSelector } from '@ngrx/store';
import { ApplicationState } from '../../shared/store/application-state';
import { Basket } from '../models/basket';
import { NavigationItem } from '../models/navigationItem';
import {
	AddMainNavigationItemsAction,
	NavigationActions,
	NavigationActionTypes,
	SetActiveNavigationItemAction,
	SetBasketAction,
	SetMainNavigationAction
} from './navigation.actions';
import { NavigationState } from './navigation.state';

const initialState: NavigationState = {
	showMainNav: false,
	showSideNav: false,
	isResponsive: false,
	showNavFooter: true,
	showNavHeader: true,
	showAjaxRotator: false,
	items: []
};

export function navigationReducer(state: NavigationState = initialState, action: NavigationActions): NavigationState {

	switch (action.type) {
		case NavigationActionTypes.AddAjaxRotator: {
			if (state.showAjaxRotator) {
				return state;
			}

			return {
				...state,
				showAjaxRotator: true
			};
		}
		case NavigationActionTypes.RemoveAjaxRotator: {
			if (!state.showAjaxRotator) {
				return state;
			}

			return {
				...state,
				showAjaxRotator: false
			};
		}
		case NavigationActionTypes.SetMainNavigation: {
			const showMainNav = (<SetMainNavigationAction>action).payload;
			if (state.showMainNav === showMainNav) {
				return state;
			}

			return {
				...state,
				showMainNav
			};
		}
		case NavigationActionTypes.ShowSideNavigation: {
			if (state.showSideNav) {
				return state;
			}

			return {
				...state,
				showSideNav: true
			};
		}
		case NavigationActionTypes.HideSideNavigation: {
			if (!state.showSideNav) {
				return state;
			}

			return {
				...state,
				showSideNav: false
			};
		}
		case NavigationActionTypes.SetResponsiveWidth: {
			if (state.isResponsive) {
				return state;
			}

			return {
				...state,
				isResponsive: true
			};
		}
		case NavigationActionTypes.SetFixedWidth: {
			if (!state.isResponsive) {
				return state;
			}

			return {
				...state,
				isResponsive: false
			};
		}
		case NavigationActionTypes.AddMainNavigationItems: {
			const newItems = (action as AddMainNavigationItemsAction)
				.payload
				.filter(item => state.items.every(existing => item.url !== existing.url));

			if (newItems.length === 0) {
				return state;
			}

			return {
				...state,
				items: [...state.items, ...newItems]
			};
		}
		case NavigationActionTypes.SetActiveNavigationItem: {
			const activeItemId = (action as SetActiveNavigationItemAction).payload;
			if (activeItemId === state.activeItemId) {
				return state;
			}
			return {
				...state,
				activeItemId
			};
		}
		case NavigationActionTypes.SetBasketAction: {
			const basket = (action as SetBasketAction).payload;

			return {
				...state,
				basket
			};
		}
		case NavigationActionTypes.RemoveBasketAction: {
			if (!state.basket) {
				return state;
			}

			return {
				...state,
				basket: null
			};
		}
		case NavigationActionTypes.ClearMainNavigationAction: {
			return {
				...state,
				items: []
			};
		}
		// case NavigationActionTypes.HideMainNavigation: {
		// 	if (!state.showMainNav) {
		// 		return state;
		// 	}

		// 	return {
		// 		...state,
		// 		showMainNav: false
		// 	};
		// }
		case NavigationActionTypes.ShowNavigationHeader: {
			if (state.showNavHeader) {
				return state;
			}

			return {
				...state,
				showNavHeader: true
			};
		}
		case NavigationActionTypes.HideNavigationHeader: {
			if (!state.showNavHeader) {
				return state;
			}

			return {
				...state,
				showNavHeader: false
			};
		}
		case NavigationActionTypes.ShowNavigationFooter: {
			if (state.showNavFooter) {
				return state;
			}

			return {
				...state,
				showNavFooter: true
			};
		}
		case NavigationActionTypes.HideNavigationFooter: {
			if (!state.showNavFooter) {
				return state;
			}

			return {
				...state,
				showNavFooter: false
			};
		}
		case NavigationActionTypes.ShowMobileNavigation: {
			if (state.showMobileNav) {
				return state;
			}

			return {
				...state,
				showMobileNav: true
			};
		}
		case NavigationActionTypes.HideMobileNavigation: {
			if (!state.showMobileNav) {
				return state;
			}

			return {
				...state,
				showMobileNav: false
			};
		}

		default:
			return state;
	}
}

const getItemById = (items: Array<NavigationItem> | null, id: string | null): NavigationItem => {
	if (!id || !items) {
		return null;
	}
	const item = items.find(currentItem => currentItem.id === id || !!getItemById(currentItem.children, id));
	return item && item.name === 'root' ? getItemById(item.children, id) : item;
};

const findItemsRecursive = (predicate: (item: NavigationItem) => boolean): (items: NavigationItem[]) => NavigationItem[] =>
	(items: Array<NavigationItem>): Array<NavigationItem> => {
		const foundItems = items.filter(predicate);
		const foundChildren = items
			.filter(item => item.children)
			.map(item => item.children)
			.map(findItemsRecursive(predicate))
			.reduce((found, foundInChild) => [...found, ...foundInChild], []);

		return [...foundItems, ...foundChildren];
	};

export namespace NavigationQuery {
	export const showMainNav = (state: ApplicationState): boolean => state.navigationState.showMainNav;
	export const showSideNav = (state: ApplicationState): boolean => state.navigationState.showSideNav;
	export const isResponsive = (state: ApplicationState): boolean => state.navigationState.isResponsive;
	export const showNavHeader = (state: ApplicationState): boolean => state.navigationState.showNavHeader;
	export const showNavFooter = (state: ApplicationState): boolean => state.navigationState.showNavFooter;
	export const showMobileNav = (state: ApplicationState): boolean => state.navigationState.showMobileNav;
	export const items = (state: ApplicationState): NavigationItem[] => state.navigationState.items;
	export const getUserNavItems = createSelector(items, (navItems) => (findItemsRecursive(item => item.parameters
		&& item.parameters['showInUserNav'])(navItems)));
	export const getActiveId = (state: ApplicationState): string => state.navigationState.activeItemId;
	export const getActiveMainItem = createSelector(items, getActiveId, (navItems, id) => getItemById(navItems, id));

	export const getBasket = (state: ApplicationState): Basket => state.navigationState.basket;
}
