import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipContentComponent } from './content/tooltip-content.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		TooltipComponent,
		TooltipContentComponent
	],
	exports: [
		TooltipComponent,
		TooltipContentComponent
	]
})
export class TooltipModule {}
