import configuration from 'globalConfiguration';

function LocaleHelper() {
	$(document)
		.on('reinitLocaleHelper', $.proxy(this.reinit, this));
	this.getLocaleAttributesFromBody();
}

LocaleHelper.prototype.getLocaleAttributesFromBody = function () {

	let uiLang = $('body')
		.attr('uilanguage');
	let formatLocale = $('body')
		.attr('formattinglocale');
	this.mappings = configuration.LocaleHelper.mappings;
	this.language = uiLang != undefined ? uiLang : 'de_DE';
	this.format = formatLocale != undefined ? formatLocale : 'de_DE';

	document.dispatchEvent(new Event('afterReinitLocaleHelper'));
};

LocaleHelper.prototype.reinit = function () {
	this.getLocaleAttributesFromBody();
};

LocaleHelper.prototype.getLanguage = function () {
	return this.language;
};
LocaleHelper.prototype.getFormat = function () {
	return this.format;
};

LocaleHelper.prototype.mapLanguage = function () {
	return this.map(this.language);
};
LocaleHelper.prototype.mapFormat = function () {
	return this.map(this.format);
};

LocaleHelper.prototype.map = function (locale) {
	let result = this.searchLocaleInMapping(locale);

	if (result == undefined && locale.indexOf('_') != -1) {
		locale = locale.split('_')[0];
		result = this.searchLocaleInMapping(locale);
	}
	if (result == undefined) {
		result = this.searchLocaleInMapping(configuration.LocaleHelper.fallbackLang);
	}
	return result;
};

LocaleHelper.prototype.searchLocaleInMapping = function (locale) {
	let result = undefined;
	$.each(this.mappings, function (region) {
		if (jQuery.inArray(locale, this) != -1) {
			result = region;
		}
	});
	return result;
};

export default new LocaleHelper();
