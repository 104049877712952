import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureService } from './feature.service';

@Pipe({
	name: 'featureValue'
})
export class FeatureValuePipe implements PipeTransform {

	constructor(private feature: FeatureService) {}

	transform(feature: string): Observable<boolean | string> {
		return this.feature.getFeatureValue(feature);
	}

}
