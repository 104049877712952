<header>
	<ng-container *ngIf="showMainNav(); else notLoggedIn">
		<div class="meta-navigation">
			<div class="logo">
				<a routerLink="/workflow.do?from=menu&to=home">
					<img src="page/style/img/header/werbebaukasten.png">
				</a>
			</div>
			<div class="rings">
				<img src="page/style/img/header/logo.gif">
			</div>
		</div>

		<nav>
			<div class="main-navigation primaryCiColor">
				<ul>
					<ng-container *ngFor="let topItems of items()">
						<ng-container *ngIf="topItems.name === 'root'; else normal">
							<bas-nav-item *ngFor="let item of topItems.children"
										  [item]="item"></bas-nav-item>
						</ng-container>
						<ng-template #normal>
							<bas-nav-item [item]="topItems"></bas-nav-item>
						</ng-template>
					</ng-container>
				</ul>

				<div class="nav-actions">
					<bas-nav-quick></bas-nav-quick>

					<bas-nav-basket></bas-nav-basket>

					<a class="logout"
					   [title]="'image.metanav.logout.alt' | msgKey"
					   (click)="logout('/logout.do')"></a>

					<bas-nav-search-field></bas-nav-search-field>
				</div>
			</div>
		</nav>
	</ng-container>

	<ng-template #notLoggedIn>
		<div class="logo">
			<img src="page/style/img/header/werbebaukasten.png">
		</div>
		<div class="rings">
			<img src="page/style/img/header/logo.gif">
		</div>
	</ng-template>
</header>