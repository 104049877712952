import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';
import { UserService } from '../../../permissions/user/user.service';
import { ApplicationState } from '../../../shared/store/application-state';
import { UserQuery } from '../../../shared/store/reducers/user.reducer';

@Component({
	selector: 'bas-footer-serverinfo',
	templateUrl: './footer-serverinfo.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterServerInfoComponent {

	debug = signal<any>(null);

	constructor(
		private store: Store<ApplicationState>,
		private http: HttpClient,
		private userService: UserService,
	) {
		this.store.select(UserQuery.getUser)
			.pipe(
				filter(Boolean),
				switchMap(() => this.userService.has('debug')),
				filter(Boolean),
				switchMap(() => this.http.get('rest/debug')),
				takeUntilDestroyed(),
			)
			.subscribe(debug => this.debug.set(debug));
	}
}
