import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BuiDialogOptions, DialogComponent } from '@bas/ui';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MsgKeyService } from '../../translate/msg-key.service';
import { DialogOptions } from './models/dialog-options';

@Injectable()
export class DialogService {

	private options?: DialogOptions[] = [];

	constructor(private dialog: MatDialog, private msgKeyService: MsgKeyService) {
	}

	public precache(options: DialogOptions) {
		options.messageKeyCached = true;
		options.cancelKey = options.cancelKey || 'button.cancel.alt';
		options.okKey = options.okKey || 'button.ok.alt';

		if (options.messageKey) {
			this.msgKeyService.getMessageWithCache(options.messageKey, options.messageReplacements)
				.subscribe();
		} else if (!options.message) {
			throw new Error('needs either message or message key');
		}
		if (options.titleKey) {
			this.msgKeyService.getMessageWithCache(options.titleKey, options.titleReplacements)
				.subscribe();
		} else if (!options.title) {
			throw new Error('needs either title or title key');
		}

		this.msgKeyService.getMessageWithCache(options.cancelKey)
			.subscribe();

		this.msgKeyService.getMessageWithCache(options.okKey)
			.subscribe();

		return this.options.push(options) - 1;
	}

	/**
	 * @param options if options is a number -> uses index of  options (defaults to 0)
	 */
	open(options?: DialogOptions | number): Observable<boolean> {
		let dialogOptions: DialogOptions;
		if (typeof options === 'number') {
			dialogOptions = this.options[options];
		} else if (options) {
			dialogOptions = options;
		} else {
			dialogOptions = this.options[0];
		}

		if (dialogOptions.messageKeyCached === undefined) {
			dialogOptions.messageKeyCached = true;
		}

		const titleOption = dialogOptions.title
			? of(dialogOptions.title)
			: dialogOptions.titleKey
				? this.msgKeyService.getMessageWithCache(dialogOptions.titleKey, dialogOptions.titleReplacements)
				: of(null);
		const messageOption = dialogOptions.message
			? of(dialogOptions.message)
			: dialogOptions.messageKeyCached
				? this.msgKeyService.getMessageHtmlCached(dialogOptions.messageKey, dialogOptions.messageReplacements)
				: this.msgKeyService.getMessageHtml(dialogOptions.messageKey, dialogOptions.messageReplacements);
		const cancelOption = dialogOptions.cancel
			? of(dialogOptions.cancel)
			: dialogOptions.cancel === false
				? of(null)
				: this.msgKeyService.getMessageWithCache(dialogOptions.cancelKey || 'button.cancel.alt');
		const okOption = dialogOptions.ok
			? of(dialogOptions.ok)
			: this.msgKeyService.getMessageWithCache(dialogOptions.okKey || 'button.ok.alt');

		return combineLatest([titleOption, cancelOption, messageOption, okOption])
			.pipe(
				take(1),
				map(([title, cancel, message, ok]): BuiDialogOptions => (
					dialogOptions.message
						? { title, cancel, ok, message }
						: { title, cancel, ok, htmlMessage: message }
				)),
				switchMap(buiDialogOptions => this.dialog.open<DialogComponent, BuiDialogOptions, boolean>(DialogComponent, {
					autoFocus: false,
					data: buiDialogOptions,
					maxWidth: 500,
				})
					.afterClosed()),
				map(result => !!result),
			);
	}

}
