import {
	ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 * @group Elements
 * @component Buttons
 */
@Component({
	selector: '[bas-button]',
	template: '<ng-content></ng-content>',
	styleUrls: ['./button.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {

	private _color: 'accent' | 'primary' = 'primary';

	/** Default: false; Wert, ob der Button disabled ist. */
	@HostBinding('class.disabled')
	@Input()
	disabled = false;

	@Input() specificTabindex = null;

	@HostBinding('attr.type')
	type = 'button';

	/** Binding auf [tabindex] => -1 falls disabled */
	@HostBinding('attr.tabindex')
	get tabindex(): number {
		if (this.specificTabindex) {
			return this.specificTabindex;
		} else {
			return this.disabled ? -1 : null;
		}
	}

	/** Default: 'primary'; Entscheidet Styling für Button */
	@Input()
	set color(color: 'accent' | 'primary') {
		this._color = (color || 'primary').trim() as 'accent' | 'primary';
	}

	/** Binding auf 'primary'-Klasse; Abhängig von color */
	@HostBinding('class.primary')
	get primary(): boolean {
		return this._color === 'primary';
	}

	/** Binding auf 'accent'-Klasse; Abhängig von color */
	@HostBinding('class.accent')
	get accent(): boolean {
		return this._color === 'accent';
	}

	@Output('throttledClick')
	throttledClick = new EventEmitter<boolean>();

	constructor(
		private elementRef: ViewContainerRef,
		private destroyRef: DestroyRef,
	) {
	}

	ngOnInit(): void {
		const clickObservable = fromEvent(this.elementRef.element.nativeElement, 'click');
		clickObservable.pipe(
			throttleTime(300),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe(() => this.throttledClick.emit(true));
	}

	public blur(): void {
		this.elementRef.element.nativeElement.blur();
	}
}
