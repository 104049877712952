import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Observable } from 'rxjs';
import { StrutsService } from './struts.service';

export const strutsGuard: CanActivateFn = (): Observable<boolean> | Promise<boolean> | boolean => {
	return inject(StrutsService)
		.canActivate();
};
