import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApplicationState } from '../../../shared/store/application-state';
import { NavigationItem } from '../../models/navigationItem';
import { SetActiveNavigationItemAction } from '../../store/navigation.actions';
import { NavigationQuery } from '../../store/navigation.reducer';

@Component({
	selector: 'bas-nav-quick',
	templateUrl: './quick-navigation.component.html',
	styleUrls: ['./quick-navigation.component.less'],
	animations: [
		trigger('openState', [
			state('closed', style({
				height: 0,
				display: 'none',
			})),
			transition('* => closed', animate('0ms cubic-bezier(0.19, 1, 0.22, 1)')),
			transition('closed => *', animate('250ms cubic-bezier(0.19, 1, 0.22, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickNavComponent {

	items = computed(() => this.store.selectSignal(NavigationQuery.items)()
		.filter(item => item.url && !item.parameters['showInAltNav'] && item.id !== 'home'));

	state: 'closed' | 'open' = 'closed';
	open$: Subject<boolean> = new Subject();
	close$: Subject<boolean> = new Subject();
	isOpened: boolean = false;

	constructor(private store: Store<ApplicationState>, private cdr: ChangeDetectorRef) {
		const toOpenState = open => open ? 'open' : 'closed';
		const close$ = this.close$.pipe(
			debounceTime(200),
			map(toOpenState),
		);
		const open$ = this.open$.pipe(
			debounceTime(175),
			map(toOpenState),
		);

		merge(close$, open$)
			.pipe(distinctUntilChanged())
			.subscribe(s => {
				this.state = <'closed' | 'open'>s;
				this.cdr.markForCheck();
			});
	}

	open() {
		this.close$.next(true);
		this.open$.next(true);
	}

	close() {
		this.open$.next(false);
		this.close$.next(false);
	}

	onClick(item: NavigationItem) {
		this.store.dispatch(new SetActiveNavigationItemAction(item.id));
		this.state = 'closed'; // Set state to closed directly to ignore debounce
		this.cdr.markForCheck();
	}
}
