import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PanelModule } from '../../generic-compounds/panel/panel.module';
import { MsgKeyModule } from '../../translate/msg-key.module';
import { HeadlineModule } from '../headline/headline.module';
import { CheckErrorDirective } from './check-error.directive';
import { NgFormGroupErrorDirective } from './error-group/error-group.directive';
import { ErrorComponent } from './error.component';
import { ErrorService } from './error.service';
import { UnassignableErrorsComponent } from './unassignable-error-container/unassignable-errors.component';

@NgModule({
	imports: [
		HeadlineModule,
		MsgKeyModule,
		CommonModule,
		PanelModule,
	],
	declarations: [
		ErrorComponent,
		CheckErrorDirective,
		NgFormGroupErrorDirective,
		UnassignableErrorsComponent,
	],
	exports: [
		ErrorComponent,
		CheckErrorDirective,
		NgFormGroupErrorDirective,
		UnassignableErrorsComponent,
	],
})
export class ErrorModule {
	static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: ErrorModule,
			providers: [ErrorService],
		};
	}
}
