<div class="headline" (click)="next()">
	<div *ngIf="actualState$ | async; else closed" class="panel-trigger close"
		title="{{'button.collapse.alt' | msgKeyCached}}"></div>
	<ng-template #closed>
		<div class="panel-trigger open" title="{{'button.expand.alt' | msgKeyCached}}"></div>
	</ng-template>
	<span *ngIf="name">{{name}}</span>
	<ng-content select=".paneltitle"></ng-content>
</div>

<div class="content" [@toggle]="animationState$  | async">
	<ng-content></ng-content>
</div>